import React, { useEffect, useRef, useState } from "react";
import DraggableComponent from "../../Components/DraggableComponent";
import { Modal } from "react-bootstrap";
import { CommonUtil, getSelectedRangeRate } from "../../utils/CommonUtil";
import Flatpickr from "react-flatpickr";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import SingleSelectComp from "../../Components/SingleSelectComp";
import AGGrid from "../../Components/AGGrid";
import OrgCellRenderer from "../Organization/OrgCellRenderer";
import { IAGrid } from "../../Components/IInterfaces";
import useStateWithCallback from "use-state-with-callback";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Confirm from "../../Components/confirm";
import { loadMapApi } from "../../utils/GoogleMapsUtils";
import BusyLoader from "../../Components/BusyLoader";
import { Constants } from "../Languages/Constants";
import ReservationDetailsCellRenderer from "../Organization/ReservationDetailsCellRenderer";
import { AvailabilityItem } from "./AvailabilityItem";
import PhoneInput from "react-phone-input-2";
import { constants } from "buffer";



var dateformat: object = { dateFormat: "m-d-Y", altFormat: 'M-d-Y' };

var reservationId = "";
var insurPolicies: any = [];
var physicalDmgPolicies = [];
var liabilityPolicies = [];
var bankAccounts: any = [];
var bank1Currency = "";
var bank2Currency = "";
var isTaxExcemptionAvail: boolean = false;

let equipListingStatus: string = "";
let saasOrg: string = "";
let avlStDate: any = null;
let avlEndDate: any = null;

let parkingLotArray: any = [];



const EquipmentAvail = (props: any) => {

    const [eqpColumnDefs] = useState([
        { field: 'listingType', headerName: "Listing Type", resizable: true, width: 100, flex: 1, sortable: true, filter: 'agTextColumnFilter', cellRenderer: OrgCellRenderer },
        { field: 'startDate', headerName: CommonUtil.getMessageText(Constants.START_DATE, "Start Date"), resizable: true, width: 100, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'endDate', headerName: CommonUtil.getMessageText(Constants.END_DATE, "End Date"), resizable: true, width: 100, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'departureParkingLotName', headerName: 'Departure Lot Name', resizable: true, width: 100, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'arrivalParkingLotName', headerName: 'Arrival Lot Name', resizable: true, width: 100, sortable: true, flex: 1, filter: 'agNumberColumnFilter' },

    ]);
    
    const [availviewTypeDef] = useState([
        { field: 'renterOrgName', headerName: "Carriers", resizable: true, width: 100, flex: 1, sortable: false, },
        { field: 'action', headerName: "Actions", resizable: true, width: 100, flex: 1, sortable: false, cellRenderer:(params:any)=>(
        <div className={"cellRenderHeader"}>
            <a style={{color:"red"}}><span onClick={(e)=>onClickOrgRemove(params)} className={"cellRender"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg>
</span>
</a>
        </div>
        )},

    ]);

    const navigate = useNavigate()

    const gridRef = useRef<IAGrid>(null);
    const [equipAvailable, setEquipData] = useStateWithCallback<any>([], () => { });

    const [showEquipPopup, setShowEquipPopup] = useState(false);
    const [showAvailPopup, setShowAvailPopup] = useState(false);

    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);
    const [stDate, setStDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [dateOptions, setDateOptions] = useState(dateformat);//CommonUtil.AVAIL_DATE_FORMAT);

    const chkAllowRef = useRef<HTMLInputElement>(null);
    const chkPickRef = useRef<HTMLInputElement>(null);


    const chkTerms = useRef<HTMLInputElement>(null);
    const rdPDmgPaidByOwner = useRef<HTMLInputElement>(null);
    const rdPDmgPaidByRenter = useRef<HTMLInputElement>(null);
    const rdLbtPaidByRenter = useRef<HTMLInputElement>(null);
    const rdLbtPaidByOwner = useRef<HTMLInputElement>(null);

    const priceRef = useRef<HTMLInputElement>(null);
    const [addtionalPrice, setAdditionalPrice] = useState("");

    const dailyRef = useRef<HTMLInputElement>(null);
    const [dailyRate, setDailyRate] = useState("");

    const weeklyRef = useRef<HTMLInputElement>(null);
    const [weeklyRate, setWeeklyRate] = useState("");

    const monthlyRef = useRef<HTMLInputElement>(null);
    const [monthlyRate, setMonthlyRate] = useState("");

    const distanceRef = useRef<HTMLInputElement>(null);
    const [distanceRate, setDistanceRate] = useState("");

    const movePriceRef = useRef<HTMLInputElement>(null);
    const [movePrice, setMovePrice] = useState<any>("");

    const [platformFeesPaidByOwner, setPlatformFeesPaidByOwner] = useState<string>("Repositioner");

    const engineRef = useRef<HTMLInputElement>(null);
    const [engineRate, setEngineRate] = useState("");

    const noOfDayRef = useRef<HTMLInputElement>(null);
    const [noOfDays, setNoOfDays] = useState<any>("");

    const eDisRef = useRef<HTMLInputElement>(null);
    const [eDisRate, setEDisRate] = useState("");

    const priceKmRef = useRef<HTMLInputElement>(null);
    const [priceKm, setPriceKm] = useState("");

    const qtyRef = useRef<HTMLInputElement>(null);
    const [quantity, setQuantity] = useState("");

    const [currencies, setCurrencies] = useState(CommonUtil.DEF_CURRENCY);
    const [currencyObj, setCurrencyObj] = useState<any>(null);

    const [loadTypes, setLoadTypes] = useState(CommonUtil.LOAD_TYPE);
    const [loadTypeObj, setLoadTypeObj] = useState<any>(null);
    const [isDisabled, setDisabled] = useState(false);
    const [isRepDisabled, setRepDisabled] = useState(false);
    const [isCurrecyDisable, setCurrencyDisable] = useState(false);
    const [isTermsDisable, setTermsDisable] = useState(false);
    const [islabelRental, setIslabelRental] = useState(false);

    const [availableRental, setAvailableRental] = useState(CommonUtil.AVAILABLE_RENTAL);


    const [isGenericReposition, setGenericReposition] = useState(false);
    const [isGenericRental, setGenericRental] = useState(false);
    const [isSpecificReposition, setSpecificReposition] = useState(false);
    const [isSpecificRental, setSpecificRental] = useState(false);

    const [parkingLots, setParkingLots] = useState<any>([]);
    const [selDepartureObj, setSelDepartureObj] = useState<any>(null);
    const [selArrivalObj, setSelArrivalObj] = useState<any>(null);

    const [defDailyRate, setDefDailyRate] = useState("");
    const [defWeeklyRate, setDefWeeklyRate] = useState("");
    const [defMonthlyRate, setDefMonthlyRate] = useState("");
    const [defYearlyRate, setDefYearlyRate] = useState("");
    const [defDistanceRate, setDefDistanceRate] = useState("");
    const [defEngineRate, setDefEngineRate] = useState("");
    const [equipmentType, setEquipmentType] = useState("");

    const [isInuranceChecked, setInsuranceChecked] = useState(false);
    const [isLiabilityChecked, setLiabilityChecked] = useState(false);
    const [isPhysicalDamageChecked, setPhysicalDamageChecked] = useState(false);

    const [isInuranceOwnerChecked, setInsuranceOwnerChecked] = useState(false);
    const [isInuranceRenterChecked, setInsuranceRenterChecked] = useState(false);

    const [insurPlans, setInsurPlans] = useState<any>([]);
    const [selPolicyObj, setSelPolicyObj] = useState<any>(null);
    const [perdayRate, setRatePerDay] = useState("");
    const [physicalDamagePolicies, setPhysicalDamagePolicies] = useState([]);
    const [liabilityPolicies, setLiabilityPolicies] = useState([]);
    const [selPhysicalDamage, setSelPhysicalDamage] = useState<any>(null);
    const [selLiability, setSelLiability] = useState<any>(null);

    const [liabilityPaidByOwner, setLiabilityPaidByOwner] = useState<any>(false);
    const [liabilityPaidByRenter, setLiabilityPaidByRenter] = useState<any>(false);
    const [physicalDamagePaidByOwner, setPhysicalDamagePaidByOwner] = useState<any>(false);
    const [physicalDamagePaidByRenter, setPhysicalDamagePaidByRenter] = useState<any>(false);

    const [isLiabilityPaidByOwner, setIsLiabilityPaidByOwner] = useState<any>(false);
    const [isLiabilityPaidByRenter, setIsLiabilityPaidByRenter] = useState<any>(false);
    const [isPhysicalDamagePaidByOwner, setIsPhysicalDamagePaidByOwner] = useState<any>(false);
    const [isPhysicalDamagePaidByRenter, setIsPhysicalDamagePaidByRenter] = useState<any>(false);
    const [isAnyAvailable, setIsAnyAvailable] = useState(false);

    const [liabilityClass, setLiabilityClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [physicalDamageClass, setPhysicalDamageClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);

    
    const [avlTypeClass, setAvlTypeClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [avlCarrierClass, setAvlCarrierClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);

    const [startDateClass, setStartDateClass] = useState(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
    const [endDateClass, setEndDateClass] = useState(CommonUtil.DATE_FIELD_MANDATORY_CLASS);

    const [loadTypeClass, setLoadTypeClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [arrivalAddrClass, setArrivalAddrClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [departureAddrClass, setDepartureAddrClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);
    const [currencyClass, setCurrencyClass] = useState(CommonUtil.DROPDOWN_MANDATORY_CLASS);

    const [selectedViewType, setSelectedViewType] = useState("PUBLIC_VIEW");
    const [closeNetworkList, setCloseNetworkList] = useState([]);
    const [selectedcloseNetwork, setSelectedcloseNetwork] = useState<any>("");
    const [specificCloseNetwork, setSpecificCloseNetwork] = useState<any>([]);
    const [phoneClass, setPhoneClass] = useState('valid');
    const [contactNumber,setContactNumber] = useState("");

    const updatePricingValues = (type:string,defaultValues:any,year:number)=>{
        let qParam:string = `saasOrgId=${localStorage.getItem('saasorgId')}&equipmentType=${type}`;
        if(year){
            qParam = qParam + `&modelYear=${year}`;
        }
        NetworkManager.sendJsonRequest("api/organization/getDefaultEquipmentTypePricing","",qParam,"GET",true,(response:any)=>{
            let restData:any = JSON.parse(response);
           const {dailyRate,
            weeklyRate,
            monthlyRate,
            distanceRate,
            engineRate}:any   = defaultValues;
            setDefDailyRate(dailyRate >= 0 ? dailyRate : restData?.dailyRate ? restData.dailyRate :'0')
            setDefWeeklyRate(weeklyRate >= 0 ? weeklyRate :restData?.weeklyRate? restData.weeklyRate :'0')
            setDefMonthlyRate(monthlyRate >= 0 ? monthlyRate :restData?.monthlyRate? restData.monthlyRate :'0' )
            setDefDistanceRate(distanceRate >= 0 ? distanceRate : restData?.distanceRate? restData.distanceRate :'0')
            setDefEngineRate(engineRate  >= 0 ? engineRate: restData?.engineRate? restData.engineRate : '0')
            setDefYearlyRate(restData.yearlyRate || 0)
        },onLoginError)
    }

    const onClickOrgRemove = (params:any)=>{
        let rowData:any = [];
        params.api.forEachNode((node:any) => rowData.push(node.data));
        const selectedarr:any = rowData.filter((ele:any)=>ele.renterOrgId !== params.data.renterOrgId)        
        setSpecificCloseNetwork(selectedarr)
    }



    //const [selArrivalObj,setSelArrivalObj] = useState<any>(null);

    let onClickEquipPopup = () => {
        avlStDate = null;
        avlEndDate = null;
        equipListingStatus = "";
        getOrgInsurancePlanList();
        CommonUtil.SELECT_EQUIP_AVAIL_ID = "";
        reservationId = "";
        CommonUtil.EQUIP_AVAIL_STATUS = "";

        setSelPolicyObj(null);
        setRatePerDay("");

        setDisabled(false);
        setRepDisabled(false);
        setTermsDisable(false);
        setSelectedViewType("PUBLIC_VIEW")
            setSpecificCloseNetwork([]);
            setCloseNetworkList( [])
        if (chkTerms && chkTerms.current) {
            chkTerms.current.checked = false;
        }
        setShowEquipPopup(true);
        setCurrencyDropdownDisable();
        setStartDateClass(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
        setEndDateClass(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
    }
    let onCloseEquipPopup = () => {
        setShowEquipPopup(false);
    }
    let onShowEquipAvailPopup = () => {
        setShowAvailPopup(true);
        //navigate("availability");
    }
    let onCloseEquipAvailPopup = () => {
        setShowAvailPopup(false);
    }
    let onClickReposition = () => {
        CommonUtil.EQUIP_AVAIL_TYPE = CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE;
        setAvailabilityType();
        onCloseEquipPopup();
        setIslabelRental(false)
        setTimeout(() => {
            onShowEquipAvailPopup();
        }, 500)

    }
    let onClickRenter = () => {
        CommonUtil.EQUIP_AVAIL_TYPE = CommonUtil.EQUIP_AVAIL_RENTAL_TYPE;
        setAvailabilityType();
        onCloseEquipPopup();
        setIslabelRental(true)
        setTimeout(() => {
            onShowEquipAvailPopup();
        }, 500)
    }


    let onCloseStDate = () => {
        if (stDateRef.current && avlStDate) {

            //stDateRef.current.flatpickr.set("minDate",avlStDate);
            //stDateRef.current.flatpickr.setDate(avlStDate);
        }
    }
    let onOpenStDate = () => {
        if (stDateRef.current) {
            // stDateRef.current.flatpickr.set("minDate",new Date());
            if (endDateRef.current) {
                var endDate = endDateRef.current.flatpickr.selectedDates[0];
                if (endDate) {
                    stDateRef.current.flatpickr.set("maxDate", new Date(endDate));
                } else {
                    stDateRef.current.flatpickr.set("minDate", new Date());
                }

            } else {
                stDateRef.current.flatpickr.set("minDate", new Date());
            }

        }
    }

    let onOpenEDDate = () => {
        if (endDateRef.current) {
            if (stDateRef.current) {
                var startDate = stDateRef.current.flatpickr.selectedDates[0];
                if (startDate) {
                    endDateRef.current.flatpickr.set("minDate", new Date(startDate));
                } else {
                    endDateRef.current.flatpickr.set("minDate", new Date());
                }

            } else {
                endDateRef.current.flatpickr.set("minDate", new Date());
            }

        }
    }

    let onChangeStDate = (dateValue: any) => {
        if (stDateRef.current) {
            let strDate: string = CommonUtil.getDate(dateValue);
            setStDate(strDate);
            if (strDate) {
                setStartDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
            } else {
                setStartDateClass(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
            }
            onChangeDates();
            setTimeout(() => {
                // updateEndDate();
            })
        }
    }
    let onChangeEdDate = (dataVal: any) => {
        let strDate: string = CommonUtil.getDate(dataVal);
        setEndDate(strDate);
        if (strDate) {
            setEndDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
        } else {
            setEndDateClass(CommonUtil.DATE_FIELD_MANDATORY_CLASS);
        }
        onChangeDates();
    }

    let onChangeDates = () => {
        if (stDateRef.current && endDateRef.current) {
            // stDateRef.current.flatpickr.set("minDate",new Date());
            //endDateRef.current.flatpickr.set("minDate",new Date());

            var startDate = stDateRef.current.flatpickr.selectedDates[0];
            var endDate = endDateRef.current.flatpickr.selectedDates[0];

            endDateRef.current.flatpickr.set("minDate", new Date(startDate));
            stDateRef.current.flatpickr.set("maxDate", new Date(endDate));

            if (startDate) {

                //stDtOptions.minDate = new Date(startDate);
            }
            if (endDate) {
                // endDate = new Date(endDate);

                // stDtOptions.maxDate = new Date(endDate);
            }
        }
    }

    let onChangeDepartureLot = (dataObj: any) => {
        setSelDepartureObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj, setDepartureAddrClass);
    }

    let onChangeArrivalLot = (dataObj: any) => {
        setSelArrivalObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj, setArrivalAddrClass);
        //  (dataObj) ? setArrivalAddrClass('select-box-valid') : setArrivalAddrClass('select-box-invalid');
    }

    let onChangeCurrency = (dataObj: any) => {
        setCurrencyObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj, setCurrencyClass);
    }

    // const handleChange = (selectedOption) => {
    //     console.log(`Option selected:`, selectedOption);
    //   };

    let onChangeAvailType = (dataObj: any) => {
        console.log(dataObj);

        setSelectedViewType(dataObj.key);
        CommonUtil.isDropDownMandatory(dataObj, setAvlTypeClass);
        // (dataObj) ? setAvlTypeClass('select-box-valid') : setAvlTypeClass('select-box-invalid');
    }
    let onChangeLoadType = (dataObj: any) => {

        setLoadTypeObj(dataObj);
        CommonUtil.isDropDownMandatory(dataObj, setLoadTypeClass);
        // (dataObj) ? setLoadTypeClass('select-box-valid') : setLoadTypeClass('select-box-invalid');
    }

    const onChangeCloseNetwork = (dataObj: any) => {        
        setSelectedcloseNetwork(dataObj);
        // CommonUtil.isDropDownMandatory(dataObj, setAvlCarrierClass);
        // (dataObj) ? setAvlCarrierClass('select-box-valid') : setAvlCarrierClass('select-box-invalid');
    }

    let setAvailabilityType = () => {
        setArrivalAddrClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setLoadTypeClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setDepartureAddrClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setLiabilityClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setPhysicalDamageClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setAvlTypeClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        setAvlCarrierClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);


        setGenericReposition(false);
        setGenericRental(false);
        setSpecificReposition(false);
        setSpecificRental(false);
        setSelLiability(null);
        setSelPhysicalDamage(null);


        setQuantity("");
        setPriceKm("");
        setEDisRate("");
        setDailyRate("");
        setWeeklyRate("");
        setMonthlyRate("");
        setDistanceRate("");
        setEngineRate("");
        if (chkAllowRef.current) {
            chkAllowRef.current.checked = false;
        }
        setMovePrice("");
        setAdditionalPrice("");
        setCurrencyObj(null);
        setSelDepartureObj(null);
        setSelArrivalObj(null);
        setNoOfDays("");




        if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
            setCurrencyDropdownDisable();
        } else {
            setCurrencyDisable(false);
            setCurrencyClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        }



        if (CommonUtil.IS_GENERIC_EQUIP) {
            if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                setGenericReposition(true);
            } else if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                setGenericRental(true);
            }
        } else {
            if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                setSpecificReposition(true);
            } else if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                setSpecificRental(true);
            }
        }

        setTimeout(() => {
            if (isTaxExcemptionAvail) {
                if (chkTerms && chkTerms.current) {
                    chkTerms.current.checked = true;
                    setTermsDisable(true);
                    $("#divConditions").hide();
                }
            }
        }, 1000);
    }

    let onClickDeleteAvailability = () => {
        let strDel = CommonUtil.getMessageText(Constants.DO_U_WANT_DELETE, "Do you want delete availability?");
        if (CommonUtil.SELECT_EQUIP_AVAIL_ID) {
            Confirm.show(CommonUtil.getMessageText(Constants.CONFIRM, "Confirm"), strDel, [{
                label: "Yes", callback: function () {
                    if (CommonUtil.SELECT_EQUIP_AVAIL_ID) {
                        let delAvailAPI = "api/availability/" + CommonUtil.SELECT_EQUIP_AVAIL_ID;
                        NetworkManager.sendJsonRequest(delAvailAPI, '', "", "DELETE", true, onDeleteAvailable, onLoginError);
                    }
                }
            }, { label: "No" }]);
        }
    }

    let onDeleteAvailable = (dataObj: any) => {
        let strDel = CommonUtil.getMessageText(Constants.AVAIL_DELETE, "Availability Deleted Successfully");
        if (dataObj) {
            if (dataObj == "success") {
                onCloseEquipAvailPopup();
                getEquipmentAvailabilities();

                ToastManager.showToast(strDel, "Info");
            } else {
                var restData = CommonUtil.parseData(dataObj);
                if (restData) {
                    onCloseEquipAvailPopup();
                    getEquipmentAvailabilities();
                    ToastManager.showToast(strDel, "Info");
                }
            }

        }
    }
    let handleNoOfDays = (e: any) => {
        setNoOfDays(e.currentTarget.value);
        CommonUtil.isMandatory(noOfDayRef);

        var newNofDaysMiilis = e.currentTarget.value * 24 * 60 * 60 * 1000;
        setTimeout(() => {
            // updateEndDate();
        })
        //console.log(noOfDays)

    }

    let updateEndDate = () => {
        var addDaysMillis = CommonUtil.ADD_MILLISECONDS;//3*24*60*60*1000;
        if (!islabelRental) {
            if (stDateRef.current && noOfDayRef.current && noOfDayRef.current.value) {
                var startDate = stDateRef.current.flatpickr.selectedDates[0];
                var endDate = new Date(new Date(startDate).getTime() + addDaysMillis + ((Number(noOfDayRef.current.value) - 1) * 24 * 60 * 60 * 1000));
                // console.log(endDate)
                if (endDateRef.current) {
                    var newEedDate: any;
                    newEedDate = endDateRef.current.flatpickr.setDate(new Date(endDate));
                }
                // setEndDate(newEedDate);
            }
        }
    }

    let onClickSaveAvailability = () => {

        /*if(equipListingStatus == CommonUtil.EQUIP_AVAILABILITY_STATUS_IN_PROGRESS){
            if(CommonUtil.IS_GENERIC_EQUIP){
                let isQty = CommonUtil.isMandatory(qtyRef);
               let availCraateApi = "api/availability/updateQuantity?equipmentListingId="+CommonUtil.SELECT_EQUIP_AVAIL_ID+"&quantity="+quantity;
                NetworkManager.sendJsonRequest(availCraateApi,"","","POST",true,onUpdateQuantity,onLoginError);
            }
        }else{*/
        let ratesNullCheck = (Number(defDailyRate) >0 || Number(dailyRate) > 0) || (Number(defWeeklyRate) >0 || Number(weeklyRate) > 0) || (Number(defMonthlyRate) >0 || Number(monthlyRate) > 0);
        let isContact:boolean = true;
        let flag: boolean = false;
        let stDate = null;
        let edDate = null;
        if(!ratesNullCheck && isGenericRental){
            ToastManager.showToast("Please enter rates", "Error");
                    return;
        }
        if (stDateRef.current) {
            stDate = stDateRef.current.flatpickr.selectedDates[0];
        }
        if (endDateRef.current) {
            edDate = endDateRef.current.flatpickr.selectedDates[0];
        }
        
        if (!contactNumber?.trim()) {
            isContact = false
            setPhoneClass('is-invalid');
        }
        (stDate ? setStartDateClass(CommonUtil.DATE_FIELD_VALID_CLASS) : setStartDateClass(CommonUtil.DATE_FIELD_IN_VALID_CLASS));
        (edDate ? setEndDateClass(CommonUtil.DATE_FIELD_VALID_CLASS) : setEndDateClass(CommonUtil.DATE_FIELD_IN_VALID_CLASS));
        (selArrivalObj ? setArrivalAddrClass(CommonUtil.DROPDOWN_VALID_CLASS) : setArrivalAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (selDepartureObj ? setDepartureAddrClass(CommonUtil.DROPDOWN_VALID_CLASS) : setDepartureAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (currencyObj ? setCurrencyClass(CommonUtil.DROPDOWN_VALID_CLASS) : setCurrencyClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (selectedViewType ? setAvlTypeClass(CommonUtil.DROPDOWN_VALID_CLASS) : setAvlTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
        (selectedcloseNetwork ? setAvlCarrierClass(CommonUtil.DROPDOWN_VALID_CLASS) : setAvlCarrierClass(CommonUtil.DROPDOWN_INVALID_CLASS));

        $("#divTerms").hide();

        if (stDate && edDate && selArrivalObj && selDepartureObj && selArrivalObj.parkingLotName && selDepartureObj.parkingLotName && currencyObj) {
            if ((isGenericRental || isGenericReposition)) {
                if (isGenericRental) {
                    let isQty = CommonUtil.isMandatory(qtyRef);
                    let isDailyRate = CommonUtil.isMandatory(dailyRef);
                    let isWeeklyRate = CommonUtil.isMandatory(weeklyRef);
                    let isMonthlyRate = CommonUtil.isMandatory(monthlyRef);
                    let isDisRate = CommonUtil.isMandatory(distanceRef);
                    if (equipmentType == CommonUtil.REEFER_EQUIP_TYPE) {
                        let isEngineRate = CommonUtil.isMandatory(engineRef);
                        if (quantity && distanceRate && engineRate) {
                            flag = true;
                        }
                    } else {
                        if (quantity && distanceRate) {
                            flag = true;
                        } else {
                            // displayErrorMessage();
                        }
                    }


                } else if (isGenericReposition) {
                    let isQty = CommonUtil.isMandatory(qtyRef);
                    let isNofOfDays = CommonUtil.isMandatory(noOfDayRef);
                    let isMovePrice = CommonUtil.isMandatory(movePriceRef);
                    let isPrice = CommonUtil.isMandatory(priceRef);
                    (loadTypeObj ? setLoadTypeClass(CommonUtil.DROPDOWN_VALID_CLASS) : setLoadTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    if (quantity && noOfDays && movePrice && addtionalPrice && loadTypeObj) {
                        flag = true;
                    } else {
                        // displayErrorMessage();
                    }
                }
            } else if (isSpecificRental || isSpecificReposition) {
                if (isSpecificRental) {
                    let isDailyRate = CommonUtil.isMandatory(dailyRef);
                    let isWeeklyRate = CommonUtil.isMandatory(weeklyRef);
                    let isMonthlyRate = CommonUtil.isMandatory(monthlyRef);
                    let isDisRate = CommonUtil.isMandatory(distanceRef);
                    if (equipmentType == CommonUtil.REEFER_EQUIP_TYPE) {
                        let isEngineRate = CommonUtil.isMandatory(engineRef);
                        if (distanceRate && engineRate) {
                            flag = true;
                        }
                    } else {
                        if (distanceRate) {
                            flag = true;
                        } else {
                            // displayErrorMessage();
                        }
                    }

                } else if (isSpecificReposition) {
                    (loadTypeObj ? setLoadTypeClass(CommonUtil.DROPDOWN_VALID_CLASS) : setLoadTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    let isNofOfDays = CommonUtil.isMandatory(noOfDayRef);
                    let isMovePrice = CommonUtil.isMandatory(movePriceRef);
                    let isPrice = CommonUtil.isMandatory(priceRef);

                    if (loadTypeObj && noOfDays && movePrice && addtionalPrice) {
                        flag = true;
                    } else {
                        // displayErrorMessage();
                    }
                }
            }

            if (isGenericReposition || isSpecificReposition) {
                let days = CommonUtil.getDays(stDate, edDate);
                if (Number(noOfDays) > days) {
                    flag = false;
                    let msg = CommonUtil.getMessageText(Constants.NUM_OF_DAY_ALLOWED, "Number of days allowed for repositioning is less than or equal to between days");
                    ToastManager.showToast(msg, 'Error');
                    return;
                }

                if (platformFeesPaidByOwner == "Renter" && Number(movePrice) < Constants.MIN_PLATFORMFEE) {
                    flag = false;
                    let msg = `Paid Price to Carrier for Repositioning must be greater that ${Constants.MIN_PLATFORMFEE}`;
                    ToastManager.showToast(msg, 'Error');
                    return;
                }
            }
            if (isLiabilityChecked) {
                if (!selLiability) {
                    (selLiability ? setLiabilityClass(CommonUtil.DROPDOWN_VALID_CLASS) : setLiabilityClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    //displayErrorMessage();
                    flag = false;
                }

            }
            if (isPhysicalDamageChecked) {
                if (!selPhysicalDamage && flag) {
                    (selPhysicalDamage ? setPhysicalDamageClass(CommonUtil.DROPDOWN_VALID_CLASS) : setPhysicalDamageClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                    //displayErrorMessage();
                    flag = false;
                }
            }
            // if (selectedViewType === "SPECIFIC_VIEW") {
            //     if (specificCloseNetwork?.length == 0){    
            //         flag = false;
            //         let msg = ` Please added atleast one Specific network carrier`;
            //         ToastManager.showToast(msg, 'Error');
            //         return;
            //     }
            // }

            if (flag) {
                //var startDate = stDateRef.current.flatpickr.selectedDates[0];
                //var endDate = endDateRef.current.flatpickr.selectedDates[0];

                if (chkTerms.current) {
                    if (!chkTerms.current.checked) {
                        $("#divTerms").show();
                        ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_SELECT_TERMS_CONDITIONS, "Please select terms & conditions"), "Error");
                        return;
                    } else {

                    }
                }

                let reqObj: any = {};
                reqObj.policyId = selPolicyObj ? selPolicyObj.id : "";
                reqObj.endDate = CommonUtil.getReservationDateString(edDate);
                reqObj.startDate = CommonUtil.getReservationDateString(stDate);
                reqObj.additionalPriceExcessDays = addtionalPrice;
                reqObj.allowOffer = ((chkAllowRef.current?.checked) ? "Y" : "N");
                reqObj.isEnableAnyAvailble = ((chkPickRef.current?.checked) ? "Y" : "N");
                reqObj.arrivalParkingLotId = selArrivalObj ? selArrivalObj.parkingLotId : "";
                reqObj.departureParkingLotId = selDepartureObj ? selDepartureObj.parkingLotId : "";
                reqObj.dailyRate = String(dailyRate).trim() !=="" ? dailyRate : defDailyRate;
                reqObj.weeklyRate = String(weeklyRate).trim() !=="" ? weeklyRate : defWeeklyRate;
                reqObj.monthlyRate = String(monthlyRate).trim() !=="" ? monthlyRate : defMonthlyRate;
                reqObj.defaultCurrency = currencyObj ? currencyObj.key : "";
                reqObj.contractNumber = contactNumber;

                /*if(distanceRate){
                    reqObj.distanceRate = distanceRate;
                }else{
                    reqObj.distanceRate = "";
                }*/

                reqObj.engineRate = engineRate;
                reqObj.equipmentId = CommonUtil.SELECT_EQUIP_ID;
                reqObj.estimatedDistance = eDisRate;
                reqObj.listingType = CommonUtil.EQUIP_AVAIL_TYPE;
                reqObj.loadType = loadTypeObj ? loadTypeObj.key : "";
                reqObj.movePrice = movePrice;
                if ((isGenericReposition || isSpecificReposition)) {
                    const percent:any = Constants.PLATFORMFEE_PERCENT/100;
                    reqObj.platformFees = (Number(movePrice) * percent);
                    reqObj.platformFeesPaidBy = (platformFeesPaidByOwner);
                }
                reqObj.noOfDaysAllowed = noOfDays;
                reqObj.pricePerKm = priceKm;

                /* if(priceKm){
                     reqObj.distanceRate = priceKm;
                 }else if(distanceRate){
                     reqObj.distanceRate = distanceRate;
                 }else{
                     reqObj.distanceRate = "";
                 }*/
                if (isSpecificReposition) {
                    if (priceKm) {
                        reqObj.distanceRate = priceKm;
                    }
                } else if (isGenericRental || isSpecificRental) {
                    if (distanceRate) {
                        reqObj.distanceRate = distanceRate;
                        reqObj.pricePerKm = distanceRate;
                    }
                } else {
                    reqObj.distanceRate = "";
                }
                if (reqObj.listingType !== 'Rental' && addtionalPrice) {
                    reqObj.dailyRate = addtionalPrice;
                }
                if (reqObj.listingType !== 'Rental' && addtionalPrice) {
                    reqObj.weeklyRate = addtionalPrice;
                }
                if (reqObj.listingType !== 'Rental' && addtionalPrice) {
                    reqObj.monthlyRate = addtionalPrice;
                }

                reqObj.quantity = quantity;
                reqObj.useEquipmentPricing = "";

                reqObj.isInsurancePaidByOwnerLiability = "N";
                reqObj.isInsurancePaidByOwnerPhysical = "N";
                reqObj.isInsurancePaidByRenterLiability = "N";
                reqObj.isInsurancePaidByRenterPhysical = "N";
                reqObj.ratePlanIdLiability = "";
                reqObj.ratePlanIdPhysical = "";

                if (isLiabilityChecked) {
                    reqObj.isInsurancePaidByOwnerLiability = (rdLbtPaidByOwner.current && rdLbtPaidByOwner.current.checked ? "Y" : "N");
                    reqObj.isInsurancePaidByRenterLiability = (rdLbtPaidByRenter.current && rdLbtPaidByRenter.current.checked ? "Y" : "N");
                    reqObj.ratePlanIdLiability = selLiability ? selLiability.insuranceRateId : "";
                }

                if (isPhysicalDamageChecked) {
                    reqObj.isInsurancePaidByOwnerPhysical = (rdPDmgPaidByOwner.current && rdPDmgPaidByOwner.current.checked ? "Y" : "N");
                    reqObj.isInsurancePaidByRenterPhysical = (rdPDmgPaidByRenter.current && rdPDmgPaidByRenter.current.checked ? "Y" : "N");
                    reqObj.ratePlanIdPhysical = selPhysicalDamage ? selPhysicalDamage.insuranceRateId : "";
                }

                reqObj.availabilityViewType = selectedViewType;
                if(selectedViewType !== "PUBLIC_VIEW"){
                    if (specificCloseNetwork?.length >0){               
                        const specificNetworks:any = specificCloseNetwork?.map((ele:any)=>{
                            let obj = {
                                renterOrgId : ele.renterOrgId,
                                orgName:ele.orgName,
                            }
                            return obj;
                        })
                        reqObj.specificNetworks = specificNetworks;
                        // reqObj.availabilityViewType = 'SPECIFIC_VIEW'
                    }else {
                        reqObj.specificNetworks=[];
                        // reqObj.availabilityViewType = 'PRIVATE_VIEW'
                    }
                }


                let distanceInMeters = 0;
                let durationInMin = 0;
                // console.log(reqObj);
                BusyLoader.showLoader();
                /* let availCraateApi:string = "";
                 let method:string = "POST";
                 if(CommonUtil.SELECT_EQUIP_AVAIL_ID == ""){
                     availCraateApi = "api/availability/?saasOrgId="+CommonUtil.getSaasOrgId();
                 }else{
                     method = "PUT";
                     availCraateApi = "api/availability/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
                 }
                 NetworkManager.sendJsonRequest(availCraateApi,reqObj,"",method,false,onCreateAvailable,onLoginError);*/

                CommonUtil.getDistanceBetweenPoints(selDepartureObj.address, selArrivalObj.address, (dataObj: any) => {
                    // console.log(dataObj);
                    if (dataObj && dataObj.distance && dataObj.distance.value) {
                        distanceInMeters = dataObj.distance.value;
                    }
                    if (dataObj && dataObj.distance && dataObj.duration.value) {
                        durationInMin = dataObj.duration.value;///60;
                    }

                    reqObj.distanceBetweenLots = distanceInMeters;
                    reqObj.travelTimeBetweenLots = durationInMin;

                    if(selectedViewType === "ASSIGN_RENTER_VIEW" && !CommonUtil.SELECT_EQUIP_AVAIL_ID && !!selectedcloseNetwork?.networkOrgId){
                        reqObj = {...reqObj,createReservation:true,renterOrgId:selectedcloseNetwork?.networkOrgId,availabilityViewType:"PRIVATE_VIEW"};
                    }

                    let availCraateApi: string = "";
                    let method: string = "POST";
                    if (CommonUtil.SELECT_EQUIP_AVAIL_ID == "") {
                        availCraateApi = "api/availability/?saasOrgId=" + saasOrg;//CommonUtil.getSaasOrgId();
                    } else {
                        method = "PUT";
                        availCraateApi = "api/availability/" + CommonUtil.SELECT_EQUIP_AVAIL_ID + "?saasOrgId=" + saasOrg;//CommonUtil.getSaasOrgId();
                    }
                    NetworkManager.sendJsonRequest(availCraateApi, reqObj, "", method, false, onCreateAvailable, onLoginError);
                });
            } else {
                displayErrorMessage();
            }
        } else {
            displayErrorMessage();
        }
        // }

    }

    let onUpdateQuantity = (dataObj: any) => {
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                onCloseEquipAvailPopup();
                getEquipmentAvailabilities();
                ToastManager.showToast(CommonUtil.getMessageText(Constants.QUANTITY_UPDATED, "Successfully Quantity updated"), "Info");
            }
        }

    }

    let displayErrorMessage = () => {
        ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"), "Error");
    }

    let onCreateAvailable = (dataObj: any) => {
        // console.log(dataObj);
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                onCloseEquipAvailPopup();
                getEquipmentAvailabilities();
                if (CommonUtil.SELECT_EQUIP_AVAIL_ID == "") {
                    if(restData.reservationCode && restData.reservationId){
                        ToastManager.showToast(`Reservation ${restData.reservationCode} and Availability Created Successfully`, "Info");
                    }else
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.AVAIL_CREATED, "Availability Created Successfully"), "Info");
                } else {
                    ToastManager.showToast(CommonUtil.getMessageText(Constants.AVAIL_UPDATED, "Availability Updated Successfully"), "Info");
                }
            }
        }

    }
    useEffect(() => {
        if(showAvailPopup)
        getPlatformFeePaidBy();
    },[showAvailPopup])
    useEffect(() => {
        console.log(props.equipData);
        equipListingStatus = "";
        reservationId = "";
        bankAccounts = [];
        bank1Currency = "";
        bank2Currency = "";
        isTaxExcemptionAvail = false;
        saasOrg = props.equipData.saasOrgId;        
        loadDirectionService();
        
    }, []);

    let loadDirectionService = () => {
        if (window.google) {
            //setScriptLoaded(true);
            // getGoogleDirection();
            loadGoogleServices();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    // setScriptLoaded(true);
                    // getGoogleDirection();
                    loadGoogleServices();
                })
            }
        }
    }
    useEffect(()=>{
        getDefaultPrices();
    },[showAvailPopup]);

    let loadGoogleServices = () => {       
        getEquipmentAvailabilities();
        getPartkingLots();
        getOrgInsurancePlanList();
        getOrgBanks();
        getTaxInfo();
        getOrganizationInfo();              
    }

    let getOrganizationCloseNetworks = () => {
        var qParam = saasOrg;//CommonUtil.getSaasOrgId();
        let avalAPI: string = 'api/organization/getOrganizationCloseNetworks?saasOrgId=' + saasOrg;//CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(avalAPI, '', '', 'GET', true, (dataObj: any) => {
            if (dataObj) {
                const restData: any = CommonUtil.parseData(dataObj);
                const ddData:any = restData?.organizationCloseNetworks.map((ele:any)=>{
                    let obj = {...ele,renterOrgName:ele.orgName,renterOrgId:ele.networkOrgId}
                    return obj;
                })
                setCloseNetworkList( ddData|| [])
            }
        }, onLoginError)
    }

    let getOrganizationInfo = () => {
        var qParam = saasOrg;//CommonUtil.getSaasOrgId();
        let avalAPI: string = 'api/organization/anyAvailbleEnabled?saasOrgId=' + saasOrg;//CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(avalAPI, '', '', 'GET', true, onGetAnyAvailableEnabled, onLoginError)
    }
    let onGetAnyAvailableEnabled = (dataObj: any) => {
        //console.log(dataObj)
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                console.log(restData);
                setIsAnyAvailable(restData.isEnableAnyAvailble == "Y" ? true : false);
                //setPage(""+Math.random()*100000);
            }
        }
    }

    let getGoogleDirection = () => {

    }

    let getTaxInfo = () => {
        let getCrediDetailsAPI: string = "api/organization/getFinance?saasOrgId=" + saasOrg;// CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getCrediDetailsAPI, "", "", "GET", true, onGetTaxInfo, onLoginError);
    }
    let onGetTaxInfo = (dataObj: any) => {
        if (dataObj) {
            var restData: any = CommonUtil.parseData(dataObj);
            if (restData) {
                if (restData.taxExemptionReason && restData.taxState && restData.taxStatus) {
                    isTaxExcemptionAvail = true;

                }
            }
        }
    }

    let getOrgBanks = () => {
        bankAccounts = [];
        let bankAPI: string = 'api/organization/finance/getBankAccounts?saasOrgId=' + saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(bankAPI, '', '', 'GET', true, onGetAllBanks, onLoginError);
    }

    let onGetAllBanks = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if (Array.isArray(restData)) {
                    bankAccounts = restData;
                } else {
                    bankAccounts.push(restData);
                }
            }
        }
        setCurrencyDropdownDisable();
    }

    let setCurrencyDropdownDisable = () => {
        if (bankAccounts.length > 0) {
            bank1Currency = bankAccounts[0].currency;
            if (bankAccounts.length > 1) {
                bank2Currency = bankAccounts[1].currency;
                setCurrencyDisable(false);
                setCurrencyClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
                setCurrencyObj({ key: bank1Currency, value: bank1Currency });
            } else {
                setCurrencyDisable(true);
                setCurrencyObj({ key: bank1Currency, value: bank1Currency });
            }
        } else {
            setCurrencyDisable(false);
            setCurrencyClass(CommonUtil.DROP_DOWN_MANDATORY_CLASS);
        }

        if (bank2Currency && bank1Currency == bank2Currency) {
            setCurrencyDisable(true);
            setCurrencyObj({ key: bank1Currency, value: bank1Currency });
        }
    }

    let getOrgInsurancePlanList = () => {
        let equipAllAPI: string = 'api/insurance/orgPolicies?orgId=' + saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetOrgInsurancePolicy, onLoginError);
    }
    let onGetOrgInsurancePolicy = (dataObj: any) => {
        insurPolicies = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setInsuranceChecked((restData.isInsuranceEnabled == "Y" ? true : false));
                setInsuranceOwnerChecked((restData.isInsurancePaidByOwner == "Y" ? true : false));
                setInsuranceRenterChecked((restData.isInsurancePaidByRenter == "Y" ? true : false));
                setLiabilityChecked((restData.isLiabilityEnabled == "Y" ? true : false));
                setPhysicalDamageChecked((restData.isPhysicalDamageEnabled == "Y" ? true : false));


                if (restData.organizationInsuranceAssociation) {
                    let arr = [];
                    if (Array.isArray(restData.organizationInsuranceAssociation)) {
                        arr = restData.organizationInsuranceAssociation;
                    } else {
                        arr.push(restData.organizationInsuranceAssociation);
                    }
                    arr.forEach((item: any, idx: number) => {
                        insurPolicies.push(item);
                    })
                }
                if (restData.isInsuranceEnabled == "Y") {
                    if (restData.isLiabilityEnabled == "Y") {
                        if (restData.isInsurancePaidByOwner == "Y") {
                            setLiabilityPaidByOwner(true);
                            setLiabilityPaidByRenter(false);

                            setIsLiabilityPaidByOwner(true);
                        } if (restData.isInsurancePaidByRenter == "Y") {
                            setIsLiabilityPaidByRenter(true);

                            if (restData.isInsurancePaidByOwner == "N") {
                                setLiabilityPaidByOwner(false);
                                setLiabilityPaidByRenter(true);
                            }

                        }
                    }
                    if (restData.isPhysicalDamageEnabled == "Y") {
                        if (restData.isInsurancePaidByOwner == "Y") {
                            setPhysicalDamagePaidByOwner(true);
                            setPhysicalDamagePaidByRenter(false);
                            setIsPhysicalDamagePaidByOwner(true);
                        } if (restData.isInsurancePaidByRenter == "Y") {
                            setIsPhysicalDamagePaidByRenter(true);

                            if (restData.isInsurancePaidByOwner == "N") {
                                setPhysicalDamagePaidByOwner(false);
                                setPhysicalDamagePaidByRenter(true);
                            }

                        }
                    }
                }

            }
        }

        setInsurPlans(insurPolicies);
        if (restData.isInsuranceEnabled == "Y") {
            if (restData.isLiabilityEnabled == "Y") {
                getLiabilityPlans();
            }
            if (restData.isPhysicalDamageEnabled == "Y") {
                getPhysicalDamagePlans();
            }
        }
    }

    let getLiabilityPlans = () => {
        let liabilityAPI: string = 'api/insurance/getRatePlanByLiability?equipmentId=' + CommonUtil.SELECT_EQUIP_ID + '&saasOrgId=' + saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(liabilityAPI, '', '', 'GET', true, onGetOrgLiabilityPlans, onLoginError);
    }

    let onGetOrgLiabilityPlans = (dataObj: any) => {
        console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let liabilityPlans: any = [];
                if (Array.isArray(restData.ratePlan)) {
                    liabilityPlans = restData.ratePlan;
                } else {
                    liabilityPlans.push(restData.ratePlan);
                }

                setLiabilityPolicies(liabilityPlans);
            }
        }
    }

    let getPhysicalDamagePlans = () => {
        let physicalDamageAPI: string = 'api/insurance/getRatePlanByPhysicalDamage?equipmentId=' + CommonUtil.SELECT_EQUIP_ID + '&saasOrgId=' + saasOrg;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(physicalDamageAPI, '', '', 'GET', true, onGetOrgPhysicalDamagePlans, onLoginError);
    }

    let onGetOrgPhysicalDamagePlans = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let physicalDamagePlans: any = [];
                if (Array.isArray(restData.ratePlan)) {
                    physicalDamagePlans = restData.ratePlan;
                } else {
                    physicalDamagePlans.push(restData.ratePlan);
                }

                setPhysicalDamagePolicies(physicalDamagePlans);
            }
        }
    }

    let getDefaultPrices = () => {
        //props.equipData
        console.log(props.equipData);
        setDefDailyRate(props.equipData.pricing.dailyRate);
        setDefWeeklyRate(props.equipData.pricing.weeklyRate);
        setDefMonthlyRate(props.equipData.pricing.monthlyRate);
        setDefDistanceRate(props.equipData.pricing.distanceRate);
        setDefEngineRate(props.equipData.pricing.engineRate);
        setEquipmentType(props.equipData.equipmentType);
        setTimeout(() => {
            updatePricingValues(props.equipData.equipmentType,{dailyRate:props.equipData.pricing.dailyRate,
                weeklyRate:props.equipData.pricing.weeklyRate,
                monthlyRate:props.equipData.pricing.monthlyRate,
                distanceRate:props.equipData.pricing.distanceRate,
                engineRate:props.equipData.pricing.engineRate},props.equipData.year);
        });
    }

    let getEquipmentAvailabilities = () => {
        setEquipData([]);
        let availableAPI: string = "api/availability/equipment/" + CommonUtil.SELECT_EQUIP_ID;//?saasOrgId="+sessionStorage.getItem("saasorgId");
        let restObj: any = {};
        restObj.vin = props.vin;
        NetworkManager.sendJsonRequest(availableAPI, "", "", "GET", true, onGetAvailabilities, onLoginError);
    }

    let onGetAvailabilities = (dataObj: any) => {
        // console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let equipAvails: any = [];
                if (Array.isArray(restData)) {
                    equipAvails = restData;
                } else {
                    equipAvails.push(restData);
                }
                equipAvails.map((item: any, idx: number) => {
                    item.FullMonth = CommonUtil.getFullMonth(item.startDate);
                    item.FullYear = CommonUtil.getFullYear(item.startDate);
                });
                //console.log(equipAvails);
                // setEquipData(equipAvails);
                setEquipData(getAvailability(equipAvails));
            }
        }
    }

    const getReservationPrices = () => {
        let reservationAPI: string = "api/reservation/costestimate/?reservationId=" + CommonUtil.SELECT_RESERVATION_ID + "&saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(reservationAPI, "", "", "GET", true, onGetReservationPrices, onLoginError);
    }

    let onGetReservationPrices = (dataObj: any) => {
        console.log(dataObj);
        // console.log("total days:"+totalDays+","+liabilityInsuracePerDayAmount+","+physicalDamagePerDayAmount);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                //setDays(restData.noOfDays);


                let totAmt = "";//
                let totalDays = 0;//let days = CommonUtil.getDays(stDate, endDate);
                let insurAmount = 0;//
                let insurPer = 0;

                // if(isLiabilityPaidByRenter){
                //     insurAmount =  (totalDays*Number(liabilityInsuracePerDayAmount));
                // }
                // if(isPhysicalDamagePaidByRenter){
                //     insurAmount =  insurAmount+(totalDays*Number(physicalDamagePerDayAmount));
                // }

                // if(insurAmount){
                //     insurPer = (insurAmount)*(10/100);
                // }

                // let totPrice = (Number(restData.movePrice)+Number(restData.durationPrice));
                // totPrice = totPrice-(Number(insurAmount)+Number(insurPer));
                // // setRepositionPrice(totPrice?totPrice.toFixed(2):"");

                // let totalPrice = Number(restData.distancePrice) + Number(restData.durationPrice) + Number(restData.ownerServiceFee);
                // totalPrice = totalPrice+Number(insurAmount)+Number(insurPer);
                // if (restData.enginePrice) {
                //     totalPrice = totalPrice + Number(restData.enginePrice);
                // }
                // // setRentalPrice( totalPrice?totalPrice.toFixed(2):"");


            }
        }
    }

    const getAvailability = (data: any) => {
        try {
            let prevDates = {
                startDate: "",
                endDate: ""
            }
            let avail_data: any = [];
            data.map((ele: any) => {
                const st1 = new Date(ele.startDate)
                const st2 = new Date(prevDates.startDate || ele.startDate)

                const ed1 = new Date(ele.endDate)
                const ed2 = new Date(prevDates.endDate || ele.endDate)

                // console.log(ele.startDate);
                if ((prevDates.startDate === "" && prevDates.endDate === "") || (Math.abs(st1.getTime() - st2.getTime()) !== 0 && Math.abs(ed1.getTime() - ed2.getTime()) !== 0)) {
                    // console.log(st1,'<->',st2); 
                    avail_data.push({
                        ...ele, startAvailablity: true,
                        activationDate: null,
                        deactivationDate: null,
                        reservationCode: null,
                        reservationId: null,
                        reservationEndDate: null,
                        reservationStartDate: null
                    });
                }
                if (ele.activationDate || ele.deactivationDate || ele.reservationCode || ele.reservationStartDate || ele.reservationEndDate)
                    avail_data.push(ele);
                prevDates.startDate = ele.startDate;
                prevDates.endDate = ele.endDate;
                // console.log(st1,'->',st2);            
                // console.log(ele.reservationEndDate);
                // console.log(ele.reservationStartDate);
                // console.log('->');
                // console.log(ele.startDate,'->',ele.endDate);
            })
            console.log(avail_data);

            return (avail_data);

        }
        catch (e) {
            return data;
        }
    }

    let getPartkingLots = () => {
        parkingLotArray = [];

        //http://54.184.126.249:8080/api/parkinglots/all?orgId=56
        let parkingAPI: string = "api/parkinglot/all?saasOrgId=" + saasOrg;//CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetParkingLots, onLoginError);

        let parkingLots = [{ parkingLotId: '1', address: '2135 O\'Toole Ave, San Jose, CA 95131, United States' },
        { parkingLotId: '2', address: '2135 O\'Toole Ave, San Jose, CA 95131, United States2' }];

        //setParkingLots(parkingLots);
    }

    let onGetParkingLots = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                //let parkingLots:any = [];
                /*if(Array.isArray(restData)){
                    parkingLots = restData;
                }else{
                    parkingLots.push(restData);
                }*/

                if (Array.isArray(restData.parkingLots)) {
                    parkingLotArray = restData.parkingLots;
                } else {
                    parkingLotArray.push(restData.parkingLots);
                }
                setParkingLots(parkingLotArray);
            }
        }
    }

    let onClickGetAvailDetails = (avlId: string, type: string, rid: string, reservationId: string, rest: any) => {

        if (reservationId) {
            localStorage.setItem("ReservationTab", "true");
            let equipId: string = rest.equipmentId;
            let reservationAssetType: string = rest.assetType;
            let isGeneric: string = rest.isGeneric;
            let equipType: string = rest.equipmentType;
            let availId: string = rest.equipmentListingId;

            let siteOrigin: string = window.location.origin;
            siteOrigin = siteOrigin + "/?requestor=reservationview&equipid=" + equipId + "&token=" + localStorage.getItem("token");
            siteOrigin = siteOrigin + "&reservationId=" + reservationId;
            siteOrigin = siteOrigin + "&assetType=" + reservationAssetType;
            siteOrigin = siteOrigin + "&equipType=" + equipType;
            siteOrigin = siteOrigin + "&generic=" + isGeneric;
            siteOrigin = siteOrigin + "&availId=" + availId;

            var newTab = document.createElement('a');
            newTab.href = siteOrigin;
            newTab.setAttribute('target', '_blank');
            newTab.click();
            return;
        }

        setSelPolicyObj(null);
        setRatePerDay("");
        CommonUtil.SELECT_EQUIP_AVAIL_ID = avlId;
        CommonUtil.EQUIP_AVAIL_TYPE = type;
        reservationId = rid;
        CommonUtil.EQUIP_AVAIL_STATUS = "";
        if (rid) {
            CommonUtil.EQUIP_AVAIL_STATUS = rid;
        }
        if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
            setIslabelRental(true);
        } else {
            setIslabelRental(false);
        }


        onShowEquipAvailPopup();
        let parkingAPI: string = "api/availability/" + avlId + "?saasOrgId=" + saasOrg;//CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetAvailabilityDetails, onLoginError);
    }

    let getInsurPolicyById = (pId: string) => {
        for (let i = 0; i < insurPolicies.length; i++) {
            if (insurPolicies[i].id == pId) {
                return insurPolicies[i].policyId;
            }
        }
        return "";
    }



    let onGetAvailabilityDetails = (dataObj: any) => {
        setAvailabilityType();

        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if (stDateRef.current && restData.startDate) {
                    let stDate = CommonUtil.getServerDate(restData.startDate);
                    if (stDate) {
                        avlStDate = stDate;

                        stDateRef.current.flatpickr.setDate(stDate);

                        stDateRef.current.flatpickr.set("enable", [stDate, function (date: any) {
                            // return true to enable
                            //console.log(date);
                            return (date.getTime() >= (new Date().getTime() - (24 * 60 * 60 * 1000)));

                        }]);
                        //stDateRef.current.flatpickr.set("minDate",new Date());
                        setStartDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
                        setStDate(stDate);
                    }

                }
                if (endDateRef.current && restData.endDate) {
                    let edDate = CommonUtil.getServerDate(restData.endDate);
                    if (edDate) {
                        avlEndDate = edDate;
                        endDateRef.current.flatpickr.setDate(edDate);
                        endDateRef.current.flatpickr.set("enable", [edDate, function (date: any) {
                            // return true to enable
                            //console.log(date);
                            return (date.getTime() >= (new Date().getTime() - (24 * 60 * 60 * 1000)));

                        }]);
                        setEndDateClass(CommonUtil.DATE_FIELD_VALID_CLASS);
                        setEndDate(edDate);
                    }
                }
                if (stDateRef.current) {
                    //stDateRef.current.flatpickr.set("minDate",new Date());
                    //stDateRef.current.flatpickr
                }
                if (endDateRef.current) {
                    endDateRef.current.flatpickr.set("minDate", new Date());
                }

                onChangeDates();
                setQuantity(restData.quantity);
                CommonUtil.EQUIP_AVAIL_TYPE = restData.listingType;
                setEDisRate(restData.estimatedDistance);
                if (restData.dailyRate == 0) {
                    setDailyRate(restData.dailyRate.toString());
                } else {
                    setDailyRate(restData.dailyRate);
                }
                if (restData.weeklyRate == 0) {
                    setWeeklyRate(restData.weeklyRate.toString());
                } else {
                    setWeeklyRate(restData.weeklyRate);
                }
                if (restData.monthlyRate == 0) {
                    setMonthlyRate(restData.monthlyRate.toString());
                } else {
                    setMonthlyRate(restData.monthlyRate);
                }
                if (restData.distanceRate == 0) {
                    setDistanceRate(restData.distanceRate.toString());
                } else {
                    setDistanceRate(restData.distanceRate);
                }
                if (restData.engineRate == 0) {
                    setEngineRate(restData.engineRate.toString());
                } else {
                    setEngineRate(restData.engineRate);
                }

                setNoOfDays(restData.noOfDaysAllowed);
                if (restData.movePrice == 0) {
                    setMovePrice(restData.movePrice.toString());
                } else {
                    setMovePrice(restData.movePrice);
                }
                if (restData.distanceRate == 0) {
                    setPriceKm(restData.distanceRate.toString());
                } else {
                    setPriceKm(restData.distanceRate);
                }
                if (restData.dailyRate == 0) {
                    setAdditionalPrice(restData.dailyRate.toString());

                } else {
                    setAdditionalPrice(restData.dailyRate);
                }
                if (restData.weeklyRate == 0) {
                    setAdditionalPrice(restData.weeklyRate.toString());

                } else {
                    setAdditionalPrice(restData.weeklyRate);
                }
                if (restData.monthlyRate == 0) {
                    setAdditionalPrice(restData.monthlyRate.toString());

                } else {
                    setAdditionalPrice(restData.monthlyRate);
                }

                if (restData.additionalPriceExcessDays) {
                    setAdditionalPrice(restData.additionalPriceExcessDays.toString());
                }

                setTimeout(() => {
                    if (restData.noOfDaysAllowed) {
                        CommonUtil.isMandatory(noOfDayRef);
                    }
                    if (restData.dailyRate) {
                        CommonUtil.isMandatory(dailyRef);
                        CommonUtil.isMandatory(priceRef);
                    }
                    if (restData.distanceRate) {
                        CommonUtil.isMandatory(distanceRef);
                    }
                    if (restData.engineRate) {
                        CommonUtil.isMandatory(engineRef);
                    }
                    if (restData.movePrice) {
                        CommonUtil.isMandatory(movePriceRef);
                    }
                    if (restData.quantity) {
                        CommonUtil.isMandatory(qtyRef);
                    }
                }, 100);


                if (restData.allowOffer == "Y") {
                    if (chkAllowRef.current) {
                        chkAllowRef.current.checked = true;
                    }
                }
                if (restData.isEnableAnyAvailble == "Y") {
                    if (chkPickRef.current) {
                        chkPickRef.current.checked = true;
                        onChangePickAnyAvailable();
                    }
                }
                if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                    setCurrencyDropdownDisable();
                }

                setSelLiability({ insuranceRateId: restData.ratePlanIdLiability, policy: restData.policyNameLiability });
                setSelPhysicalDamage({ insuranceRateId: restData.ratePlanIdPhysical, policy: restData.policyNamePhysical });

                setLiabilityPaidByOwner((restData.isInsurancePaidByOwnerLiability == "Y") ? true : false);
                setLiabilityPaidByRenter((restData.isInsurancePaidByRenterLiability == "Y") ? true : false);
                setPhysicalDamagePaidByOwner((restData.isInsurancePaidByOwnerPhysical == "Y") ? true : false);
                setPhysicalDamagePaidByRenter((restData.isInsurancePaidByRenterPhysical == "Y") ? true : false);


                setCurrencyObj({ key: restData.defaultCurrency, value: restData.defaultCurrency });
                setSelPolicyObj({ id: restData.policyId, policyId: getInsurPolicyById(restData.policyId) });
                setRatePerDay(restData.insurancePerDayAmount);

                let dLotName: string = CommonUtil.getDropdownText(parkingLots, restData.departureParkingLotId, "parkingLotId", "parkingLotName");
                let aLotName: string = CommonUtil.getDropdownText(parkingLots, restData.arrivalParkingLotId, "parkingLotId", "parkingLotName");

                setSelDepartureObj({ parkingLotId: restData.departureParkingLotId, parkingLotName: dLotName, address: restData.departureParkingLotAddress });
                setSelArrivalObj({ parkingLotId: restData.arrivalParkingLotId, parkingLotName: aLotName, address: restData.arrivalParkingLotAddress });
                setLoadTypeObj({ key: restData.loadType, value: restData.loadType });

                (restData.loadType ? setLoadTypeClass(CommonUtil.DROPDOWN_VALID_CLASS) : setLoadTypeClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                (restData.arrivalParkingLotId ? setArrivalAddrClass(CommonUtil.DROPDOWN_VALID_CLASS) : setArrivalAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                (restData.departureParkingLotId ? setDepartureAddrClass(CommonUtil.DROPDOWN_VALID_CLASS) : setDepartureAddrClass(CommonUtil.DROPDOWN_INVALID_CLASS));
                (restData.defaultCurrency ? setCurrencyClass(CommonUtil.DROPDOWN_VALID_CLASS) : setCurrencyClass(CommonUtil.DROPDOWN_INVALID_CLASS));


                CommonUtil.EQUIP_AVAILABILITY_STATUS = restData.listingStatus;
                setDisabled(false);
                setTermsDisable(false);
                setRepDisabled(false);
                if (chkTerms && chkTerms.current) {
                    chkTerms.current.checked = true;
                }

                equipListingStatus = restData.listingStatus;

                if ((restData.listingStatus == CommonUtil.EQUIP_AVAILABILITY_STATUS_COMPLETED || restData.listingStatus == CommonUtil.EQUIP_AVAILABILITY_STATUS_IN_PROGRESS)) {
                    setDisabled(true);
                    setCurrencyDisable(true);
                    if (CommonUtil.IS_GENERIC_EQUIP && CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                        setRepDisabled(false);
                    } else {
                        setRepDisabled(true);
                    }

                }
                setSelectedViewType(restData?.availabilityViewType);
                setSpecificCloseNetwork(restData?.specificNetworks);
                setContactNumber(restData?.contractNumber);
                console.log(restData.specificNetworks);
                
            }

        }
    }

    const getPlatformFeePaidBy = () => {
        let getAPI: string = `api/organization/getPlatformFeesPaidBy?saasOrgId=${CommonUtil.getSaasOrgId()}`;
        NetworkManager.sendJsonRequest(getAPI, "", "", "GET", true, (dataObj: any) => {
            console.log(dataObj)
            if (dataObj) {
                var restData = JSON.parse(dataObj);
                Constants.PLATFORMFEE_PERCENT = restData?.repositionPlatformFeesPer || Constants.PLATFORMFEE_PERCENT;
                Constants.MIN_PLATFORMFEE = restData?.minimumPlatformFees || Constants.MIN_PLATFORMFEE;
                if (restData.platformFeesPaidByRenter) {
                    setPlatformFeesPaidByOwner("Renter")
                } else if (restData.platformFeesPaidByOwner) {
                    setPlatformFeesPaidByOwner("Owner")
                } else {
                    setPlatformFeesPaidByOwner("Repositioner")
                }
            }
        }, onLoginError);
    }

    let onChangePickAnyAvailable = () => {
        let checked: boolean = false;
        if (chkPickRef.current) {
            checked = chkPickRef.current.checked;
        }
        setParkingLots([]);
        // setSelDepartureObj({});
        // setSelArrivalObj({});

        let parkLots: any = [];
        parkingLotArray.forEach((pItem: any, pIdx: number) => {
            if (checked) {
                if (pItem.isDummy == "N") {
                    parkLots.push(pItem);
                }
            } else {
                parkLots.push(pItem);
            }

        });
        setParkingLots(parkLots);
    }

    let onDevAvailShowPopup = () => {
        setTimeout(function () {
            // setPopupCenter('devAvailPopup', 'devAvailContentPopup')
        }, 100)
        // setSelectedViewType("")
        getOrganizationCloseNetworks();
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 800;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }

    let onSelectPolicy = (data: any) => {
        setSelPolicyObj(data);
        setRatePerDay("");
        getRatePerDay(data);
    }

    let getRatePerDay = (data: any) => {
        if (data) {
            let rateAPI: string = "api/insurance/getRatePlanByEquipmentIdAndPolicyId?equipmentId=" + CommonUtil.SELECT_EQUIP_ID + "&policyId=" + data.id;
            NetworkManager.sendJsonRequest(rateAPI, "", "", "GET", true, onPolicyRateDetails, onLoginError);
        }
    }

    let onPolicyRateDetails = (dataObj: any) => {
        // console.log(dataObj);
        var restData = CommonUtil.parseData(dataObj);
        if (restData) {
            setRatePerDay(restData.ratePerDay);
        }
    }

    let onChangeLiability = (dataObj: any) => {
        setSelLiability(dataObj);
        (dataObj) ? setLiabilityClass('select-box-valid') : setLiabilityClass('select-box-invalid');
    }

    let onChangePhysicalDamage = (dataObj: any) => {
        setSelPhysicalDamage(dataObj);
        (dataObj) ? setPhysicalDamageClass('select-box-valid') : setPhysicalDamageClass('select-box-invalid');
    }

    let onChangeLiabilityPaidByOwner = (e: any) => {
        console.log(e.currentTarget.checked);
        if (e.currentTarget.checked) {
            setLiabilityPaidByOwner(true);
            setLiabilityPaidByRenter(false);
        }
    }

    let onChangeLiabilityPaidByRenter = (e: any) => {
        console.log(e.currentTarget.checked);
        if (e.currentTarget.checked) {
            setLiabilityPaidByOwner(false);
            setLiabilityPaidByRenter(true);
        }
    }

    let onChangePhysicalDamageOwner = (e: any) => {
        if (e.currentTarget.checked) {
            setPhysicalDamagePaidByOwner(true);
            setPhysicalDamagePaidByRenter(false);
        }
    }

    let onChangePhysicalDamageRenter = (e: any) => {
        if (e.currentTarget.checked) {
            setPhysicalDamagePaidByOwner(false);
            setPhysicalDamagePaidByRenter(true);
        }
    }

    const platformFeeMsgForPaidByRenter = () => {
        let msg: any = "";
        const percent:any = Constants.PLATFORMFEE_PERCENT/100;
        if (platformFeesPaidByOwner === "Renter") {
            const pf: any = Math.max(Number(movePrice) * percent, Constants.MIN_PLATFORMFEE);
            msg = `Availability will be listed for ${currencyObj?.value} ${(Number(movePrice) - pf)} and the owner will end up paying a total of ${currencyObj?.value} ${Number(movePrice)} that includes ${pf > Constants.MIN_PLATFORMFEE ? `${Constants.PLATFORMFEE_PERCENT}% of Platform Fee` : `Platform Fee of ${currencyObj?.value} ${pf}`}`
            if (Number(movePrice) < Constants.MIN_PLATFORMFEE)
                msg = `Please enter value greater than ${Constants.MIN_PLATFORMFEE}`;
        }

        if (platformFeesPaidByOwner === "Owner") {
            msg = `Availability will be listed for ${currencyObj?.value} ${Number(movePrice)} and the owner will end up paying ${currencyObj?.value} ${Number(movePrice) + Math.max((Number(movePrice) * percent), Constants.MIN_PLATFORMFEE)} that includes ${Constants.PLATFORMFEE_PERCENT}% Platform Fee`;
        }

        return msg;
    }



    let onLoginError = (data: any) => {
        console.log(data);
    }
    let rateObj: any = {}
    let newMonthlyRate:number = Number(defMonthlyRate);
    try {
        if (stDateRef.current && endDateRef.current) {
            const startDate = stDateRef.current.flatpickr.selectedDates[0];
            const endDate = endDateRef.current.flatpickr.selectedDates[0];
            console.log(CommonUtil.getDays(startDate, endDate)," -------- CommonUtil.getDays(startDate, endDate)");
            
            
            rateObj = getSelectedRangeRate(CommonUtil.getDays(startDate, endDate), Number(defDailyRate), Number(defWeeklyRate), Number(defMonthlyRate))
            if(CommonUtil.getDays(startDate, endDate) > 364)
                newMonthlyRate= (Number(defYearlyRate) || Number(defMonthlyRate))
            else
                newMonthlyRate = Number(defMonthlyRate);        
        }
        // console.log(rateObj);

    } catch (e) {
        rateObj = {};
    }

    

    let selectedAvailView: any = "";
    try {
        selectedAvailView = availableRental?.filter((ele: any) => ele.key == selectedViewType)[0];
    } catch (e) {
        selectedAvailView = "";
    }

    let closeNetworkListNotSelected: any = [...closeNetworkList];
    try {
        closeNetworkListNotSelected = closeNetworkList?.filter((ele: any) =>{
            const idx:any = specificCloseNetwork?.findIndex((item:any)=>{
                return item.renterOrgId == ele.renterOrgId 
            })
            // console.log(idx,specificCloseNetwork);
            
            return idx == -1;
    });
    } catch (e) {
        closeNetworkListNotSelected = [...closeNetworkList];
    }

    const handleInputPhone = (e: any) => {
        setContactNumber(e);
    }

    return (
        <>
            <Modal show={showEquipPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{ height: '450px' }}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0 fw-bold text-center black-text" style={{ width: '97%', marginLeft: '3%' }}>{CommonUtil.getMessageText(Constants.NEW_AVAILABILITY, "New Availability")}</h5>
                        {/* <button type="button" className="close" data-dismiss="modal" style={{ width:'3%', height:'10%' }} aria-label="Close" onClick={onCloseEquipPopup} /> */}
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseEquipPopup}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body color-black my-2" style={{ paddingTop: '10px' }}>
                        <div className="row fs-7 px-6">
                            <div className="col-md-6 text-center">
                                <div className="col-md-12 generic-equip p-3" style={{ height: '289px' }} onClick={onClickRenter}>
                                    <img src={"../../images/new-availability-location-1.png"} style={{ width: '110px' }} />
                                    <p style={{ marginTop: 0, marginBottom: '1rem' }}>
                                        <strong>{CommonUtil.getMessageText(Constants.RENTAL, "Rental")} </strong>{' '}
                                    </p>
                                    <p style={{ marginTop: 0, marginBottom: '1rem' }}>
                                        {CommonUtil.getMessageText(Constants.MULTIPLE_SIMILAR_EQUIPS, " Display multiple similar equipment in a single listing to\n" +
                                            "                                        simplify the management of their availabilites.")}

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="col-md-12 specific-equip p-3" style={{ height: '289px' }} onClick={onClickReposition}>
                                    <img src={"../../images/new-availability-location-2.png"} style={{ width: '110px' }} />
                                    <p style={{ marginTop: 0, marginBottom: '1rem' }}>
                                        <strong>{CommonUtil.getMessageText(Constants.REPOSITIONING, "Repositiong")}</strong>{' '}
                                    </p>
                                    <p style={{ marginTop: 0, marginBottom: '1rem' }}>
                                        {CommonUtil.getMessageText(Constants.SPECIFIC_EQUIPS, " This specific equipment \n" +
                                            "                                        Will be the one made available \n" +
                                            "                                        for the renters.")}

                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer clsModalPermissionsFootr">
                        <button type="button" className="btn btn-outline-dark btn-sm"
                            style={{ backgroundColor: '#00CFB4', color: '#fff' }}
                            data-bs-dismiss="modal" onClick={onCloseEquipPopup}>
                            {CommonUtil.getMessageText(Constants.CLOSE, "Cancel")}
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal show={showAvailPopup} dialogAs={DraggableComponent} id="devAvailPopup" onShow={onDevAvailShowPopup}>
                <div className="modal-content" id="devAvailContentPopup" style={{ "width": "705px", "height": "650px" }}>
                    <div className="modal-header">
                        <h6 className="modal-title mt-0 fw-bold text-center black-text">{(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) ? CommonUtil.getMessageText(Constants.REPOSITION, "Reposition") : CommonUtil.getMessageText(Constants.RENTAL, "Rental")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseEquipAvailPopup} style={{ marginLeft: "0px" }} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ paddingTop: "0px", overflow: "auto" }}>
                        <div className="container" style={{ padding: "0px" }}>
                            <br />
                            <section style={{ boxShadow: "none", padding: "0px" }}>
                                {/*<h5 style={{textAlign:"center"}}>New Repositioning</h5>*/}
                                <p style={{ textAlign: "center", marginBottom: "0px" }}>{CommonUtil.getMessageText(Constants.AVAIL_DATES, "Availabilities will be automatically reallocated if a reservation is made on a subset of the dates")}</p>

                                <div className="row" style={{ paddingTop: "10px", display: (isAnyAvailable && CommonUtil.IS_GENERIC_EQUIP ? "" : "none") }}>
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-12  flex">
                                                <input ref={chkPickRef} type="checkbox" className="checkBox" disabled={isDisabled ? true : false}
                                                    onChange={onChangePickAnyAvailable} />
                                                <label style={{ paddingLeft: "10px", display: "inline" }}>
                                                    {CommonUtil.getMessageText(Constants.PICK_ANY_AVAILABLE_TRAILER, "Pick any available trailer")}
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="form-group row mt-3">
                                    <label className="col-sm-12 col-form-label">Contract Number<span
                                        className="text-danger"></span></label>
                                    <div className="col-sm-6">
                                        <input
                                            // country={'us'}
                                            // countryCodeEditable={false}
                                            // containerClass={phoneClass}
                                            value={contactNumber}
                                            className="form-control clsStandardText"
                                               placeholder="Contract Number"
                                           // placeholder={CommonUtil.getMessageText(Constants.ENTER_MOBILE_NUMBER, "Enter Phone Number")}
                                           // inputClass={`input-phone ${contactNumber.toString().length === 11 ? '' : 'inputMandatory'}`}
                                           onChange={(e:any) => {
                                               // handleInputPhone(e)
                                               setContactNumber(e.target.value)
                                           }}
                                            // containerStyle={{
                                            //     width: '100%',
                                            // }}
                                            // disableCountryGuess={true}
                                            // disableDropdown={true}
                                            // disableSearchIcon={true}
                                        />
                                        
                                    </div>
                                
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STARTS_AT, "Starts at")}:</label>
                                            <div className="col-sm-12">
                                                <Flatpickr placeholder="Start Date" ref={stDateRef} options={dateOptions} onChange={onChangeStDate}
                                                    onOpen={onOpenStDate} className={startDateClass} onClose={onCloseStDate}
                                                    disabled={isRepDisabled ? true : false} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT, "Ends at")}:</label>
                                            <div className="col-sm-12">
                                                <Flatpickr placeholder="End Date" ref={endDateRef} options={dateOptions} onChange={onChangeEdDate}
                                                    onOpen={onOpenEDDate} className={endDateClass}
                                                    disabled={isRepDisabled ? true : false} />
                                            </div>
                                        </div>
                                    </div>
                                    {/*islabelRental ?
                                      <div className="col-sm-6 col-6">
                                      <div className="form-group row">
                                          <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT,"Ends at")}:</label>
                                          <div className="col-sm-12">
                                              <Flatpickr  placeholder="End Date" ref={endDateRef} options={dateOptions}  onChange={onChangeEdDate}
                                                          onOpen={onOpenEDDate} className={endDateClass}
                                                          disabled={isDisabled?true:false}/>
                                          </div>
                                      </div>
                                  </div> :  <Flatpickr  placeholder="End Date" ref={endDateRef} options={dateOptions}  onChange={onChangeEdDate}
                                                          onOpen={onOpenEDDate} className="form-control css-2rsrq9 clsStandardText  clsFormInputControl flat-picker d-none"
                                                          disabled={isDisabled?true:false}/>
                                    */ }

                                    <div className="col-sm-6 col-6" style={{ display: ((isSpecificReposition || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAIL_EQUIP, "Available equipment for this period")}</label>
                                            <div className="col-sm-12">
                                                <input ref={qtyRef} type="number" value={quantity} min={0} className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" pattern="\d+" onChange={(e) => { setQuantity(e.currentTarget.value); CommonUtil.isMandatory(qtyRef); }}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-6 col-6" style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAYS_ALLOWED, "Days allowed for repositioning")}</label>
                                            <div className="col-sm-12">
                                                <input ref={noOfDayRef} type="number" min={0} value={noOfDays} pattern="^[0-9]" className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" onChange={handleNoOfDays}
                                                    disabled={isRepDisabled ? true : false} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12" style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LOAD_TYPE, "Load Type")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={loadTypes} selectedValue={loadTypeObj}
                                                    handleChange={onChangeLoadType} width={"100%"} value={"key"} label={"value"}
                                                    className={loadTypeClass} disabled={isDisabled ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 className="mt-2">Location</h6>
                                <div className="row">
                                    <div className="col-sm-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT, "Departure Parking Lot")}</label>
                                            <div className="col-sm-12 col-12">
                                                <SingleSelectComp dataSource={parkingLots} selectedValue={selDepartureObj}
                                                    handleChange={onChangeDepartureLot} width={"100%"} value={"parkingLotId"} label={"parkingLotName"}
                                                    className={departureAddrClass} disabled={isRepDisabled ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT, "Arrival Parking Lot")}</label>
                                            <div className="col-sm-12 ">
                                                <SingleSelectComp dataSource={parkingLots} selectedValue={selArrivalObj}
                                                    handleChange={onChangeArrivalLot} width={"100%"} value={"parkingLotId"} label={"parkingLotName"}
                                                    className={arrivalAddrClass} disabled={isRepDisabled ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div style={{ display: (isInuranceChecked ? "" : "none") }}>
                                        <h6 className="mt-2">{CommonUtil.getMessageText(Constants.INSURANCE, "Insurance")}</h6>
                                        <div className="row" style={{ display: (isLiabilityChecked ? "" : "none") }}>
                                            <div className="col-sm-6 col-12" >
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LIABILITY_PLAN, "Liability Plan")}</label>
                                                    <div className="col-sm-12 col-12">
                                                        <SingleSelectComp dataSource={liabilityPolicies} selectedValue={selLiability}
                                                            handleChange={onChangeLiability} width={"100%"} value={"insuranceRateId"} label={"policy"}
                                                            className={liabilityClass} disabled={isDisabled ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12" style={{ paddingTop: "8px" }}>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{ visibility: "hidden" }}>{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                    <div className="col-sm-6 col-6 flex" style={{ display: (isLiabilityPaidByOwner ? "" : "none") }}>
                                                        <input ref={rdLbtPaidByOwner} type="radio" name="liability" className="checkBox" checked={liabilityPaidByOwner}
                                                            disabled={isDisabled ? true : false} onChange={onChangeLiabilityPaidByOwner} />
                                                        <span style={{ paddingLeft: "10px", display: "inline" }}>
                                                            {CommonUtil.getMessageText(Constants.PAID_BY_OWNER, "Paid By Owner")}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-6 flex" style={{ display: (isLiabilityPaidByRenter ? "" : "none") }}>
                                                        <input ref={rdLbtPaidByRenter} type="radio" name="liability" className="checkBox" checked={liabilityPaidByRenter}
                                                            disabled={isDisabled ? true : false} onChange={onChangeLiabilityPaidByRenter} />
                                                        <span style={{ paddingLeft: "10px", display: "inline" }}>
                                                            {CommonUtil.getMessageText(Constants.PAID_BY_RENTER, "Paid By Renter")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row" style={{ display: (isPhysicalDamageChecked ? "" : "none") }}>
                                            <div className="col-sm-6 col-12" >
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_PLAN, "Physical Damage Plan")}</label>
                                                    <div className="col-sm-12 col-12">
                                                        <SingleSelectComp dataSource={physicalDamagePolicies} selectedValue={selPhysicalDamage}
                                                            handleChange={onChangePhysicalDamage} width={"100%"} value={"insuranceRateId"} label={"policy"}
                                                            className={physicalDamageClass} disabled={isDisabled ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-12" style={{ paddingTop: "8px" }}>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label" style={{ visibility: "hidden" }}>{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                    <div className="col-sm-6 col-6 flex" style={{ display: (isPhysicalDamagePaidByOwner ? "" : "none") }}>
                                                        <input ref={rdPDmgPaidByOwner} type="radio" name="physicalDamage" className="checkBox" checked={physicalDamagePaidByOwner}
                                                            disabled={isDisabled ? true : false} onChange={onChangePhysicalDamageOwner} />
                                                        <span style={{ paddingLeft: "10px", display: "inline" }}>
                                                            {CommonUtil.getMessageText(Constants.PAID_BY_OWNER, "Paid By Owner")}
                                                        </span>
                                                    </div>
                                                    <div className="col-sm-6 col-6 flex" style={{ display: (isPhysicalDamagePaidByRenter ? "" : "none") }}>
                                                        <input ref={rdPDmgPaidByRenter} type="radio" name="physicalDamage" className="checkBox" checked={physicalDamagePaidByRenter}
                                                            onChange={onChangePhysicalDamageRenter} disabled={isDisabled ? true : false} />
                                                        <span style={{ paddingLeft: "10px", display: "inline" }}>
                                                            {CommonUtil.getMessageText(Constants.PAID_BY_RENTER, "Paid By Renter")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                    <h6 className="mt-1">{CommonUtil.getMessageText(Constants.PRICING, "Pricing")}</h6>
                                    <div className="col-sm-6 col-12">
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CURRENCY, "Currency")}</label>
                                            <div className="col-sm-12 col-12">
                                                <SingleSelectComp dataSource={currencies} selectedValue={currencyObj}
                                                    disabled={isCurrecyDisable ? true : false} handleChange={onChangeCurrency} width={"100%"}
                                                    value={"key"} label={"value"} className={currencyClass} />
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="row">
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="col-sm-12 col-12">

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 col-12 col-md-6" style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.PAID_PRICE, "Paid Price to Carrier for Repositioning")}:</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={movePriceRef} type="text" className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" value={movePrice} min={0} aria-label="trailer" disabled={isDisabled ? true : false}
                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: movePriceRef, setState: setMovePrice }) }} />
                                            </div>
                                            <p style={{ marginBottom: "0px", paddingTop: "5px", fontSize: "12px", color: "red", display: movePrice ? "" : "none" }}>
                                                {platformFeeMsgForPaidByRenter()}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6" style={{ display: ((isGenericRental || isSpecificRental) ? "none" : "") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDITIONAL_PRICE, "Additional Price per Excess Days")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={priceRef} type="text" className="form-control clsStandardText clsFormInputControl"
                                                    placeholder="" aria-label="trailer" value={addtionalPrice} min={0} disabled={isDisabled ? true : false}
                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: priceRef, setState: setAdditionalPrice }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ display: "none" }}>
                                    <div className="col-sm-6 col-12 col-md-6" >
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.EST_DISTANCE, "Estimated excess days mileage")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={eDisRef} type="text" min={0} className="form-control clsStandardText" value={eDisRate}
                                                    disabled={isDisabled ? true : false} placeholder="" aria-label="trailer"
                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: null, setState: setEDisRate }) }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.PRICE_PER_MILE, "Price per mile")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <input ref={priceKmRef} type="text" min={0} className="form-control clsStandardText"
                                                    placeholder="" aria-label="trailer" value={priceKm}
                                                    disabled={isDisabled ? true : false}
                                                    onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: null, setState: setPriceKm }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: "-20px", display: ((isSpecificReposition || isGenericReposition) ? "none" : "") }}>
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="col-sm-12 col-12 col-md-12" style={{ visibility: "hidden" }}>
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label" style={{ paddingLeft: "0px" }}>Default Price(s)</label>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input ref={dailyRef} type="text" min={0} pattern="[0-9]*" className="form-control clsStandardText avail-text-field"
                                                        placeholder="" aria-label="trailer" value={dailyRate} disabled={isDisabled ? true : false}
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: dailyRef, setState: setDailyRate }) }} /> / {CommonUtil.getMessageText(Constants.DAY, "DAY")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12" style={{ display: !defWeeklyRate && !weeklyRate ? "none" : "" }}>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input ref={weeklyRef} type="text" min={0} pattern="[0-9]*" className="form-control clsStandardText  avail-text-field"
                                                        placeholder="" aria-label="trailer" value={weeklyRate}
                                                        disabled={isDisabled ? true : false}
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: weeklyRef, setState: setWeeklyRate }) }} /> / {CommonUtil.getMessageText(Constants.WEEK, "WEEK")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12" style={{ display: !defMonthlyRate && !monthlyRate ? "none" : "" }}>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.MONTHLY_RATE, "Monthly Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input ref={monthlyRef} type="text" min={0} pattern="[0-9]*" className="form-control clsStandardText  avail-text-field"
                                                        placeholder="" aria-label="trailer" value={monthlyRate}
                                                        disabled={isDisabled ? true : false}
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: monthlyRef, setState: setMonthlyRate }) }} /> / {CommonUtil.getMessageText(Constants.MONTH, "MONTH")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DIS_RATE, "Distance Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input ref={distanceRef} type="text" min={0} className="form-control clsStandardText clsFormInputControl avail-text-field" value={distanceRate}
                                                        disabled={isDisabled ? true : false} placeholder="" aria-label="trailer"
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: distanceRef, setState: setDistanceRate }) }} /> / {CommonUtil.getMesurementByCountry()}
                                                </div>
                                            </div>
                                        </div>








                                        <div className="col-sm-12 col-12 col-md-12">
                                            <div className="form-group row" style={{ display: (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) ? "" : "none" }}>
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE, "Engine Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input ref={engineRef} type="text" min={0} className="form-control clsStandardText clsFormInputControl avail-text-field"
                                                        disabled={isDisabled ? true : false} placeholder="" aria-label="trailer" value={engineRate}
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: engineRef, setState: setEngineRate }) }} /> / {CommonUtil.getMessageText(Constants.HOUR, "HOUR")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12 col-md-6">
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <h6 className="col-sm-12 col-12 col-md-12 " style={{ paddingLeft: "0px" }}>{CommonUtil.getMessageText(Constants.DEFAULT_PRICE, "Default Price(s)")}</h6>
                                        </div>
                                        <div className="col-sm-12 col-12 col-md-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input type="number" className="form-control clsStandardText  avail-text-field"
                                                        placeholder="" min={0} aria-label="trailer" disabled={true} value={defDailyRate} /> / {CommonUtil.getMessageText(Constants.DAY, "DAY")}
                                                </div>
                                            </div>
                                            <div className="form-group row" style={{ display: !defWeeklyRate && !weeklyRate ? "none" : "" }}>
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input type="number" className="form-control clsStandardText  avail-text-field"
                                                        placeholder="" min={0} aria-label="trailer" disabled={true} value={defWeeklyRate} /> / {CommonUtil.getMessageText(Constants.WEEK, "WEEK")}
                                                </div>
                                            </div>
                                            <div className="form-group row" style={{ display: !defMonthlyRate && !monthlyRate ? "none" : "" }}>
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.MONTHLY_RATE, "Monthly Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input type="number" className="form-control clsStandardText  avail-text-field"
                                                        placeholder="" min={0} aria-label="trailer" disabled={true} value={newMonthlyRate} /> / {CommonUtil.getMessageText(Constants.MONTH, "MONTH")}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.DIS_RATE, "Distance Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input type="number" className="form-control clsStandardText  avail-text-field"
                                                        placeholder="" min={0} aria-label="trailer" disabled={true} value={defDistanceRate} /> / {CommonUtil.getMesurementByCountry()}
                                                </div>
                                            </div>
                                            <div className="form-group row" style={{ display: (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) ? "" : "none" }}>
                                                <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE, "Engine Rate")}</label>
                                                <div className="col-sm-6 col-12 col-md-12">
                                                    <input type="number" className="form-control clsStandardText avail-text-field"
                                                        placeholder="" min={0} aria-label="trailer" disabled={true} value={defEngineRate} /> / {CommonUtil.getMessageText(Constants.HOUR, "HOUR")}
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>


                                {/* <div className="row" style={{ paddingTop: "10px" }}>

                                    <div className="col-sm-12 col-lg-6 col-md-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAILABLE_VIEW_TYPE, "Availablity View Type")}</label>
                                            <div className="col-sm-6 col-12 col-md-12">
                                                <SingleSelectComp dataSource={availableRental} 
                                                selectedValue={selectedAvailView}
                                                    handleChange={onChangeAvailType} width={"100%"} value={"PUBLIC_VIEW.key"} label={"value"}
                                                    defaultValue={CommonUtil.AVAILABLE_RENTAL[0]}
                                                    className={avlTypeClass} disabled={isDisabled ? true : false}
                                                />

                                            </div>
                                        </div>
                                    </div>



                                </div> */}



                                {
                                    closeNetworkList?.length > 0 ?
                                    <>
                                    <div className="row" >
                                        <div className="col-sm-12 col-12">
                                            <div className="form-group row">
                                                <div className="col-sm-12 col-12  flex-wrap">
                                                    <div className="form-check">                                                   
                                                        <label className="form-check-label">
                                                        <input className="form-check-input" type="radio" name="exampleRadios" onClick={(e:any)=>{
                                                            setSelectedViewType("PUBLIC_VIEW");
                                                            setSpecificCloseNetwork([])
                                                        }}  checked={selectedViewType == "PUBLIC_VIEW" ? true : false} />
                                                            Create a public availability (visible to all Renters & Carriers).
                                                        </label>
                                                    </div>
                                                    
                                                    <div className="form-check">
                                                    
                                                        <label className="form-check-label" >
                                                        <input className="form-check-input" type="radio" name="exampleRadios" onClick={(e:any)=>{
                                                            setSelectedViewType("SPECIFIC_VIEW")
                                                        }} checked={selectedViewType == "SPECIFIC_VIEW" ? true : false} />
                                                            Availability visible only to the equipment owner (Hidden from all Renters & carriers).
                                                        </label>
                                                    </div>
                                                    
                                                    <div className="form-check">
                                                    
                                                        <label className="form-check-label" >
                                                        <input className="form-check-input" type="radio" name="exampleRadios" onClick={(e:any)=>{
                                                            setSelectedViewType("PRIVATE_VIEW")
                                                            setSpecificCloseNetwork([])
                                                        }} checked={selectedViewType == "PRIVATE_VIEW" ? true : false} />
                                                            Availability visible only to the Private Network organizations.
                                                        </label>
                                                    </div>
                                                    {!CommonUtil.IS_GENERIC_EQUIP && !CommonUtil.SELECT_EQUIP_AVAIL_ID &&
                                                        <>
                                                            <div className="form-check">
                                                            
                                                                <label className="form-check-label" >
                                                                <input className="form-check-input" type="radio" name="exampleRadios" onClick={(e:any)=>{
                                                                    setSelectedViewType("ASSIGN_RENTER_VIEW")
                                                                    setSelectedcloseNetwork("");
                                                                }} checked={selectedViewType == "ASSIGN_RENTER_VIEW" ? true : false} />
                                                                    Assign the reservation to renter under private network.
                                                                </label>
                                                            </div>
                                                            {selectedViewType == "ASSIGN_RENTER_VIEW" && 
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-lg-6">
                                                                        <SingleSelectComp
                                                                        selectedValue={selectedcloseNetwork}
                                                                            dataSource={closeNetworkListNotSelected} 
                                                                            handleChange={onChangeCloseNetwork} 
                                                                            value={"orgCloseNetworkId"} label={"orgName"}
                                                                            className={avlCarrierClass} 
                                                                            // disabled={isDisabled ? true : false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    <div className="col-sm-12 col-lg-6" style={{display:selectedViewType == "SPECIFIC_VIEW" ? "" : "none"}}>
                                                        <label className="col-sm-12 col-12 col-md-12 col-form-label">{CommonUtil.getMessageText(Constants.CARRIER, "Carrier")}</label>
                                                        <div className="form-group row">
                                                            <div className="col-sm-12 col-lg-12 col-md-12 d-flex">
                                                                <div style={{width:"80%"}}>
                                                                <SingleSelectComp 
                                                            // selectedValue={selectedAvailView}
                                                                selectedValue={selectedcloseNetwork}
                                                                    dataSource={closeNetworkListNotSelected} 
                                                                    handleChange={onChangeCloseNetwork} 
                                                                    value={"renterOrgId"} label={"orgName"}
                                                                     disabled = {selectedViewType == "PUBLIC_VIEW"}
                                                                />
                                                                </div>

                                                                <div style={{width:"20%"}}>
                                                                    <button type="button" className="btn btn-primary" style={{float:"left", marginLeft:"10px", marginTop:"3px"}} onClick={(e:any)=>{
                                                                        if(selectedcloseNetwork){
                                                                            const selectedarr:any= [...specificCloseNetwork];
                                                                            selectedarr.push(selectedcloseNetwork);
                                                                            setSelectedcloseNetwork("")
                                                                            setSpecificCloseNetwork(selectedarr);
                                                                        }
                                                                    }}
                                                                    disabled = {selectedViewType == "PUBLIC_VIEW"}
                                                                    >Add</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row"  style={{display:selectedViewType == "SPECIFIC_VIEW" ? "" : "none"}}>
                                        <div className="col-sm-12 col-12">
                                            <div className="ag-theme-alpine gridHeight"
                                                style={{ height: '200px', width: '100%', paddingLeft: '8px', paddingRight: '5px' }} >
                                                <AGGrid id={`availtype${CommonUtil.EQUIPMENT_LIST ? '_a' : ''}`} cols={availviewTypeDef} hideToolbar={true}
                                                    rows={specificCloseNetwork} pageSize={500} headerHeight={30} rowHeight={40}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    </>:""
                                }
                                <div className="row" style={{ paddingTop: "10px" }}>
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-12  flex">
                                                <input ref={chkAllowRef} type="checkbox" className="checkBox" disabled={isDisabled ? true : false} />
                                                <label style={{ paddingLeft: "10px", display: "inline" }}>
                                                    {CommonUtil.getMessageText(Constants.ALLOW_RENTER, "Allow renter to make an offer for this availability")}
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ border: "1px solid #999", height: "100px", margin: "1px" }}>
                                    <div className="col-sm-12 col-12" style={{ display: "flex", alignItems: "center" }}>
                                        <div className="new-repositioning">
                                            <div className="form-group row" style={{ marginBottom: "0px" }}>
                                                <div id="repositioning" className="col-sm-1 col-2">
                                                    <i className="fa fa-tag" style={{ fontSize: "24px" }}></i>
                                                </div>
                                                <div className="col-sm-11 col-10">
                                                    <p style={{ marginBottom: "0px" }}>{CommonUtil.getMessageText(Constants.APPLY_RENTER, "These are the rates that will apply for this rental. vHub will\n" +
                                                        "                                                            charge a fee in addition to these rates. Please refer to our\n" +
                                                        "                                                            Payment Terms for full details.")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="row" id={"divConditions"}>
                                    <div className="col-sm-12 col-12">
                                        <div className="form-group row">
                                            <div className="col-sm-12 col-12 checkbox flex" style={{ paddingTop: "10px" }}>
                                                <input ref={chkTerms} type="checkbox" id="vehicle1" name="vehicle1" className="checkBox"
                                                    disabled={isTermsDisable ? true : false} onChange={(e) => { (e.currentTarget.checked ? $("#divTerms").hide() : $("#divTerms").show()) }} /> &nbsp;
                                                <label style={{ display: "inline" }}> {CommonUtil.getMessageText(Constants.TAX_EXEMPTION_AVAIL, "I have filled my tax exemptions OR I don’t have tax exemptions")}</label>

                                            </div>
                                            <div className="col-sm-12 col-12" id={"divTerms"} style={{ display: "none" }}>
                                                <label style={{ color: 'red' }}>{CommonUtil.getMessageText(Constants.TAX_EXEMPTION_ACCEPT, "You must accept the vHub Tax Exemptions")}</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 shadow-sm p-2 border border-danger" style={{ display: "none" }}>{CommonUtil.getMessageText(Constants.WILL_BE_CHARGED, "You will be charged for each excess day that the trailer is late.")}</div>
                                    </div>

                                </div>

                            </section>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-warning waves-effect" onClick={onCloseEquipAvailPopup}>{CommonUtil.getMessageText(Constants.CLOSE, "Close")}</button>
                        {(CommonUtil.EQUIP_AVAILABILITY_STATUS != CommonUtil.EQUIP_AVAILABILITY_STATUS_COMPLETED) ?
                            <div>
                                <button type="submit" className="btn btn-warning waves-effect waves-light"
                                    onClick={onClickDeleteAvailability}
                                    style={{ display: (CommonUtil.SELECT_EQUIP_AVAIL_ID == "" ? "none" : "") }}>{CommonUtil.getMessageText(Constants.DELETE, "Delete")}
                                </button>
                            </div> : ""
                        }
                        {(CommonUtil.IS_GENERIC_EQUIP && CommonUtil.EQUIP_AVAIL_STATUS != "") || (CommonUtil.EQUIP_AVAIL_STATUS == "") ? <button type="submit" className="btn btn-primary waves-effect waves-light"
                            onClick={onClickSaveAvailability}>
                            {CommonUtil.SELECT_EQUIP_AVAIL_ID ? CommonUtil.getMessageText(Constants.SAVE, "Save") : 
                            !CommonUtil.IS_GENERIC_EQUIP && selectedViewType ==="ASSIGN_RENTER_VIEW" ? CommonUtil.getMessageText(Constants.CREATE, "Create and Book") :CommonUtil.getMessageText(Constants.CREATE, "Create")   
                          }</button> : ""}


                    </div>
                </div>
            </Modal>
            <div className="container-fluid" style={{ background: "#FFF", height: "100vh" }}>
                <div className="container">
                    <section style={{ backgroundColor: "#f4f4f4" }}>
                        <div className="">
                            <div className="col-sm-12 col-12 text-right">
                                <button type="button" className="btn btn-warning waves-effect waves-light" onClick={onClickEquipPopup}>
                                    {/* <i className="fa-solid fa-plus"></i> &nbsp; */}
                                    {CommonUtil.getMessageText(Constants.ADD_NEW_AVAIL, "Add New Availability")}
                                </button></div>
                            <div className="m-3">
                                {
                                    equipAvailable.map((item: any, idx: number) => {
                                        return <AvailabilityItem item={item} onRowClick={onClickGetAvailDetails} />
                                    })

                                }

                            </div>
                        </div>
                    </section>
                </div>

            </div>
        </>
    );
}

export default EquipmentAvail;
