import React, {useEffect, useRef, useState} from 'react'
import {NetworkManager} from '../../Components/NetworkManager'
import {CommonUtil} from '../../utils/CommonUtil'
import $ from 'jquery'
import SingleSelectComp from '../../Components/SingleSelectComp'
import SingleSelect from '../../Components/SingleSelect'
import Select from 'react-select'
import {StatusCodeUtil} from '../../utils/StatusCodeUtil'
import ToastManager from '../../Components/ToastManager'
import {useNavigate} from 'react-router-dom'
import BusyLoader from '../../Components/BusyLoader'
import {loadMapApi} from '../../utils/GoogleMapsUtils'
import LoginHeader from './LoginHeader'
import profileImage from '../../images/add-profile.png'
import PhoneInput, {PhoneInputProps} from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Confirm from '../../Components/confirm'
import {Outlet} from "react-router";
import {subscribe, unsubscribe} from "./CustomEvents";
import {Constants} from "../Languages/Constants";
import {Events} from "../Languages/Events";
import SignUpHeader from "./SignUpHeader";

var defCountryObj = {Key: 'Canada', Value: 'Canada'};
const options = {
    fields: ['formatted_address', 'geometry', 'name'],
    strictBounds: false,
    types: ['establishment'],
}
const SignUpPage = () => {
    const navigate = useNavigate();
    const fileref = useRef<HTMLInputElement>(null);
    const loginRef = useRef<Select>(null);
    const statusRef = useRef<HTMLSelectElement>(null);
    const countryRef = useRef<HTMLSelectElement>(null);
    const provinceRef = useRef<HTMLSelectElement>(null);
    const depRef = useRef<HTMLSelectElement>(null);
    const oprUS = useRef<HTMLInputElement>(null);
    const chkProfile = useRef<HTMLInputElement>(null);
    const oprCanada = useRef<HTMLInputElement>(null);
    const oprBorder = useRef<HTMLInputElement>(null);
    const ananimousRef = useRef<HTMLInputElement>(null);
    const companyRef = useRef<HTMLInputElement>(null);
    const legalRef = useRef<HTMLInputElement>(null);
    const addressRef = useRef<HTMLInputElement>(null);
    const trnNoRef = useRef<HTMLInputElement>(null);
    const postalCodeRef = useRef<HTMLInputElement>(null);
    const phnoRef = useRef<PhoneInputProps>(null);
    const cityRef = useRef<HTMLInputElement>(null);
    const fnRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const contactNoRef = useRef<HTMLInputElement>(null);
    var sourceRef = useRef<HTMLInputElement>(null);

    const cmnRef = useRef<HTMLInputElement>(null)
    const cmeRef = useRef<HTMLInputElement>(null)
    const sanRef = useRef<HTMLInputElement>(null)
    const saeRef = useRef<HTMLInputElement>(null)

    const [fileNmae, setFileName] = useState('');
    const [imagePath, setImagePath] = useState<any>(null);
    const [addLogo, setAddLogo] = useState(true);
    const [companyLogo, setCompanyLogo] = useState(false);

    const [companyName, setCompanyName] = useState('');
    const [legalName, setLegalName] = useState('');
    const [address1, setAddress1] = useState('');
    const [ctq, setCTQ] = useState('');
    const [city, setCity] = useState('');
    const [localAuthority, setLocalAuthority] = useState('')
    const [mcNumber, setMcNumber] = useState('')
    const [postalcode, setPostalCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmailId] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [sourceData, setSourceData] = useState(CommonUtil.sourceData);
    const [sourceState, setSourceState] = useState(true);
    const [selectedCanOperate, setSelectedCanOperate] = useState<any>([]);
    const [loginAsObj, selLoginAsObj] = useState<any>(null);
    const [statusObj, setStatusObj] = useState<any>(null);
    const [countryObj, setCountryObj] = useState<any>(defCountryObj);
    const [stateObj, setStateObj] = useState<any>(null);
    const [depObj, setDepObj] = useState<any>(null);
    const [sourceObj, setSourceObj] = useState<any>(null);
    const [sourceText, setSourceText] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [depSave,setDepSave]= useState("")

    const [cmn,setCMN] = useState("");
    const [cme,setCME] = useState("");
    const [san,setSAN] = useState("");
    const [sae,setSAE] = useState("");

    const [loginClass, setLoginClass] = useState('mandatory');
    const [statusClass, setStatusClass] = useState('mandatory');
    const [countryClass, setCountryClass] = useState('mandatory');
    const [provinceClass, setProvinceClass] = useState('mandatory');
    const [sourceClass, setSourceClass] = useState('mandatory');
    const [depClass, setDepClass] = useState('mandatory');
    const [languageClass, setLanguageClass] = useState('mandatory');

    const [loginData, setLoginData] = useState(CommonUtil.loginAsArr);
    const [statues, setStatues] = useState(CommonUtil.statuses);
    const [countries, setCountries] = useState(CommonUtil.countryList);
    const [states, setStates] = useState<any>(CommonUtil.canadaProvinces);
    const [departments, setDepartments] = useState(CommonUtil.onlyAdminDepartment);
    const [languages, setLanguages] = useState(CommonUtil.languages);
    const [languageObj, setLanguageObj] = useState<any>(null);

    //const [languages,setLanguages] = useState(CommonUtil.languages);
    const [selLanguage, setSelLanguage] = useState(CommonUtil.SEL_LANGUAGE_TEXT);

    const [terms, setTerms] = useState('none');

    const [transText, setTransText] = useState('Transport Commission No.');
    const [transTextPlace, setTransTextPlace] = useState('CTQ, CVOR, NSC etc.');

    const [provinceText, setProvinceText] = useState('Province');

    const [postalCodeText, setPostalCodeText] = useState('Postal code');
    const [pcTextPlace, setPCTextPlace] = useState('Enter Postal Code');

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [isBorderCheckEnable, setBorderCheckEnable] = useState(true);
    const [page,setPage] = useState(0);

    useEffect(() => {
        //const [postalCodeText, setPostalCodeText] = useState('Postal code');
        setPostalCodeText(CommonUtil.getMessageText(Constants.POSTAL_CODE,"Postal code"));
        setPCTextPlace(CommonUtil.getMessageText(Constants.ENTER_POSTAL_CODE,"Enter Postal Code"));
        setProvinceText(CommonUtil.getMessageText(Constants.PROVINCE,"Province"));
        setTransText(CommonUtil.getMessageText(Constants.TRANS_COMM_NO,"Transport Commission No"));
        setTransTextPlace(CommonUtil.getMessageText(Constants.CTQ,"CTQ, CVOR, NSC etc."));

        loadGooglePlaces();
        subscribe(Events.LANGUAGE_CHANGE_EVENT,(data:any)=>{
            // console.log(CommonUtil.SELECTED_LANGUAGE);
            showLanguageMessages();
        });
        return () => {
            unsubscribe(Events.LANGUAGE_CHANGE_EVENT,{});
        }
    }, [])

    let showLanguageMessages = ()=>{
        showMessages();
    }
    let showMessages = () => {
        setPage(Math.random()*100000);
    }

    let loadGooglePlaces = () => {
        if (window.google) {
            setScriptLoaded(true);
            getGooglePlaces();
        } else {
            const googleMapScript = loadMapApi();
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    setScriptLoaded(true);
                    getGooglePlaces();
                })
            }
        }
    }
    let getGooglePlaces = () => {

        const address = document.getElementById("address") as HTMLInputElement;
        CommonUtil.getPlaceAddressByMap(address,(addr:any)=>{
            //console.log(result);
            setAddress1(addr['rawAddress']);
            setCity(addr['city']);
            setPostalCode(addr['postalcode']);
            setCountryObj({Key: addr['country'], Value: addr['country']});
            setStateObj({Key: addr['state'], Value: addr['state']});
            onCountryChange({Key: addr['country'], Value: addr['country']});
            setStateObj({Key: addr['state'], Value: addr['state']});
            setLatitude(addr['lat']);
            setLongitude(addr['lng']);

            //CommonUtil.getFormatedAddress(addr['lat'],addr['lng'],(dataObj:any)=>{console.log(dataObj)})
        });

        /*const input = document.getElementById('address') as HTMLInputElement
        const autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: {country: ['us', 'ca','in']}
        })
        autocomplete.addListener('place_changed', () => {
            let addr: any = CommonUtil.getGoogleMapAddress(autocomplete)
            //console.log(addr)
            if (addr) {
                setAddress1(addr['rawAddress']);
                setCity(addr['city']);
                setPostalCode(addr['postalcode']);
                setCountryObj({Key: addr['country'], Value: addr['country']});
                setStateObj({Key: addr['state'], Value: addr['state']});
                onCountryChange({Key: addr['country'], Value: addr['country']});
                setStateObj({Key: addr['state'], Value: addr['state']});
                setLatitude(addr['lat']);
                setLongitude(addr['lng']);

                CommonUtil.getTimeZome(addr['lat'], addr['lng'], (data: any) => {
                    //console.log(data);
                })
            }
        })*/
    }

    let onCompanyChange = (e: any) => {
        setCompanyName(e.currentTarget.value);
        CommonUtil.isMandatory(companyRef);
    }
    let onChangeLegalName = (e: any) => {
        setLegalName(e.currentTarget.value)
        CommonUtil.isMandatory(legalRef)
    }
    let onChangeAddress = (e: any) => {
        setAddress1(e.currentTarget.value);
        CommonUtil.isMandatory(addressRef);
    }
    let onChangeCTQ = (e: any) => {
        setCTQ(e.currentTarget.value);
        CommonUtil.isMandatory(trnNoRef);
    }
    const onCanOperateChange = (data: any) => {
        if(data?.length && data?.findIndex((item:any)=>item.value == 3) !=-1){
          setSelectedCanOperate([{key:"Can Operate in Canada",value:1},{key:"Can Operate in US",value:2},{key:"Cross Border",value:3}]);
        }else{
          setSelectedCanOperate(data);
        }  
    }
    let onLoginAsChange = (data: any) => {
        selLoginAsObj(data);
        (data) ? setLoginClass('select-box-valid') : setLoginClass('select-box-invalid');
    }
    let onStatusChange = (data: any) => {
        setStatusObj(data);
        (data) ? setStatusClass('select-box-valid') : setStatusClass('select-box-invalid');
    };
    let onCountryChange = (data: any) => {
        setCountryObj(data);
        setStateObj(null);
        if (data) {
            if (data.Key == CommonUtil.CANADA) {
                setStates(CommonUtil.canadaProvinces);
            } else {
                setStates(CommonUtil.usStates);
            }
        } else {
            setCountryObj(defCountryObj);
            setStates(CommonUtil.canadaProvinces);
        }
        if (data === null) {
            data = defCountryObj;
        }
        (data) ? setCountryClass('select-box-valid') : setCountryClass('select-box-invalid');
        if (data) {
            let strTransText = CommonUtil.getMessageText(Constants.TRANS_COMM_NO,"Transport Commission No");//'Transport Commission No';
            let strTransTextPlace = CommonUtil.getMessageText(Constants.CTQ,"CTQ, CVOR, NSC etc.");//'CTQ, CVOR, NSC etc.';

            let strProvinceText = CommonUtil.getMessageText(Constants.PROVINCE,"Province");//'Province';

            let strPostalCodeText = CommonUtil.getMessageText(Constants.POSTAL_CODE,"Postal code");//'Postal code';
            let strPostalCodePlace = CommonUtil.getMessageText(Constants.ENTER_POSTAL_CODE,"Enter Postal Code");//'Enter Postal Code';

            if (data.Key == 'United States') {
                strTransText = CommonUtil.getMessageText(Constants.US_DOT_NO,"USDOT number");//'USDOT number';
                strTransTextPlace = CommonUtil.getMessageText(Constants.ENTER_US_DOT_NO,"You must enter an USDOT number");//'You must enter an USDOT number';

                strProvinceText = CommonUtil.getMessageText(Constants.STATE,"State");//'State';

                strPostalCodeText = CommonUtil.getMessageText(Constants.ZIP_CODE,"ZIP code");//'ZIP code';
                strPostalCodePlace = CommonUtil.getMessageText(Constants.ENTER_ZIP_CODE,"Enter ZIP Code");//'Enter ZIP Code';
            }

            setTransText(strTransText);
            setTransTextPlace(strTransTextPlace);
            setProvinceText(strProvinceText);
            setPostalCodeText(strPostalCodeText);
            setPCTextPlace(strPostalCodePlace);
        }
    }
    let onStateChange = (data: object) => {
        setStateObj(data);
        (data) ? setProvinceClass('select-box-valid') : setProvinceClass('select-box-invalid');
    }

    let onSourceChange = (data: any) => {
        setSourceObj(data);
        setSourceState(true);
        setSourceText('');
        if (data && data.Key == 'Other') {
            setSourceState(false);
        }
        (data) ? setSourceClass('select-box-valid') : setSourceClass('select-box-invalid');
    }
    let onDepChange = (data: any) => {
        setDepObj(data);
        (data) ? setDepClass('select-box-valid') : setDepClass('select-box-invalid');
    }
    let onClickDiscardProfile = () => {
        let msg: string = CommonUtil.getMessageText(Constants.DISCARD_THE_PROFILE,'Discarding the profile, will clear the data and navigate to login. Do you want continue?');
        Confirm.show('Confirm', msg, [{
            label: 'Yes', callback: function () {
                navigate('/')
            },
        }, {label: 'No'}])
    }


    let onClickCreateProfile = () => {

        const selectOperate = document.querySelector("#selectOperate");
        // if(!oprCanada.current?.checked && !oprUS.current?.checked){
        //     if(selectOperate) selectOperate.classList.add('d-block');
        //     return
        // }else{
        //     if(selectOperate) selectOperate.classList.remove('d-block');
        // }
        if(selectedCanOperate?.length == 0){
            ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"), "Error");
            return;
        }

        var isOrg: boolean = CommonUtil.isMandatory(companyRef);
        //var isLogin:boolean = CommonUtil.isMandatory(legalRef);
        var isLegal: boolean = CommonUtil.isMandatory(legalRef);
        var isAddress: boolean = CommonUtil.isMandatory(addressRef);
        var isCTQ: boolean = CommonUtil.isMandatory(trnNoRef);
        var isCity: boolean = CommonUtil.isMandatory(cityRef);
        var isPostalCode: boolean = CommonUtil.isMandatory(postalCodeRef);
        // var isPhone:boolean =  CommonUtil.isMandatory(phnoRef);
        var isFN: boolean = CommonUtil.isMandatory(fnRef);
        var isLN: boolean = CommonUtil.isMandatory(lnRef);
        var isEmail: boolean = CommonUtil.isEmailMandatory(emailRef);
        //var isContact:boolean =  CommonUtil.isMandatory(contactNoRef);

        (loginAsObj) ? setLoginClass('select-box-valid') : setLoginClass('select-box-invalid');
        (depObj) ? setDepClass('select-box-valid') : setDepClass('select-box-invalid');
        sourceObj ? setSourceClass('select-box-valid') : setSourceClass('select-box-invalid');
        countryObj ? setCountryClass('select-box-valid') : setCountryClass('select-box-invalid');
        stateObj ? setProvinceClass('select-box-valid') : setProvinceClass('select-box-invalid');
        languageObj ? setLanguageClass('select-box-valid') : setLanguageClass('select-box-invalid');
        

        //(statusObj)?setStatusClass("select-box-valid"): setStatusClass("select-box-invalid");

        chkProfile.current?.checked ? setTerms('none') : setTerms('block')
        if(phoneNumber && phoneNumber?.length != 11){
            ToastManager.showToast("Please enter valid Phone Number", "Error");
            return;
        }
        if(contactNumber && contactNumber?.length !=11){
            ToastManager.showToast("Please enter valid Contact Number", "Error");
            return;
        }
// && localAuthority && mcNumber
        if (chkProfile.current?.checked && languageObj && stateObj && countryObj  && depObj && loginAsObj && isEmail && isLN && isOrg && isLegal && isAddress && isCTQ && isCity && isPostalCode && isFN && contactNumber && phoneNumber) {
            var obj: any = {}
            obj.address1 = address1;
            //obj.anonymousListingAllowed = (ananimousRef.current?.checked)?CommonUtil.CHECKED:CommonUtil.UN_CHECKED;
            obj.canOperateInCanada =  CommonUtil.UN_CHECKED;
            obj.canOperateInCrossBorder = CommonUtil.UN_CHECKED;
            obj.canOperateInUSA = CommonUtil.UN_CHECKED;
            selectedCanOperate?.map((item:any)=>{
                if(item.value == 1){
                    obj.canOperateInCanada = CommonUtil.CHECKED;
                }else if(item.value == 2){
                    obj.canOperateInUSA = CommonUtil.CHECKED;
                }else if(item.value == 3){
                    obj.canOperateInCanada = CommonUtil.CHECKED;
                    obj.canOperateInUSA = CommonUtil.CHECKED;
                    obj.canOperateInCrossBorder = CommonUtil.CHECKED;
                }
              })      
            obj.city = city
            obj.contactNumber = contactNumber
            obj.country = countryObj ? countryObj.Key : '' // countryRef.current?.value;
            obj.department = depObj ? depObj.Key : '' // depRef.current?.value;
            obj.email = email
            obj.firstName = firstName
            obj.lastName = lastName
            obj.legalName = legalName

            obj.mcNumber = mcNumber;
            obj.localAuthority = localAuthority;

            if (fileNmae) {
                obj.logoPath = 'images/organizations/' + fileNmae
            } else {
                obj.logoPath = ''
            }
            let valDBA = companyName;
            if(String(valDBA).toLowerCase() === "n/a"){
              valDBA = legalName;
            }
            obj.organizationName = valDBA;
            obj.organizationType = loginAsObj ? loginAsObj.Key : '' // loginRef.current?.value;
            obj.phoneNumber = phoneNumber
            obj.postalCode = postalcode
            obj.province = stateObj ? stateObj.Key : '' //provinceRef.current?.value;
            // obj.province =  stateObj?stateObj.Key:"";//statusRef.current?.value;
            obj.transportCommissionNumber = ctq
            if (sourceObj) {
                obj.source = sourceObj.Value
                if (sourceObj.Key == 'Other') {
                    obj.source = 'Other-' + sourceText
                }
            }
            obj.language = languageObj.Key
            obj.latitude = latitude
            obj.longitude = longitude
            obj.rawAddress = address1
            obj.timeZone = ''

            obj.customerManagerName = cmn;
            obj.customerManagerEmail = cme;      
            obj.salesAgentName = san;      
            obj.salesAgentEmail = sae;

            BusyLoader.showLoader()
            NetworkManager.sendJsonRequest('api/session/registerOrganization', obj, '', 'POST', false, onOrgCreateSuccess, onLoginError);
        }else{
            ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"), "Error");
        }
    }

    let onOrgCreateSuccess = (data: any) => {
        if (data) {
            BusyLoader.hideLoader();
            var restData = JSON.parse(data);
            if(restData){
                if(restData.statusCode){
                    ToastManager.showToast(restData.errorMessage, 'Error')
                }else{
                   // return restData;
                }
            }
        } else {
            if (fileNmae) {
                if (fileref.current && fileref.current.files) {
                    var qParam: string = 'objectPath=images/organizations/' + fileref.current.files[0].name
                    NetworkManager.sendJsonRequest('api/session/uploadUrl', '', qParam, 'GET', false, onUploadSuccess, onLoginError);
                }
            } else {
                BusyLoader.hideLoader()
                gotoLoginPage()
            }
        }
    }
    let gotoLoginPage = () => {
        navigate('/regsignup')
    }
    let onUploadSuccess = (dataObj: any) => {
        if (dataObj) {
            if (dataObj.statusCode) {
                BusyLoader.hideLoader()
                var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode)
                ToastManager.showToast(errMsg, 'Error')
            } else {
                var strUploadPath = dataObj
                if (fileref.current && fileref.current.files) {
                    const formData = new FormData()
                    var myHeaders = new Headers()
                    var file = fileref.current.files[0]
                    var requestOptions: any = {
                        method: 'PUT',
                        headers: myHeaders,
                        body: file,
                        redirect: 'follow',
                    }
                    fetch(strUploadPath, requestOptions)
                        .then((response) => response.text())
                        .then((result) => {
                            BusyLoader.hideLoader()
                            gotoLoginPage()
                        })
                        .catch((error) => {
                            BusyLoader.hideLoader()
                            console.log('error', error)
                        })
                }
            }
        }
    }

    let onLoginError = (err: any) => {
        BusyLoader.hideLoader();
        console.log(err)
    }
    let onClickBrowse = () => {
        //inputRef.current?.classList.remove("is-invalid");
        if (fileref.current) {
            fileref.current.value = ''
            // fileref.current.onclick();
            $('#inputGroupFile').click()
        }
        setFileName('')
    }
    let onSelectFiles = (e: any) => {
        //console.log(e);
        if (e && e.target && e.target.files[0]) {
            let fileName = e.target.files[0].name
            var oFReader = new FileReader()
            oFReader.readAsDataURL(e.target.files[0])
            oFReader.onload = function (oFREvent) {
                // console.log(oFREvent.target?.result);
                setAddLogo(false)
                setCompanyLogo(true)
                setImagePath(oFREvent.target?.result)
                setFileName(fileName)
            }
        }
    }
    let onLanguageChange = (data: object) => {
        setLanguageObj(data)
        data
            ? setLanguageClass('select-box-valid')
            : setLanguageClass('select-box-invalid')
    }
    let onClickLanOption = (item: any) => {
        //console.log(item);
        CommonUtil.SELECTED_LANGUAGE = item.Key
        if (item.Key == 'en') {
            setSelLanguage(CommonUtil.ENG_LANGUAGE_TEXT)
        } else if (item.Key == 'fr') {
            setSelLanguage(CommonUtil.FR_LANGUAGE_TEXT)
        } else if (item.Key == 'es') {
            setSelLanguage(CommonUtil.SPANISH_LANGUAGE_TEXT)
        }
    }
    let onChangeOprCanada = () => {
        onChangeOprBorder()
    }
    let onChangeOprUS = () => {
        onChangeOprBorder()
    }
    let onChangeOprBorder = () => {
        if (oprBorder.current) {
            oprBorder.current.checked = false
        }
        setBorderCheckEnable(true)
        if (oprCanada.current?.checked && oprUS.current?.checked) {
            setBorderCheckEnable(false)
        }
    }
    return (
        <div style={{overflow:"hidden"}}>
            <input type="file" ref={fileref} className="custom-file-input " id="inputGroupFile"
                   accept="image/*" style={{display: 'none'}} onChange={(e) => {
                onSelectFiles(e)
            }}></input>
            <div className="row">
                <div className="row">
                    <SignUpHeader />
                </div>

                <div className={"row"}>
                    <div className="page-content-wrapper page-margin-top">
                        <div className="container" style={{width: "80%"}}>
                            <section className="mt-3">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 text-center">
                                        <div className="picture-container">
                                            <div className="picture">
                                                {addLogo && (
                                                    <img
                                                        className="clsCompanyLogo companyLogo"
                                                        src={'../../AddLogo.png'}
                                                        style={{border: '0px'}}
                                                        onClick={onClickBrowse}
                                                    />
                                                )}
                                                {companyLogo && (
                                                    <img
                                                        className="clsCompanyLogo companyLogo"
                                                        src={imagePath}
                                                        onClick={onClickBrowse}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-9 col-md-8 text-center">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group row text-left">
                                                    <label className="col-sm-12 col-form-label text-left"
                                                           style={{textAlign: "left"}}>
                                                        <a data-toggle="modal" data-target=".bs-example-modal-center-register">
                                                            {CommonUtil.getMessageText(Constants.COMPANY_NAME_DBA,"Company Name (DBA)")}
                                                        </a>
                                                        <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input ref={companyRef} type="text" maxLength={60}
                                                               className="form-control clsStandardText clsBoldText  clsFormInputControl"
                                                               placeholder={CommonUtil.getMessageText(Constants.COMPANY_NAME_DBA,"Company Name (DBA)")} value={companyName}
                                                               onChange={(e) => {
                                                                   onCompanyChange(e)
                                                               }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group row text-left" style={{textAlign: "left"}}>
                                                    <label className="col-sm-12 col-form-label text-left">
                                                        {CommonUtil.getMessageText(Constants.LEGAL_NAME,"Legal Name")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input
                                                            ref={legalRef}
                                                            type="text" maxLength={125}
                                                            className="form-control clsStandardText  clsFormInputControl"
                                                            placeholder={CommonUtil.getMessageText(Constants.ENTER_LEGAL_NAME,"You must enter a legal name")}
                                                            value={legalName}
                                                            aria-label="Legal Name"
                                                            onChange={(e) => {
                                                                onChangeLegalName(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <hr/>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="row">
                                            
                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.ADDRESS1,"Address 1")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input
                                                            type="text"
                                                            id={'address'}
                                                            ref={addressRef}
                                                            className="form-control clsFormInputControl"
                                                            placeholder= {CommonUtil.getMessageText(Constants.ENTER_ADDRESS1,"Enter Address")}
                                                            aria-label="Address1"
                                                            value={address1}
                                                            onChange={(e) => {
                                                                onChangeAddress(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.COUNTRY,"Country")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <SingleSelectComp
                                                            ref={countryRef}
                                                            dataSource={countries}
                                                            selectedValue={countryObj}
                                                            handleChange={onCountryChange}
                                                            value={'Key'}
                                                            label={'Value'}
                                                            width={'100%'}
                                                            className={countryClass}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.CITY,"City")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input maxLength={60}
                                                               ref={cityRef}
                                                               type="text"
                                                               className="form-control clsFormInputControl"
                                                               placeholder={CommonUtil.getMessageText(Constants.ENTER_CITY,"Enter City")}
                                                               aria-label="City"
                                                               value={city}
                                                               onChange={(e) => {
                                                                   setCity(e.currentTarget.value)
                                                                   CommonUtil.isMandatory(cityRef)
                                                               }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {provinceText} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <SingleSelectComp
                                                            ref={provinceRef}
                                                            dataSource={states}
                                                            selectedValue={stateObj}
                                                            handleChange={onStateChange}
                                                            value={'Key'}
                                                            label={'Value'}
                                                            width={'100%'}
                                                            className={provinceClass}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {postalCodeText} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input maxLength={8}
                                                               ref={postalCodeRef}
                                                               type="text"
                                                               className="form-control clsFormInputControl"
                                                               placeholder={postalCodeText}
                                                               aria-label="Postal Code"
                                                               value={postalcode}
                                                               onChange={(e) => {
                                                                   setPostalCode(e.currentTarget.value)
                                                                   CommonUtil.isMandatory(postalCodeRef)
                                                               }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.PHONE_NO,"Phone Number")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <PhoneInput
                                                            country={'us'}
                                                            countryCodeEditable={false}
                                                            value={phoneNumber}
                                                            placeholder={CommonUtil.getMessageText(Constants.ENTER_PHONE_NO,"Enter Phone Number")}
                                                            inputClass={'input-phone'}
                                                            onChange={(e) => {
                                                                setPhoneNumber(e);
                                                                //console.log(e);
                                                            }}
                                                            containerStyle={{
                                                                width: '100%',
                                                            }}
                                                            disableCountryGuess={true}
                                                            disableDropdown={true}
                                                            disableSearchIcon={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>

                                                {/* 3938 */}
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">
                                                            {transText} <span className="text-danger"></span>
                                                        </label>
                                                        <div className="col-sm-12">
                                                            <input maxLength={30}
                                                                ref={trnNoRef}
                                                                type="text"
                                                                className="form-control clsStandardText  clsFormInputControl "
                                                                placeholder={transTextPlace}
                                                                value={ctq}
                                                                onChange={(e) => {
                                                                    onChangeCTQ(e)
                                                                }}
                                                                aria-label="Transport Commission No. (CTQ,CVOR etc.)"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">MC Number</label>
                                                        <div className="col-sm-12">
                                                            <input                                  
                                                                type="text"
                                                                className={"form-control clsStandardText  "}
                                                                placeholder={"MC Number"}
                                                                value={mcNumber}
                                                                onChange={(e) => {
                                                                setMcNumber(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">Local Authority</label>
                                                        <div className="col-sm-12">
                                                            <input                                  
                                                                type="text"
                                                                className={"form-control  clsStandardText  "}
                                                                placeholder={"Local Authority"}
                                                                value={localAuthority}
                                                                onChange={(e) => {
                                                                    setLocalAuthority(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-4 col-12">
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">
                                                            {CommonUtil.getMessageText(Constants.JOIN_VHUB_AS,"Join vHub as A")} <span className="text-danger"></span>
                                                        </label>
                                                        <div className="col-sm-12">
                                                            <SingleSelectComp
                                                                ref={loginRef}
                                                                dataSource={loginData}
                                                                selectedValue={loginAsObj}
                                                                handleChange={onLoginAsChange}
                                                                value={'Key'}
                                                                label={'Value'}
                                                                width={'100%'}
                                                                className={loginClass}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">{"Can Operate in"}</label>
                                                    <div className="col-sm-12">
                                                        <SingleSelectComp dataSource={[{key:"Can Operate in Canada",value:1},{key:"Can Operate in US",value:2},{key:"Cross Border",value:3}]}
                                                            handleChange={onCanOperateChange} 
                                                            selectedValue={selectedCanOperate} 
                                                            multiple={true}
                                                            value={'value'} label={'key'} className={statusClass}
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.SOURCE,"Source")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <SingleSelectComp
                                                            dataSource={sourceData}
                                                            selectedValue={sourceObj}
                                                            handleChange={onSourceChange}
                                                            value={'Key'}
                                                            label={'Value'}
                                                            width={'100%'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.SOURCE_OTHERS,"Source (Others)")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input maxLength={120}
                                                               ref={sourceRef}
                                                               type="text"
                                                               className="form-control  "
                                                               placeholder={CommonUtil.getMessageText(Constants.ENTER_SOURCE,"Enter Source")}
                                                               aria-label="Phone Number"
                                                               value={sourceText}
                                                               style={{borderBottomColor: '#e7e1e1'}}
                                                               onChange={(e) => {
                                                                   setSourceText(e.currentTarget.value)
                                                               }}
                                                               disabled={sourceState}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12"></div>
                                            <div className='row'>
                          
                          {/* V20-320  1612*/}
                          {/* <div className='row'>
                            <div className="col-md-4 col-12">
                              <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CUSTOMER_MANAGER_NAME,"Customer Manager Name")}</label>
                                <div className="col-sm-12">
                                  <input
                                      ref={cmnRef}
                                      type="text"
                                      className="form-control "
                                      placeholder="Enter Manager Name"
                                      aria-label="cmn"
                                      value={cmn}
                                      onChange={(e) => {
                                        setCMN(e.currentTarget.value)
                                        // CommonUtil.isMandatory(cityRef)
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CUSTOMER_MANAGER_EMAIL,"Customer Manager Email")}</label>
                                <div className="col-sm-12">
                                  <input
                                      ref={cmeRef}
                                      type="text"
                                      className="form-control "
                                      placeholder="Enter Manager Email"
                                      aria-label="Manager Email"
                                      value={cme}
                                      onChange={(e) => {
                                        setCME(e.currentTarget.value)
                                        // CommonUtil.isMandatory(cityRef)
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className="col-md-4 col-12">
                              <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.SALES_AGENT_NAME,"Sales Agent Name")}</label>
                                <div className="col-sm-12">
                                  <input
                                      ref={sanRef}
                                      type="text"
                                      className="form-control "
                                      placeholder="Enter Sales Agent Name"
                                      aria-label="SAN"
                                      value={san}
                                      onChange={(e) => {
                                        setSAN(e.currentTarget.value)
                                        // CommonUtil.isMandatory(cityRef)
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.SALES_AGENT_EMAIL,"Sales Agent Email")}</label>
                                <div className="col-sm-12">
                                  <input
                                      ref={saeRef}
                                      type="text"
                                      className="form-control "
                                      placeholder="Enter Sales Agent Email"
                                      aria-label="sae"
                                      value={sae}
                                      onChange={(e) => {
                                        setSAE(e.currentTarget.value)
                                        // CommonUtil.isMandatory(cityRef)
                                      }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr/> */}
                        </div>
                                            <div className='row' style={{display:"none"}}>
                                                <hr/>
                                                <div className="col-md-4 col-12 pt-2">
                                                    <div className="flex mr-4">
                                                        <input ref={oprCanada}  type="checkbox"
                                                            className="checkBox" id="chkOperateCanada" name="OperateCanada"
                                                            value="OperateCanada" onChange={onChangeOprCanada}
                                                        />&nbsp;
                                                        <label>{CommonUtil.getMessageText(Constants.OPR_CANADA,"Can Operate in Canada")}</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12 pt-2">
                                                    <div className="flex mr-4">
                                                        <input ref={oprUS} type="checkbox"
                                                            className="checkBox"
                                                            id="chkOperateUS" name="OperateUS" value="OperateUS"
                                                            onChange={onChangeOprUS}/>&nbsp;
                                                        <label>{CommonUtil.getMessageText(Constants.OPR_US,"Can Operate in US")}</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 col-12 pt-2">
                                                    <div className="flex mr-4">
                                                        <input ref={oprBorder} type="checkbox"
                                                            className="checkBox" id="chkOperateCrossBorder"
                                                            name="OperateCrossBorder" value="OperateCrossBorder"
                                                            disabled={isBorderCheckEnable}/> &nbsp;
                                                        <label>{CommonUtil.getMessageText(Constants.CROSS_BORDER,"Cross Border")}</label>
                                                    </div>
                                                </div>
                                                <label style={{color: 'red', display:'none'}} id="selectOperate" className='col-12 text-center m-0' >
                                                    You must select any checkbox above.
                                                </label>

                                                <div className="col-md-12 col-12 pt-3" style={{display: "none"}}>
                                                    <div className="custom-control custom-checkbox">
                                                        <input ref={ananimousRef} type="checkbox" style={{zIndex: 999}}
                                                            className="checkBox" id="chkOperateCanada" name="OperateCanada"
                                                            value="OperateCanada"
                                                        /> &nbsp;
                                                        <label className="custom-control-label pt-1">
                                                            Anonymous Listing (Do not show organization information
                                                            until eservation is created)
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <h6>Create Your Account</h6>
                                        <div className="row">
                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.FIRST_NAME,"First Name")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input ref={fnRef} type="text" className="form-control clsFormInputControl"
                                                               placeholder={CommonUtil.getMessageText(Constants.ENTER_FIRST_NAME,"Enter First Name")} aria-label="First Name"
                                                               value={firstName} maxLength={60}
                                                               onChange={(e) => {
                                                                   setFirstName(e.currentTarget.value)
                                                                   CommonUtil.isMandatory(fnRef)
                                                               }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.LAST_NAME,"Last Name")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input ref={lnRef} type="text" className="form-control clsFormInputControl "
                                                               placeholder={CommonUtil.getMessageText(Constants.ENTER_LAST_NAME,"Enter Last Name")} aria-label="Last Name"
                                                               value={lastName} maxLength={32}
                                                               onChange={(e) => {
                                                                   setLastName(e.currentTarget.value)
                                                                   CommonUtil.isMandatory(lnRef)
                                                               }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.EMAIL_ID,"Email ID")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <input ref={emailRef} type="text" className="form-control clsFormInputControl "
                                                               placeholder={CommonUtil.getMessageText(Constants.PLACE_ENTER_EMAIL,"Enter Email")} maxLength={60}
                                                               aria-label="Email ID" value={email}
                                                               onChange={(e) => {
                                                                   setEmailId(e.currentTarget.value)
                                                                   CommonUtil.isEmailMandatory(emailRef)
                                                               }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.CONTACT_NO,"Contact Number")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <PhoneInput country={'us'} countryCodeEditable={false}
                                                                    value={contactNumber} placeholder={CommonUtil.getMessageText(Constants.CONTACT_NO,"Contact Number")}
                                                                    inputClass={'input-phone'} disableCountryGuess={true} disableDropdown={true} disableSearchIcon={true}
                                                                    onChange={(e) => {
                                                                        setContactNumber(e)
                                                                    }}

                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.DEPARTMENT,"Department")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">

                                                        {/* <SingleSelectComp
                                                            ref={depRef}
                                                            dataSource={departments}
                                                            selectedValue={depObj}
                                                            handleChange={onDepChange}
                                                            value={'Key'}
                                                            // onChange={(e) => {
                                                            //     setDepSave(e)
                                                            // }}
                                                            // value={depSave}
                                                            label={'Value'}
                                                            width={'100%'}
                                                            className={depClass}
                                                        /> */}
                                                        <input  type="text" className="form-control clsFormInputControl "
                                                               placeholder={CommonUtil.getMessageText(Constants.DEPARTMENT,"Department")}
                                                               aria-label="Department" 
                                                               // ref={depRef}
                                                          //  dataSource={departments}
                                                           // selectedValue={depObj}
                                                           // handleChange={onDepChange}
                                                            value={depObj}
                                                          //  label={'Value'}
                                                            width={'100%'}
                                                            onChange={(e) => {
                                                                setDepObj(e.target.value)
                                                                 }}
                                                               
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12">
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">
                                                        {CommonUtil.getMessageText(Constants.LANGUAGE,"Language")} <span className="text-danger"></span>
                                                    </label>
                                                    <div className="col-sm-12">
                                                        <SingleSelectComp
                                                            dataSource={languages}
                                                            selectedValue={languageObj}
                                                            handleChange={onLanguageChange}
                                                            value={'Key'}
                                                            label={'Value'}
                                                            width={'100%'}
                                                            className={languageClass}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-12 pt-3">
                                                <div className="flex">
                                                    <input
                                                        ref={chkProfile}

                                                        type="checkbox"
                                                        className="checkBox"
                                                        id="chkProfile"
                                                        name="profile"
                                                        value="profile"
                                                        onChange={(e) => {
                                                            e.currentTarget.checked
                                                                ? setTerms('none')
                                                                : setTerms('none')
                                                        }}
                                                    /> &nbsp;
                                                    <label className="">
                                                        {CommonUtil.getMessageText(Constants.BY_CLICK,"By clicking")}  "{CommonUtil.getMessageText(Constants.CREATE_PROFILE,"Create Profile")}" , {CommonUtil.getMessageText(Constants.I_ACCEPT_THE,"I accept the")}{' '}
                                                        <a href="https://www.vhubapp.com/terms-conditions-home"
                                                           target={"_blank"}>
                                                            <u>{CommonUtil.getMessageText(Constants.TERMS_CONDITIONS,"Terms & Conditions")}</u>
                                                        </a>{' '}
                                                        and{' '}
                                                        <a href="https://www.vhubapp.com/privacy-policy" target={"_blank"}>
                                                            <u>{CommonUtil.getMessageText(Constants.PRIVACY_POLICY,"Privacy Policy")}</u>
                                                        </a>{' '}
                                                        {CommonUtil.getMessageText(Constants.OF_VHUB,"of vHub")}
                                                    </label>
                                                </div>
                                                <div className="col-md-8 flex ml-2" style={{display: terms == 'none' ? 'none' : 'block'}}>
                                                    <label style={{color: 'red'}}>
                                                        {CommonUtil.getMessageText(Constants.ACCEPT_TERMS_CONDITIONS,"You must accept the vHub Terms of Service")}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-12 text-right mt-3">
                                                <button type="button" className="btn btn-warning mr-2" data-dismiss="modal" onClick={onClickDiscardProfile}>
                                                    {CommonUtil.getMessageText(Constants.DISCARD_PROFILE,"Discard Profile")}
                                                </button>
                                                &nbsp;&nbsp;
                                                <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={onClickCreateProfile}>
                                                    {CommonUtil.getMessageText(Constants.CREATE_PROFILE,"Create Profile")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>





        </div>
    )
}

export default SignUpPage
