import React, { useEffect, useState } from 'react'
import { CommonUtil } from '../../utils/CommonUtil';
import { Constants } from "../Languages/Constants";

const ProfileCompletion = ({completedStatus,onSubmitProfile}:any) => {
    const [showSubmit, setShowSubmit] = useState({});
    useEffect(()=>{
        let str = "";
        switch(CommonUtil.SELECT_ORG_TYPE){
            case CommonUtil.ORG_TYPE_OWNER : 
                str = completedStatus["isCompleted"] ? "none" :
                    completedStatus["customerDetails"] &&
                    completedStatus["financeInformation"] &&
                    completedStatus["insuranceDetails"] &&
                    completedStatus["bankDetails"] 
                    ? ""
                    : "none";
            break;
            case CommonUtil.ORG_TYPE_RENTER : 
                str = completedStatus["isCompleted"] ? "none" :
                    completedStatus["customerDetails"] &&
                    completedStatus["financeInformation"] &&
                    completedStatus["insuranceDetails"] &&
                    completedStatus["creditCardDetails"]
                    ? ""
                    : "none";
            break;
            case CommonUtil.ORG_TYPE_OWNER_RENTER : 
                str = completedStatus["isCompleted"] ? "none" :
                    completedStatus["customerDetails"] &&
                    completedStatus["financeInformation"] &&
                    completedStatus["insuranceDetails"] &&
                    completedStatus["bankDetails"] &&
                    completedStatus["creditCardDetails"]
                    ? ""
                    : "none";
            break;
        }
        
        setShowSubmit({
            display: str,
        });        
            console.log(str);
            
    },[completedStatus]);
  return (
    <div className="col-md-12 col-12" >
    <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingLeft: "8px",
            paddingRight: "5px",
        }}>
            <label className="ms-4">
                <a href="https://www.vhubapp.com/terms-conditions-home"
                    target={"_blank"}>
                    <u>{CommonUtil.getMessageText(Constants.TERMS_CONDITIONS,"Terms & Conditions")}</u>
                </a>{' '}
                and{' '}
                <a href="https://www.vhubapp.com/privacy-policy" target={"_blank"}>
                    <u>{CommonUtil.getMessageText(Constants.PRIVACY_POLICY,"Privacy Policy")}</u>
                </a>
            </label>
        <div 
        style={showSubmit}
        >
            <div className="col-md-12 col-12 pb-5 me-4">
                  <button type="submit" className="btn btn-danger" onClick={onSubmitProfile}>Submit</button>
            </div>
        </div>
        </div>
    </div>
  )
}

export default ProfileCompletion