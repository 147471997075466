import React, { useEffect, useRef, useState } from "react";
import {CommonUtil, getBambooraCode, setSectionCompleted} from "../../utils/CommonUtil";
import BusyLoader from "../../Components/BusyLoader";
import { NetworkManager } from "../../Components/NetworkManager";
import $, { data } from "jquery";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import ToastManager from "../../Components/ToastManager";
import SingleSelectComp from "../../Components/SingleSelectComp";
import CreditCardDetails from "./CreditCardDetails";
import Flatpickr from "react-flatpickr";
import Confirm from "../../Components/confirm";
import { loadMapApi } from "../../utils/GoogleMapsUtils";
import { DragNDrop } from "../../utils/DragDrop";
import { Constants } from "../Languages/Constants";
import {Events} from "../Languages/Events";
import AddOpenAccount from "./AddOpenAccount";
import RibbonTag from "./RibbonTag";
//import PreAuth from "../../images/pre-authorized payment form_vHub ANG[2914].pdf";

var defBusCenter = { Key: 'Canada', Value: 'Canada' };
var defCountryObj = { Key: '', Value: '' };
var defCurrencyObj = { Key: '', Value: '' };
var defTypeObj = { key: 'SWIFT', value: 'SWIFT' };


var fileType: string = "";
var flag: boolean = false;

var dateformat: object = {};
var selFileType: string = "";

var ccId: string = "";
var bankAccId1: string = "";
var bankAccId2: string = "";

var fullPath: string = "";

var strInfo = "";
var strError = "";
const FinanceInfo = ({completedStatus={}, updateProfile}:any) => {

    const abaRouteNoRef = useRef<HTMLInputElement>(null);
    const [abaRouteNo, setABARouteNo] = useState("");

    const abaRouteNoRef1 = useRef<HTMLInputElement>(null);
    const [abaRouteNo1, setABARouteNo1] = useState("");

    const bankTransRef = useRef<HTMLInputElement>(null);
    const [bankTransit, setBankTransit] = useState("");

    const bankTransRef1 = useRef<HTMLInputElement>(null);
    const [bankTransit1, setBankTransit1] = useState("");

    const bankRecRef = useRef<HTMLInputElement>(null);
    const [bankReceiptName, setBankReceiptName] = useState("");

    const bankRecRef1 = useRef<HTMLInputElement>(null);
    const [bankReceiptName1, setBankReceiptName1] = useState("");

    const bankAccRef = useRef<HTMLInputElement>(null);
    const [bankAccNo, setBankAccNo] = useState("");

    const bankAccRef1 = useRef<HTMLInputElement>(null);
    const [bankAccNo1, setBankAccNo1] = useState("");

    const finInsAddrRef = useRef<HTMLInputElement>(null);
    const [finAddInstAddress, setFinAddInstAddress] = useState("");
    const [finAddInstRawAddress, setFinAddInstRawAddress] = useState("");

    const [city, setCity] = useState("");
    const [postalcode, setPostalCode] = useState("");

    const [city1, setCity1] = useState("");
    const [postalcode1, setPostalCode1] = useState("");

    const postalCodeRef = useRef(null);
    const postalCodeRef1 = useRef(null);
    const cityRef = useRef(null);
    const cityRef1 = useRef(null);

    const finInsAddrRef1 = useRef<HTMLInputElement>(null);
    const [finAddInstRawAddress1, setFinAddInstRawAddress1] = useState("");
    const [finAddInstAddress1, setFinAddInstAddress1] = useState("");

    const finInsNumRef = useRef<HTMLInputElement>(null);
    const [finAddInstNumber, setFinAddInstNumber] = useState("");

    const finInsNumRef1 = useRef<HTMLInputElement>(null);
    const [finAddInstNumber1, setFinAddInstNumber1] = useState("");

    const finInsNameRef = useRef<HTMLInputElement>(null);
    const [finInstName, setFinInstName] = useState("");

    const finInsNameRef1 = useRef<HTMLInputElement>(null);
    const [finInstName1, setFinInstName1] = useState("");

    const gstRef = useRef<HTMLInputElement>(null);
    const [gstNumber, setGSTNumber] = useState("");

    const qstRef = useRef<HTMLInputElement>(null);
    const [qstNumber, setQSTNumber] = useState("");

    const bntRef = useRef<HTMLInputElement>(null);
    const [bnNumber, setBNNumber] = useState("");

    const w8tRef = useRef<HTMLInputElement>(null);
    const [w8Number, setW8Number] = useState("");

    const w9tRef = useRef<HTMLInputElement>(null);
    const [w9Number, setW9Number] = useState("");

    const fileref = useRef<HTMLInputElement>(null);
    const [fileNmae, setFileName] = useState("");

    const [busCenters, setBusCenters] = useState(CommonUtil.bankCountryList);
    const [busCenterObj, setBusCenterObj] = useState(defBusCenter);
    const [busCenterObj1, setBusCenterObj1] = useState(defBusCenter);

    const [busCenterClass, setLoginClass] = useState("mandatory");
    const [countryClass, setCountryClass] = useState("mandatory");
    const [countryClass1, setCountryClass1] = useState("mandatory");
    const [currencyClass, setCurrencyClass] = useState("mandatory");
    const [provinceClass, setProvinceClass] = useState("mandatory");
    const [provinceClass1, setProvinceClass1] = useState("mandatory");
    const [statusClass, setStatusClass] = useState("mandatory");
    const [taxStateClass, setTaxStateClass] = useState("mandatory");
    const [taxStatusClass, setTaxStatusClass] = useState("mandatory");
    const [countryObj, setCountryObj] = useState<any>(defCountryObj);
    const [countryObj1, setCountryObj1] = useState<any>(defCountryObj);
    const [countries, setCountries] = useState(CommonUtil.financeCountryList);

    const [stateObj, setStateObj] = useState<any>(null);
    const [stateObj1, setStateObj1] = useState<any>(null);

    const [taxStateObj, setTaxStateObj] = useState<any>(null);
    const [states, setStates] = useState<any>(CommonUtil.canadaProvinces);
    const [states1, setStates1] = useState<any>(CommonUtil.canadaProvinces);
    const [taxStates, setTaxStates] = useState<any>(CommonUtil.usStates);

    const [currencyObj, setCurrencyObj] = useState<any>(defCurrencyObj);
    const [currencies, setCurrencies] = useState(CommonUtil.currencyList);
    const [statues, setStatues] = useState(CommonUtil.statuses);
    const [statusObj, setStatusObj] = useState<any>(null);

    const [typeObj, setTypesObj] = useState<any>(defTypeObj);
    const [types, setTypes] = useState(CommonUtil.TYPES);

    const [typeObj1, setTypesObj1] = useState<any>(defTypeObj);
    const [types1, setTypes1] = useState(CommonUtil.TYPES);

    const [currencyObj1, setCurrencyObj1] = useState<any>(defCurrencyObj);

    const [isCanadaVisible, setCanadaVisible] = useState("block");
    const [isUSVisible, setUSVisible] = useState("block");

    const insureDateRef = useRef<Flatpickr>(null);
    const [insurenceDate, setInsurenceDate] = useState("");
    const [dateOptions, setDateOptions] = useState(dateformat);

    const signatureDateRef = useRef<Flatpickr>(null);
    const [signatureeDate, setSignatureDate] = useState("");

    const reasonRef = useRef(null);
    const [reason, setReason] = useState("");

    const insNameRef = useRef(null);
    const [insurerName, setInsurereName] = useState("");

    const insNoRef = useRef(null);
    const [insurerNumber, setInsurereNumber] = useState("");

    const insCivilRef = useRef(null);
    const [insCivil, setInsCivil] = useState("");

    const insCarrierCoverRef = useRef<HTMLInputElement>(null);
    const [insCarrierCover, setCarrierCover] = useState((CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)?CommonUtil.MIN_OWNER_COVERAGE:"");

    const insContinentRef = useRef<HTMLInputElement>(null);
    const [continentCargo, setContinentCargo] = useState("");

    const insAssRef = useRef(null);
    const [insAssCov, setInsAssCover] = useState("");
    const [isCardLoad, setCardLoad] = useState(false);
    const [sBankVisible, setSBankVisible] = useState("none");
    const [addBank, setAddBank] = useState("block");

    const statusRef = useRef<HTMLInputElement>(null);
    const stateRef = useRef<HTMLInputElement>(null);

    const [iStatus, setIStatus] = useState("");
    const [state, setState] = useState("");

    const autoDebitRef1 = useRef<HTMLInputElement>(null);
    const autoDebitRef2 = useRef<HTMLInputElement>(null);

    const defBank1 = useRef<HTMLInputElement>(null);
    const defBank2 = useRef<HTMLInputElement>(null);


    const [attachDocs, setAttachDocs] = useState<any>([]);
    const [fp2506Doc, setFP2506Doc] = useState('');
    const [gstDoc, setGSTDoc] = useState('');
    const [w8Doc, setW8Doc] = useState('');
    const [w9Doc, setW9Doc] = useState('');
    const [bankDoc, setBankDoc] = useState("");
    const [bankDoc1, setBankDoc1] = useState("");
    const [taxDoc, setTaxDoc] = useState("");
    const [insurenceDoc, setInsurenceDoc] = useState("");

    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const [latitude1, setLatitude1] = useState("");
    const [longitude1, setLongitude1] = useState("");

    const [page, setPage] = useState("");
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const [provinceText, setProvinceText] = useState("Province");
    const [postalCodeText, setPostalCodeText] = useState("Postal code");
    const [pcTextPlace, setPCTextPlace] = useState("Enter Postal Code");

    const [provinceText1, setProvinceText1] = useState("Province");
    const [postalCodeText1, setPostalCodeText1] = useState("Postal code");
    const [pcTextPlace1, setPCTextPlace1] = useState("Enter Postal Code");

    /* General Tab Buttons */

    const [genEdit, setGenEdit] = useState(false);
    const [genaralClass, setGeneralClass] = useState("disable-form");

    const [insurenceEdit, setInsurenceEdit] = useState(false);
    const [insurenceClass, setInsurenceClass] = useState("disable-form");

    const [taxEdit, setTaxEdit] = useState(false);
    const [taxClass, setTaxClass] = useState("disable-form");

    const [bankEdit, setBankEdit] = useState(false);
    const [bankClass, setBankClass] = useState("disable-form");

    const [bankEdit1, setBankEdit1] = useState(false);
    const [bankClass1, setBankClass1] = useState("disable-form");

    const [cardEdit, setCardEdit] = useState(false);
    const [cardDetails, setCardDetails] = useState<any>({});
    const [cardClass, setCardClass] = useState("disable-form");
    const [isInsFieldDisable, setInsFieldDisable] = useState(false);
    const dropFileObj = React.useRef<any>(null);
    const dropGST = React.useRef(null)
    const dropW8 = React.useRef(null)
    const dropW9 = React.useRef(null)
    const dropInsurance = React.useRef(null)
    const dropTax = React.useRef(null)
    const dropBank = React.useRef(null)
    const dropBank1 = React.useRef(null)

    let onClickSaveFinance = () => {
        let validate: boolean = false;
        let bankValidate: boolean = false;

        if (isCanadaVisible == "block") {
            let isgst: boolean = CommonUtil.isMandatory(gstRef);
            let isqst: boolean = CommonUtil.isMandatory(qstRef);
            let isw8: boolean = CommonUtil.isMandatory(w8tRef);
            if (isgst && isqst && w8Number && isw8) {
                validate = true;
            }
        } else if (isUSVisible == "block") {
            let isw9: boolean = CommonUtil.isMandatory(w9tRef);
            let isreason: boolean = CommonUtil.isMandatory(reasonRef);

            (taxStateObj) ? setTaxStateClass("select-box-valid") : setTaxStateClass("select-box-invalid");
            if (isw9 && isreason && taxStateObj && statusObj) {
                validate = true;
            }
        }
        let cardHolderName = CreditCardDetails.getCardHoderName();
        let isBnNum: boolean = CommonUtil.isMandatory(bntRef);
        let isIsureres: boolean = CommonUtil.isMandatory(insNameRef);
        let isIsuNo: boolean = CommonUtil.isMandatory(insNoRef);
        let isCivil: boolean = CommonUtil.isMandatory(insCivilRef);
        let isAssetCov: boolean = CommonUtil.isMandatory(insAssRef);

        if (validate && isBnNum && isIsureres && isIsuNo && isCivil && isAssetCov && cardHolderName) {
            let isFinInstName: boolean = false;
            let isFinInsNum: boolean = false;
            let isFinInsAddr: boolean = false;
            //let isCountry:boolean = false;
            let isPostlCode: boolean = false;
            let isBankReceipt: boolean = false;
            let isBankTransit: boolean = false;
            let isAbRouteNo: boolean = false;

            isFinInstName = CommonUtil.isMandatory(finInsNameRef);
            isFinInsNum = CommonUtil.isMandatory(finInsNumRef);
            isFinInsAddr = CommonUtil.isMandatory(finInsAddrRef);
            isPostlCode = CommonUtil.isMandatory(postalCodeRef);
            (countryObj) ? setCountryClass("select-box-valid") : setCountryClass("select-box-invalid");
            (stateObj) ? setProvinceClass("select-box-valid") : setProvinceClass("select-box-invalid");
            (currencyObj) ? setCountryClass("select-box-valid") : setCountryClass("select-box-invalid");
            isBankReceipt = CommonUtil.isMandatory(bankRecRef);
            isBankTransit = CommonUtil.isMandatory(bankTransRef);
            isAbRouteNo = CommonUtil.isMandatory(abaRouteNoRef);

            if (sBankVisible == "none") {
                if (isFinInstName && isFinInsNum && isFinInsAddr && countryObj && stateObj && isPostlCode && currencyObj && isBankReceipt && isBankTransit && isAbRouteNo) {
                    bankValidate = true;
                }
            } else {
                if (isFinInstName && isFinInsNum && isFinInsAddr && countryObj && stateObj && isPostlCode && currencyObj && isBankReceipt && isBankTransit && isAbRouteNo) {
                    let isFinInstName1 = CommonUtil.isMandatory(finInsNameRef1);
                    let isFinInsNum1 = CommonUtil.isMandatory(finInsNumRef1);
                    let isFinInsAddr1 = CommonUtil.isMandatory(finInsAddrRef1);
                    let isPostlCode1 = CommonUtil.isMandatory(postalCodeRef1);
                    (countryObj1) ? setCountryClass1("select-box-valid") : setCountryClass1("select-box-invalid");
                    (stateObj1) ? setProvinceClass1("select-box-valid") : setProvinceClass1("select-box-invalid");
                    (currencyObj1) ? setCountryClass1("select-box-valid") : setCountryClass1("select-box-invalid");
                    let isBankReceipt1 = CommonUtil.isMandatory(bankRecRef1);
                    let isBankTransit1 = CommonUtil.isMandatory(bankTransRef1);
                    let isAbRouteNo1 = CommonUtil.isMandatory(abaRouteNoRef1);

                    if (isFinInstName1 && isFinInsNum1 && isFinInsAddr1 && countryObj1 && stateObj1 && isPostlCode1 && currencyObj1 && isBankReceipt1 && isBankTransit1 && isAbRouteNo1) {
                        bankValidate = true;
                    }
                }
            }
        }
        // validate = true;
        //  bankValidate = true;
        if (validate && bankValidate) {
            CreditCardDetails.getCardDetails((res: any) => {
                console.log(res);
                let banks: any = [];
                let restObj: any = {};
                restObj.businessNumber = bnNumber;
                restObj.gstOrHstNumber = gstNumber;
                restObj.qstOrPstNumber = qstNumber;

                restObj.cardHolderName = CreditCardDetails.getCardHoderName();
                if (res) {
                    restObj.creditCardExpiryMonth = res.expiryMonth ? res.expiryMonth : "";
                    restObj.creditCardExpiryYear = res.expiryYear ? res.expiryYear : "";
                    ;
                    restObj.creditCardLast4 = res.last4 ? res.last4 : "";
                    restObj.creditCardTokenCanada = res.token ? res.token : "";
                    restObj.creditCardTokenUs = res.token ? res.token : "";
                }

                restObj.w8Number = w8Number;
                restObj.w9Number = w9Number;

                restObj.insuranceExpirationDate = insurenceDate;//insureDateRef.current?
                restObj.insurersName = insurerName;
                restObj.insurersNumber = insurerNumber;
                restObj.minCoverageCivil = insCivil;
                restObj.minCoverageNonOwnedAssets = insAssCov;
                restObj.saasOrgId = CommonUtil.getSaasOrgId();//localStorage.getItem("saasorgId");

                restObj.taxExemptionReason = reason;
                restObj.taxSignatureDate = signatureeDate;
                restObj.taxState = taxStateObj ? taxStateObj.Key : "";
                restObj.taxStatus = statusObj ? statusObj.Key : "";


                let bank: any = {};
                bank.abaRoutingNumber = abaRouteNo;
                bank.accountNumber = bankAccNo;
                //bank.businessLocation = countryObj?countryObj.Key:"";
                bank.city = city;
                bank.country = countryObj ? countryObj.Key : "";
                bank.currency = currencyObj ? currencyObj.Key : "";
                bank.financialInstitutionAddress = finAddInstAddress;
                bank.rawAddress = finAddInstAddress;
                bank.financialInstitutionName = finAddInstNumber;
                bank.financialInstitutionNumber = finAddInstNumber;
                bank.isAutoDebit = (autoDebitRef1.current?.checked == true) ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
                bank.receipientsName = bankReceiptName;
                bank.transit = bankTransit;
                bank.zipCode = postalcode;
                bank.state = stateObj ? stateObj.Key : "";

                banks.push(bank);

                if (sBankVisible == "block") {
                    let bank1: any = {};
                    bank1.abaRoutingNumber = abaRouteNo1;
                    bank1.accountNumber = bankAccNo1;
                    //bank1.businessLocation = countryObj1?countryObj1.Key:"";
                    bank1.city = city1;
                    bank1.country = countryObj1 ? countryObj1.Key : "";
                    bank1.currency = currencyObj1 ? currencyObj1.Key : "";
                    bank1.financialInstitutionAddress = finAddInstAddress1;
                    bank1.rawAddress = finAddInstAddress1;
                    bank1.financialInstitutionName = finAddInstNumber1;
                    bank1.financialInstitutionNumber = finAddInstNumber1;
                    bank1.isAutoDebit = (autoDebitRef2.current?.checked == true) ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
                    bank1.receipientsName = bankReceiptName1;
                    bank1.transit = bankTransit1;
                    bank1.zipCode = postalcode1;
                    bank1.state = stateObj1 ? stateObj1.Key : "";
                    banks.push(bank1);
                }

                restObj.bankAccounts = banks;

                var listAPI: string = "api/organization/saveFinance?saasOrgId=" + CommonUtil.getSaasOrgId();
                NetworkManager.sendJsonRequest(listAPI, restObj, "", "POST", true, onSaveFinance, onLoginError);

            });
        }


    }

    let onSaveFinance = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            BusyLoader.hideLoader();
            if (restData) {
                if (restData.statusCode) {
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), "Error");
                } else {
                    ToastManager.showToast("Financial Information Updated Successfully", "Info");
                }
            }
        }

    }

    let onStatusChange = (data: any) => {
        setStatusObj(data);
        (data) ? setTaxStatusClass("select-box-valid") : setTaxStatusClass("select-box-invalid");
    }

    let uploadFinanceDocuments = (fType: string) => {
        fileType = fType;
        onFPGSTFormUpload();
    }
    let onFPGSTFormUpload = () => {
        if (fileref.current) {
            fileref.current.value = "";
            if (document.getElementById("inputGST")) {
                // document.getElementById("inputGST").click();
            }

            $("#inputGST").click();
        }
        setFileName("");
    }

    let onSelectFiles = (e: any) => {
        //console.log(e);
        if (e && e.target && e.target.files[0]) {
            let fileName = e.target.files[0].name;
            setFileName(fileName);
            BusyLoader.showLoader();
            fullPath = "objectPath=documents/organizations/" + CommonUtil.getSelectedOrgId() + "/" + fileName;
            var qParam: string = fullPath;
            NetworkManager.sendJsonRequest("api/session/uploadUrl", "", qParam, "GET", false, onUploadSuccess, onLoginError);
        }
    }
    let onUploadSuccess = (dataObj: any) => {
        if (dataObj) {
            if (dataObj.statusCode) {
                BusyLoader.hideLoader();
                var errMsg = (StatusCodeUtil.getCodeMessage(dataObj.statusCode));
                ToastManager.showToast(errMsg, "Error");
            } else {
                var strUploadPath = dataObj;
                if (fileref.current && fileref.current.files) {
                    const formData = new FormData();
                    var myHeaders = new Headers();
                    var file = fileref.current.files[0];
                    var requestOptions: any = { method: 'PUT', headers: myHeaders, body: file, redirect: 'follow' };
                    fetch(strUploadPath, requestOptions)
                        .then(response => response.text())
                        .then(result => {
                            //BusyLoader.hideLoader();
                            uploadDocument();
                        })
                        .catch(error => {
                            //  BusyLoader.hideLoader();
                            console.log('error', error);
                        });
                }
            }
        }
    }

    let uploadDocument = () => {
        if (fileref.current && fileref.current.files && fileref.current.files[0]) {
            let fName: string = fileref.current.files[0].name;
            let fType: string = "";
            //let fType:string = "FP-2506";
            if (fileType == "GST") {
                fType = CommonUtil.FP2506_DOC_TYPE;
                if (fName.indexOf("GST506") >= 0) {
                    fType = CommonUtil.GST506_DOC_TYPE;
                }
            } else {
                fType = fileType;
            }

            let restObj: any = {};
            restObj.documentName = fileref.current.files[0].name;
            restObj.documentType = fType;
            restObj.fullFilePath = "documents/organizations/" + CommonUtil.getSelectedOrgId() + "/" + fileref.current.files[0].name;;//"documents/organizations/" + CommonUtil.getSelectedOrgId() + "/" + fileref.current.files[0].name;
            restObj.saasOrgId = CommonUtil.getSaasOrgId();

            let uploadUrl: string = "api/organization/updateFinanceAndInsuranceForms?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(uploadUrl, restObj, "", "PUT", false, onUpdateDocument, onLoginError);
        }
    }

    let onUpdateDocument = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData.statusCode) {
                BusyLoader.hideLoader();
                var errMsg = (StatusCodeUtil.getCodeMessage(restData.statusCode));
                ToastManager.showToast(errMsg, "Error");
            } else {
                ToastManager.showToast("Document uploaded successfully", "Info");
            }
        } else {
            ToastManager.showToast("Document uploaded successfully", "Info");
        }
        getFinanceInfo();
        BusyLoader.hideLoader();
    }

    let getAttachmentId = (docType: string) => {
        let attachId = "";
        if (attachDocs && attachDocs.length > 0) {
            for (let i = 0; i < attachDocs.length; i++) {
                if (attachDocs[i].documentType == docType) {
                    attachId = attachDocs[i].attachementId;
                    break;
                }
            }
        }
        return attachId;
    }

    let getAttachmentFilePath = (docType: string) => {
        let filePath = "";
        if (attachDocs && attachDocs.length > 0) {
            for (let i = 0; i < attachDocs.length; i++) {
                if (attachDocs[i].documentType == docType) {
                    filePath = attachDocs[i].fullFilePath;
                    break;
                }
            }
        }
        return filePath;
    }

    let onRemoveFP2506Doc = (attachId: string) => {
        // Confirm.show("dosos",["yes","No"])
        // selFileType = fType;
        Confirm.show("Confirm", "Do you want remove document?", [{
            label: "Yes", callback: function () {
                //let attachId = getAttachmentId(fType);
                // BusyLoader.showLoader();
                if (attachId) {
                    let downloadAPI: string = "api/organization/deleteFinanceAndInsuranceForms?attachmentId=" + attachId + "&saasOrgId=" + CommonUtil.getSaasOrgId();
                    NetworkManager.sendJsonRequest(downloadAPI, "", "", "DELETE", true, onDeleteFinanceForms, onLoginError);
                }
            }
        }, { label: "No" }]);

    }
    let onDeleteFinanceForms = (dataObj: any) => {
        let flag: boolean = true;
        if (dataObj) {
            //  var restData = JSON.parse(dataObj);
            if (dataObj.statusCode) {
                flag = false;
                BusyLoader.hideLoader();
                var errMsg = (StatusCodeUtil.getCodeMessage(dataObj.statusCode));
                ToastManager.showToast(errMsg, "Error");
            } else {

            }
        }
        if (flag) {
            ToastManager.showToast("File Removed Successfully", "Info");
            getFinanceInfo();
        }

    }

    let onDownloadFP2506Doc = (filePath: string) => {
        // selFileType = fType;
        // let filePath = getAttachmentFilePath(fType);
        if (filePath) {
            var qParam: string = "objectPath=" + filePath;
            NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadFP2506Success, onLoginError);
        }
    }

    let onDownloadFP2506Success = (dataObj: any) => {
        //console.log(dataObj);
        let link = document.createElement("a");
        link.download = "download";
        link.href = dataObj;
        link.target = "_blank"
        link.click();
    }


    let onBusCenterChange = (data: any) => {
        setBusCenterObj(data);
    }
    let onBusCenterChange1 = (data: any) => {
        setBusCenterObj1(data);
    }
    let onCountryChange = (data: any) => {
        setBankAccNo("");
        setCountryObj(data);
        setStateObj(null);
        countryChange(data);

    }

    let onTypeChange = (data: any) => {
        setTypesObj(data);
    }
    let onTypeChange1 = (data: any) => {
        setTypesObj1(data);
    }

    let countryChange = (data: any) => {
        $("#divABARouteNum").show();
        $("#divFinInsNum").show();
        $("#divTransit").show();
        if (data) {
            if (data.Key == CommonUtil.CANADA) {
                $("#divABARouteNum").hide();
                setStates(CommonUtil.canadaProvinces);
                setCurrencyObj({Key:CommonUtil.CANADA_CURRENCY,Value:CommonUtil.CANADA_CURRENCY});
            } else {
                $("#divFinInsNum").hide();
                $("#divTransit").hide();
                setCurrencyObj({Key:CommonUtil.US_CURRENCY,Value:CommonUtil.US_CURRENCY});
                setStates(CommonUtil.usStates);
            }
        } else {
            setCountryObj(null);
            setCurrencyObj(null);
            setStates([]);

        }
        (data) ? setCountryClass("select-box-valid") : setCountryClass("select-box-invalid");

        let strProvinceText = CommonUtil.getMessageText(Constants.PROVINCE,"Province");//"Province";
        let strPostalCodeText = CommonUtil.getMessageText(Constants.POSTAL_CODE,"Postal code");//"Postal code";
        let strPostalCodePlace = CommonUtil.getMessageText(Constants.ENTER_POSTAL_CODE,"Enter Postal Code");//"Enter Postal Code";

        if (data && data.Key == CommonUtil.US) {
            strProvinceText = CommonUtil.getMessageText(Constants.STATE,"State");//"State";
            strPostalCodeText = CommonUtil.getMessageText(Constants.ZIP_CODE,"ZIP code");//"ZIP code";
            strPostalCodePlace = CommonUtil.getMessageText(Constants.ENTER_ZIP_CODE,"Enter ZIP Code");//"Enter ZIP Code";
        }

        setProvinceText(strProvinceText);
        setPostalCodeText(strPostalCodeText);
        setPCTextPlace(strPostalCodePlace);
    }

    let onCountryChange1 = (data: any) => {
        setBankAccNo1("");
        setCountryObj1(data);
        setStateObj1(null);
        countryChange1(data);
    }
    let countryChange1 = (data: any) => {
        $("#divABARouteNum1").show();
        $("#divFinInsNum1").show();
        $("#divTransit1").show();

        if (data) {
            if (data.Key == CommonUtil.CANADA) {
                $("#divABARouteNum1").hide();
                setStates1(CommonUtil.canadaProvinces);
                setCurrencyObj1({Key:CommonUtil.CANADA_CURRENCY,Value:CommonUtil.CANADA_CURRENCY});
            } else {
                $("#divFinInsNum1").hide();
                $("#divTransit1").hide();
                setStates1(CommonUtil.usStates);
                setCurrencyObj1({Key:CommonUtil.US_CURRENCY,Value:CommonUtil.US_CURRENCY});
            }
        } else {
            setCountryObj1(null);
            setCurrencyObj1(null);
            setStates1([]);
        }
        (data) ? setCountryClass1("select-box-valid") : setCountryClass1("select-box-invalid");

        let strProvinceText = CommonUtil.getMessageText(Constants.PROVINCE,"Province");//"Province";
        let strPostalCodeText = CommonUtil.getMessageText(Constants.POSTAL_CODE,"Postal code");//"Postal code";
        let strPostalCodePlace = CommonUtil.getMessageText(Constants.ENTER_POSTAL_CODE,"Enter Postal Code");//"Enter Postal Code";

        if (data && data.Key == CommonUtil.US) {
            strProvinceText = CommonUtil.getMessageText(Constants.STATE,"State");//"State";
            strPostalCodeText = CommonUtil.getMessageText(Constants.ZIP_CODE,"ZIP code");//"ZIP code";
            strPostalCodePlace = CommonUtil.getMessageText(Constants.ENTER_ZIP_CODE,"Enter ZIP Code");//"Enter ZIP Code";
        }

        setProvinceText1(strProvinceText);
        setPostalCodeText1(strPostalCodeText);
        setPCTextPlace1(strPostalCodePlace);
    }

    let onStateChange = (data: object) => {
        setStateObj(data);
        (data) ? setProvinceClass("select-box-valid") : setProvinceClass("select-box-invalid");
    }

    let onStateChange1 = (data: object) => {
        setStateObj1(data);
        (data) ? setProvinceClass1("select-box-valid") : setProvinceClass1("select-box-invalid");
    }

    let onTaxStateChange = (dataObj: any) => {
        setTaxStateObj(dataObj);
        (dataObj) ? setTaxStateClass("select-box-valid") : setTaxStateClass("select-box-invalid");
    }

    let onCurrencyChange = (data: any) => {
        setCurrencyObj(data);
        (data) ? setCountryClass("select-box-valid") : setCountryClass("select-box-invalid");
    }

    let onCurrencyChange1 = (data: any) => {
        setCurrencyObj1(data);
        (data) ? setCountryClass1("select-box-valid") : setCountryClass1("select-box-invalid");
    }
    let onSelectDrop = (e: any) => {
        //console.log(e);
        if (e && e.name) {
            let fileName = e.name;
            setFileName(fileName);
            BusyLoader.showLoader();
            fullPath = "objectPath=documents/organizations/" + CommonUtil.getSelectedOrgId() + "/" + fileName;
            var qParam: string = fullPath;
            NetworkManager.sendJsonRequest("api/session/uploadUrl", "", qParam, "GET", false, onUploadSuccessDrop, onLoginError);
        }
    }
    let onUploadSuccessDrop = (dataObj: any) => {
        if (dataObj.statusCode) {
            BusyLoader.hideLoader();
            let errMsg = (StatusCodeUtil.getCodeMessage(dataObj.statusCode));
            ToastManager.showToast(errMsg, "Error");
        } else {
            let myHeaders = new Headers();
            let requestOptions: any = { method: 'PUT', headers: myHeaders, body: dropFileObj.current, redirect: 'follow' };
            fetch(dataObj, requestOptions)
                .then(response => response.text())
                .then(result => {
                    let restObj: any = {};
                    restObj.documentName = dropFileObj?.current.name;
                    restObj.documentType = fileType;
                    restObj.fullFilePath = "documents/organizations/" + CommonUtil.getSelectedOrgId() + "/" + dropFileObj?.current.name;//"documents/organizations/" + CommonUtil.getSelectedOrgId() + "/" + fileref.current.files[0].name;
                    restObj.saasOrgId = CommonUtil.getSaasOrgId();
                    // console.log(restObj, 'restObj')
                    let uploadUrl: string = "api/organization/updateFinanceAndInsuranceForms?saasOrgId=" + CommonUtil.getSaasOrgId();
                    NetworkManager.sendJsonRequest(uploadUrl, restObj, "", "PUT", false, onUpdateDocument, onLoginError);
                })
                .catch(error => {
                    BusyLoader.hideLoader();
                    console.log('error', error);
                });
        }
    }
    const handleDragEnter = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragLeave = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragOver = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDrop = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.GST506_DOC_TYPE;//'GST506';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            onSelectDrop(files[0]);
        }
    }
    const handleDropW8 = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.W8_DOC_TYPE;//'W8';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            onSelectDrop(files[0]);
        }
    }
    const handleDropW9 = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.W9_DOC_TYPE;//'W9';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            onSelectDrop(files[0]);
        }
    }
    const handleDropInsurance = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.INSUR_DOC_TYPE;//'Insurence';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            onSelectDrop(files[0]);
        }
    }
    const handleDropTax = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.TAX_DOC_TYPE;//'Tax';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            onSelectDrop(files[0]);
        }
    }
    const handleDropBank = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.BANK_DOC_TYPE;//'Bank';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            onSelectDrop(files[0]);
        }
    }
    const handleDropBank1 = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        fileType = CommonUtil.BANK1_DOC_TYPE;//'Bank1';
        const { files } = e.dataTransfer
        if (files && files.length) {
            dropFileObj.current = files[0];
            onSelectDrop(files[0]);
        }
    }
    useEffect(() => {
        setPostalCodeText(CommonUtil.getMessageText(Constants.POSTAL_CODE,"Postal code"));
        setPCTextPlace(CommonUtil.getMessageText(Constants.ENTER_POSTAL_CODE,"Enter Postal Code"));
        setProvinceText(CommonUtil.getMessageText(Constants.PROVINCE,"Province"));

        setPostalCodeText1(CommonUtil.getMessageText(Constants.POSTAL_CODE,"Postal code"));
        setPCTextPlace1(CommonUtil.getMessageText(Constants.ENTER_POSTAL_CODE,"Enter Postal Code"));
        setProvinceText1(CommonUtil.getMessageText(Constants.PROVINCE,"Province"));


        DragNDrop({ drop: dropGST, handleDragOver, handleDrop, handleDragEnter, handleDragLeave })
        DragNDrop({ drop: dropW8, handleDragOver, handleDrop: handleDropW8, handleDragEnter, handleDragLeave })
        DragNDrop({ drop: dropW9, handleDragOver, handleDrop: handleDropW9, handleDragEnter, handleDragLeave })
        DragNDrop({ drop: dropInsurance, handleDragOver, handleDrop: handleDropInsurance, handleDragEnter, handleDragLeave })
        DragNDrop({ drop: dropTax, handleDragOver, handleDrop: handleDropTax, handleDragEnter, handleDragLeave })
        DragNDrop({ drop: dropBank, handleDragOver, handleDrop: handleDropBank, handleDragEnter, handleDragLeave })
        DragNDrop({ drop: dropBank1, handleDragOver, handleDrop: handleDropBank1, handleDragEnter, handleDragLeave })
        // console.log(flag);
        //console.log((CommonUtil.ORGANIZATION_TYPE == (CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORG_TYPE_OWNER_RENTER)));
        if (CommonUtil.isRootUser() || (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER) || (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)) {
            setInsFieldDisable(true);
        }

        flag = true;
        if (CommonUtil.getCustomerCountry() == CommonUtil.US) {
            setCanadaVisible("none");
            setTaxStates(CommonUtil.usStates);
        }
        if (CommonUtil.getCustomerCountry() == CommonUtil.CANADA) {
            setUSVisible("none");
            setTaxStates(CommonUtil.canadaProvinces);
        }
        // CreditCardDetails.showCardDetails();
        resetFormDetails();
        getFinanceInfo();
        getInsurenceDetails();
        getCrediCardDetails();
        getTaxInfo();
        loadGooglePlaces();

        strInfo = CommonUtil.getMessageText(Constants.INFO, "Info");
        strError = CommonUtil.getMessageText(Constants.ERROR, "Error");

        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);

        //console.log();
        return () => {
            console.log("return");
            //CreditCardDetails
           // CreditCardDetails
        }

    }, []);

    let onSwitchCustomer = ()=>{
        if(CommonUtil.isRootUser()){
            Events.dispatchBreadCrumbEvent();
            //navigate("orgList1");
        }else{
            resetFormDetails();
            getFinanceInfo();
            getInsurenceDetails();
            getCrediCardDetails();
            getTaxInfo();
            loadGooglePlaces();
            //navigate("orgList1");
        }
        Events.dispatchBreadCrumbEvent();
    }

    let loadGooglePlaces = () => {
        if (window.google) {
            // console.log(google.maps.MapTypeId.ROADMAP);
            setScriptLoaded(true);
            getGooglePlaces();
        } else {
            const googleMapScript = loadMapApi();
            // console.log(googleMapScript);
            if (googleMapScript) {
                googleMapScript.addEventListener('load', function () {
                    //console.log("Map loaded");
                    // console.log(window.google);
                    setScriptLoaded(true);
                    getGooglePlaces();
                });
            }
        }
    }

    let getGooglePlaces = () => {
        const bankAddr = document.getElementById("bankAddr") as HTMLInputElement;
        CommonUtil.getPlaceAddressByMap(bankAddr, (result: any) => {
            //console.log(result);
            setFinAddInstRawAddress(result['rawAddress']);
            setFinAddInstAddress(result['address1']);
            setCity(result['city']);
            setPostalCode(result['postalcode']);
            setCountryObj({ Key: result['country'], Value: result['country'] });
            setStateObj({ Key: result['state'], Value: result['state'] });
            onCountryChange({ Key: result['country'], Value: result['country'] });
            setStateObj({ Key: result['state'], Value: result['state'] });
            setLatitude(result['lat']);
            setLongitude(result['lng']);
        });


        /*const autocomplete = new google.maps.places.Autocomplete(input, {
            componentRestrictions: {country: ["us", "ca"]}
        });
        autocomplete.addListener("place_changed", () => {
            let addr: any = CommonUtil.getGoogleMapAddress(autocomplete);
            console.log(addr);
            if (addr) {
                setFinAddInstAddress(addr['rawAddress']);
                setCity(addr['city']);
                setPostalCode(addr['postalcode']);
                setCountryObj({Key: addr['country'], Value: addr['country']});
                setStateObj({Key: addr['state'], Value: addr['state']});
                onCountryChange({Key: addr['country'], Value: addr['country']});
                setStateObj({Key: addr['state'], Value: addr['state']});
                setLatitude(addr['lat']);
                setLongitude(addr['lng']);

                CommonUtil.getTimeZome(addr['lat'], addr['lng'], (data: any) => {
                    //console.log(data);
                })
            }
        });*/

        const bankAddr1 = document.getElementById("bankAddr1") as HTMLInputElement;
        CommonUtil.getPlaceAddressByMap(bankAddr1, (addr: any) => {
            //console.log(result);
            setFinAddInstAddress1(addr['address1']);
            setFinAddInstRawAddress1(addr['rawAddress']);
            setCity1(addr['city']);
            setPostalCode1(addr['postalcode']);
            setCountryObj1({ Key: addr['country'], Value: addr['country'] });
            setStateObj1({ Key: addr['state'], Value: addr['state'] });
            onCountryChange1({ Key: addr['country'], Value: addr['country'] });
            setStateObj1({ Key: addr['state'], Value: addr['state'] });
            setLatitude1(addr['lat']);
            setLongitude1(addr['lng']);
        });
        /*const autocomplete1 = new google.maps.places.Autocomplete(input1, {
            componentRestrictions: {country: ["us", "ca"]},
            fields: ["address_components", "geometry", "formatted_address", "name"],
            types: ["address"],
        });
        autocomplete1.addListener("place_changed", () => {
            let addr: any = CommonUtil.getGoogleMapAddress(autocomplete1);
            console.log(addr);
            if (addr) {
                setFinAddInstAddress1(addr['rawAddress']);
                setCity1(addr['city']);
                setPostalCode1(addr['postalcode']);
                setCountryObj1({Key: addr['country'], Value: addr['country']});
                setStateObj1({Key: addr['state'], Value: addr['state']});
                onCountryChange1({Key: addr['country'], Value: addr['country']});
                setStateObj1({Key: addr['state'], Value: addr['state']});
                setLatitude1(addr['lat']);
                setLongitude1(addr['lng']);

                CommonUtil.getTimeZome(addr['lat'], addr['lng'], (data: any) => {
                    //console.log(data);
                })
            }
        });*/
    }

    let getFinanceInfo = () => {
        let financeAPI: string = "api/organization/finance/getFinanceDetails?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(financeAPI, "", "", "GET", true, onGetFinanceInfo, onLoginError);
    }

    let resetFinanceInfo = ()=>{
        setGSTNumber("");
        setQSTNumber("");
        setBNNumber("");
        setW8Number("");
        setW9Number("");
        setAttachDocs([]);
    }

    let onGetFinanceInfo = (dataObj: any) => {
        if (dataObj) {
            var restData = JSON.parse(dataObj);
            if (restData) {
                if (restData.statusCode) {
                    //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                    ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), strError);
                } else {
                    setGSTNumber(restData.gstOrHstNumber);
                    setQSTNumber(restData.qstOrPstNumber);
                    setBNNumber(restData.businessNumber);
                    setW8Number(restData.w8Number);
                    setW9Number(restData.w9Number);



                    /*CreditCardDetails.setCreditCardHolderName(restData.cardHolderName);
                    let crditCardNum:string = (restData.creditCardLast4?"last 4 digits: ************"+restData.creditCardLast4:"");
                    let crditCvv:string = (restData.creditCardLast4?"CVV ***":"");
                    let crditExpDate:string = (restData.creditCardExpiryMonth4?("Exp Date: "+restData.creditCardExpiryMonth+"/"+restData.creditCardExpiryYear):"");
                    CreditCardDetails.setCreditCardHolderNo(crditCardNum);//"last 4 digits: ************"+restData.creditCardLast4);
                    CreditCardDetails.setCreditCardHolderCVV(crditCvv);
                    CreditCardDetails.setCreditCardHolderExpDate(crditExpDate);

                    setInsurereName(restData.insurersName);
                    setInsurereNumber(restData.insurersNumber);
                    setInsAssCover(restData.minCoverageCivil);
                    setInsCivil(restData.minCoverageCivil);

                    setReason(restData.taxExemptionReason);
                    setTaxStateObj({Key:restData.taxState,Value:restData.taxState});
                    setStatusObj({Key:restData.taxStatus,Value:restData.taxStatus});

                    if(restData.insuranceExpirationDate){
                        if(insureDateRef.current){
                            //setSignatureDate
                            setInsurenceDate(restData.insuranceExpirationDate);
                            insureDateRef.current.flatpickr.setDate(new Date(restData.insuranceExpirationDate));
                        }
                    }

                    if(restData.taxSignatureDate){
                        if(signatureDateRef.current){
                            setSignatureDate(restData.taxSignatureDate);
                            signatureDateRef.current.flatpickr.setDate(new Date(restData.taxSignatureDate));
                        }
                    }*/



                    let docs = [];
                    if (restData.documents) {
                        if (Array.isArray(restData.documents)) {
                            docs = restData.documents;
                        } else {
                            docs.push(restData.documents);
                        }
                        setAttachDocs(docs);
                        //console.log(attachDocs);
                        docs.forEach((item: any, idx: number) => {
                            //console.log(item);
                            /*if (item.documentType == CommonUtil.FP2506_DOC_TYPE) {
                                setFP2506Doc(item.documentName);
                            } else if (item.documentType == CommonUtil.GST506_DOC_TYPE) {
                                setGSTDoc(item.documentName);
                            } else if (item.documentType == CommonUtil.W8_DOC_TYPE) {
                                setW8Doc(item.documentName);
                            } else if (item.documentType == CommonUtil.W9_DOC_TYPE) {
                                setW9Doc(item.documentName);
                            } else if (item.documentType == CommonUtil.BANK_DOC_TYPE) {
                                setBankDoc(item.documentName);
                            } else if (item.documentType == CommonUtil.BANK1_DOC_TYPE) {
                                setBankDoc1(item.documentName);
                            } else if (item.documentType == CommonUtil.INSUR_DOC_TYPE) {
                                setInsurenceDoc(item.documentName);
                            } else if (item.documentType == CommonUtil.TAX_DOC_TYPE) {
                                setTaxDoc(item.documentName);
                            }*/
                        });
                    }
                }
            }
        }
    }

    let getTaxInfo = () => {

    }

    let getInsurenceDetails = () => {
        let insurenceAPI: string = "api/organization/finance/getInsuranceDetails?saasOrgId=" + CommonUtil.getSaasOrgId();;
        NetworkManager.sendJsonRequest(insurenceAPI, "", "", "GET", true, onGetInsureceInfo, onLoginError);
    }

    let onGetInsureceInfo = (dataObj: any) => {
        //console.log(dataObj);
        var restData = JSON.parse(dataObj);
        if (restData) {
            if (restData.statusCode) {
                //setErrorMsg(StatusCodeUtil.getCodeMessage(restData.statusCode));
                ToastManager.showToast(StatusCodeUtil.getCodeMessage(restData.statusCode), strError);
            } else {

                setInsurereName(restData.insurersName);
                setInsurereNumber(restData.insurersNumber);
                setInsAssCover(restData.minCoverageNonOwnedAssets);
                setInsCivil(restData.minCoverageCivil);
                if(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER){
                    setCarrierCover(restData.carrierRequiredCoverage?restData.carrierRequiredCoverage:CommonUtil.MIN_OWNER_COVERAGE);
                }else{
                    setCarrierCover(restData.carrierRequiredCoverage);//?restData.carrierRequiredCoverage:CommonUtil.MIN_OWNER_COVERAGE);
                }

                setContinentCargo(restData.contingentCargo);

                if (restData.insuranceExpirationDate) {
                    if (insureDateRef.current) {
                        setInsurenceDate(restData.insuranceExpirationDate);
                        insureDateRef.current.flatpickr.setDate(new Date(restData.insuranceExpirationDate));
                    }
                }


            }
        }
    }

    let getCrediCardDetails = () => {
        let getCrediDetailsAPI: string = "api/organization/getFinance?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getCrediDetailsAPI, "", "", "GET", true, onGetCreditCardInfo, onLoginError);
    }

    let resetFormDetails = ()=>{
        CreditCardDetails.setCreditCardHolderName("");
        CreditCardDetails.setCreditCardHolderNo("");
        CreditCardDetails.setCreditCardHolderCVV("");
        CreditCardDetails.setCreditCardHolderExpDate("");

        setTaxStateObj(null);
        setStatusObj(null);
        setSignatureDate("");
        setFinInstName("");
        setFinAddInstNumber("");
        setFinAddInstAddress("");
        setFinAddInstRawAddress("");
        onCountryChange(null);
        setStateObj(null);
        setCity("");
        setCurrencyObj(null);
        setTypesObj(null);
        setBankAccNo("");
        setBankReceiptName("");
        setBankTransit("");
        setABARouteNo("");
        setPostalCode("");

        setFinInstName1("");
        setFinAddInstNumber1("");
        setFinAddInstAddress1("");
        setFinAddInstRawAddress1("");
        onCountryChange1(null);
        setStateObj1(null);
        setCity1("");
        setCurrencyObj1(null);
        setTypesObj1(null);
        setBankAccNo1("");
        setBankReceiptName1("");
        setBankTransit1("");
        setABARouteNo1("");
        setPostalCode1("");

        //onClickRemoveBank();

        setSBankVisible("none");
        setAddBank("block");

        bankAccId1 = "";
        bankAccId2 = "";

        if (autoDebitRef1.current) {
            autoDebitRef1.current.checked = true;
        }
        if (defBank1.current) {
            defBank1.current.checked = true;
        }
        resetFinanceInfo();
        setInsurereName("");
        setInsurereNumber("");
        setInsAssCover("");
        setInsCivil("");

        setCarrierCover("");
        setContinentCargo("");
        setInsurenceDate("");

       // insureDateRef.current.flatpickr.setDate(new Date(restData.insuranceExpirationDate));


    }

    let onGetCreditCardInfo = (dataObj: any) => {
        // console.log(dataObj);
        if (dataObj) {
            var restData: any = CommonUtil.parseData(dataObj);
            if (restData) {
                ccId = restData.ccId;
                CreditCardDetails.setCreditCardHolderName(restData.cardHolderName);
                let crditCardNum: string = (restData.creditCardLast4 ? "last 4 digits: ************" + restData.creditCardLast4 : "");
                let crditCvv: string = (restData.creditCardLast4 ? "CVV ***" : "");
                let crditExpDate: string = (restData.creditCardExpiryMonth4 ? ("Exp Date: " + restData.creditCardExpiryMonth + "/" + restData.creditCardExpiryYear) : "");
                CreditCardDetails.setCreditCardHolderNo(crditCardNum);//"last 4 digits: ************"+restData.creditCardLast4);
                CreditCardDetails.setCreditCardHolderCVV(crditCvv);
                CreditCardDetails.setCreditCardHolderExpDate(crditExpDate);
                setCardDetails({crditCardHolderName:restData.cardHolderName,crditCardNum:restData.creditCardLast4,crditCvv:"***",crditExpDate:restData.creditCardExpiryMonth + "/" + restData.creditCardExpiryYear});
                if(crditCardNum && crditCvv && crditExpDate){
                    setSectionCompleted("creditCardDetails", updateProfile);
                }
                setReason(restData.taxExemptionReason);
                if (restData.taxState) {
                    setTaxStateObj({ Key: restData.taxState, Value: restData.taxState });
                }
                if (restData.taxStatus) {
                    setStatusObj({ Key: restData.taxStatus, Value: restData.taxStatus });
                }


                if (restData.taxSignatureDate) {
                    if (signatureDateRef.current) {
                        setSignatureDate(restData.taxSignatureDate);
                        signatureDateRef.current.flatpickr.setDate(new Date(restData.taxSignatureDate));
                    }
                }
                let banks = [];
                if (restData.bankAccounts) {
                    if (Array.isArray(restData.bankAccounts)) {
                        banks = restData.bankAccounts;
                    } else {
                        banks.push(restData.bankAccounts);
                    }
                }
                if (banks.length > 0) {
                    if (banks[0]) {
                        bankAccId1 = banks[0].bankAccountId;
                        setFinInstName(banks[0].financialInstitutionName);
                        setFinAddInstNumber(banks[0].financialInstitutionNumber);
                        setFinAddInstAddress(banks[0].financialInstitutionAddress);
                        setFinAddInstRawAddress(banks[0].rawAddress);
                        onCountryChange({ Key: banks[0].country, Value: banks[0].country });
                        setStateObj({ Key: banks[0].state, Value: banks[0].state });
                        setCity(banks[0].city);
                        setCurrencyObj({ Key: banks[0].currency, Value: banks[0].currency });
                        setTypesObj({ key: banks[0].transferType, value: banks[0].transferType });
                        setBankAccNo(banks[0].accountNumber);
                        setBankReceiptName(banks[0].receipientsName);
                        setBankTransit(banks[0].transit);
                        setABARouteNo(banks[0].abaRoutingNumber);
                        setPostalCode(banks[0].zipCode);
                        if (banks[0].isAutoDebit == "Y") {
                            if (autoDebitRef1.current) {
                                autoDebitRef1.current.checked = true;
                            }
                        }
                        if (banks[0].isDefault == "Y") {
                            if (defBank1.current) {
                                defBank1.current.checked = true;
                            }
                        }
                    }
                    if (banks[1]) {
                        bankAccId2 = banks[1].bankAccountId;
                        onClickAddBank();
                        setFinInstName1(banks[1].financialInstitutionName);
                        setFinAddInstNumber1(banks[1].financialInstitutionNumber);
                        setFinAddInstAddress1(banks[1].financialInstitutionAddress);
                        setFinAddInstRawAddress1(banks[1].rawAddress);
                        onCountryChange1({ Key: banks[1].country, Value: banks[1].country });
                        setStateObj1({ Key: banks[1].state, Value: banks[1].state });
                        setCity1(banks[1].city);
                        setCurrencyObj1({ Key: banks[1].currency, Value: banks[1].currency });
                        setTypesObj1({ key: banks[1].transferType, value: banks[1].transferType });
                        setBankAccNo1(banks[1].accountNumber);
                        setBankReceiptName1(banks[1].receipientsName);
                        setBankTransit1(banks[1].transit);
                        setABARouteNo1(banks[1].abaRoutingNumber);
                        setPostalCode1(banks[1].zipCode);
                        if (banks[1].isAutoDebit == "Y") {
                            if (autoDebitRef2.current) {
                                autoDebitRef2.current.checked = true;
                            }
                        }
                        if (banks[1].isDefault == "Y") {
                            if (defBank2.current) {
                                defBank2.current.checked = true;
                            }
                        }
                    }
                }

            }
        }
    }

    let onClickAddBank = () => {
        setSBankVisible("block");
        setAddBank("none");
    }

    let onClickRemoveBank = () => {
        if(bankAccId2){
            Confirm.show("Confirm", "Are you sure. Do you want remove the Bank Details?", [{
                label: "Yes", callback: function () {
                    //let attachId = getAttachmentId(fType);
                    // BusyLoader.showLoader();
                    let delBankAPI:string = "api/organization/finance/deleteBankAccount?bankAccountId="+bankAccId2+"&saasOrgId="+CommonUtil.getSaasOrgId();
                    console.log(delBankAPI);
                    let obj:any = {};
                    NetworkManager.sendJsonRequest(delBankAPI,"","","DELETE",true,(dataObj:any)=>{
                        console.log(dataObj);
                        if(dataObj) {
                            var restData = CommonUtil.parseData(dataObj);
                            if (restData) {
                                console.log(restData);
                                ToastManager.showToast("Successfully Removed Bank Details", "Info");
                                setSBankVisible("none");
                                setAddBank("block");
                                setFinInstName1("");
                                setFinAddInstNumber1("");
                                setFinAddInstAddress1("");
                                setFinAddInstRawAddress1("");
                                onCountryChange1(null);
                                setStateObj1(null);
                                setCity1("");
                                setCurrencyObj1(null);
                                setTypesObj1(null);
                                setBankAccNo1("");
                                setBankReceiptName1("");
                                setBankTransit1("");
                                setABARouteNo1("");
                                setPostalCode1("");
                                bankAccId2 = "";
                            }
                        }

                    },onLoginError);
                }
            }, { label: "No" }]);
        }else{
            setSBankVisible("none");
            setAddBank("block");
        }
    }

    let onChangeInsuranceDate = (dateValue: any) => {
        //console.log(dateValue);
        let strDate: string = CommonUtil.getDate(dateValue);
        setInsurenceDate(strDate);
    }

    let onChangeSignatureDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue);
        setSignatureDate(strDate);
    }

    let onShowCreditCard = (flag: boolean) => {
        console.log("flag:" + flag);
        setCardLoad(flag);
    }

    let onClickGenEdit = () => {
        setGenEdit(true);
        setGeneralClass("enable-form");
    }

    let onClickGenDisable = () => {
        setGenEdit(false);
        setGeneralClass("disable-form");
    }

    let onClickInsurenceEdit = () => {
        setInsurenceEdit(true);
        setInsurenceClass("enable-form");
    }

    let onClickInsurenceDisable = () => {
        setInsurenceEdit(false);
        setInsurenceClass("disable-form");
    }

    let onClickTaxEdit = () => {
        setTaxEdit(true);
        setTaxClass("enable-form");
    }

    let onClickTaxDisable = () => {
        setTaxEdit(false);
        setTaxClass("disable-form");
    }

    let onClickBankEdit = () => {
        setBankEdit(true);
        setBankClass("enable-form");
    }

    let onClickBankDisable = () => {
        setBankEdit(false);
        setBankClass("disable-form");
    }

    let onClickBankEdit1 = () => {
        setBankEdit1(true);
        setBankClass1("enable-form");
    }

    let onClickBankDisable1 = () => {
        setBankEdit1(false);
        setBankClass1("disable-form");
    }

    let onClickCardEdit = () => {
        setCardEdit(true);
        setCardClass("enable-form");
    }

    let onClickCardDisable = () => {
        setCardEdit(false);
        setCardClass("disable-form");
    }

    let onClickGenSave = () => {
        let validate: boolean = false;
        if (isCanadaVisible == "block") {
            let isgst: boolean = CommonUtil.isMandatory(gstRef);
            let isqst: boolean = CommonUtil.isMandatory(qstRef);
            let isw8: boolean = CommonUtil.isMandatory(w8tRef);
            if (isgst && isqst) {//&& isw8 && && w8Number
                validate = true;
            }
        } else if (isUSVisible == "block") {
            let isw9: boolean = CommonUtil.isMandatory(w9tRef);
            if (isw9) {
                validate = true;
            }
        }
        let isBnNum: boolean = CommonUtil.isMandatory(bntRef);
        if (validate && isBnNum) {
            let restObj: any = {};
            restObj.businessNumber = bnNumber;
            restObj.gstOrHstNumber = gstNumber;
            restObj.qstOrPstNumber = qstNumber;

            restObj.w8Number = w8Number;
            restObj.w9Number = w9Number;

            //console.log(restObj);

            var saveFinance: string = "api/organization/finance/saveFinanceDetails?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(saveFinance, restObj, "", "POST", true, onSaveFinanceDetails, onLoginError);
        }else{
            ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"), "Error");
        }
    }

    let onSaveFinanceDetails = (dataObj: any) => {
        //console.log(dataObj);
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let strMsg = CommonUtil.getMessageText(Constants.FINANCE_INFO_UPDATE, "Financial Information updated Successfully");
                ToastManager.showToast(strMsg, strInfo);
                setSectionCompleted("financeInformation", updateProfile);
            }
        }
        onClickGenDisable();
    }

    let onClickInsuranceSave = () => {
        let isIsureres: boolean = false;//CommonUtil.isMandatory(insNameRef);
        let isIsuNo: boolean = false;//CommonUtil.isMandatory(insNoRef);
        let isCivil: boolean = false;//CommonUtil.isMandatory(insCivilRef);
        // let isAssetCov: boolean = CommonUtil.isMandatory(insAssRef);

        //let isCarrierCover: boolean = CommonUtil.isMandatory(insCarrierCoverRef);
        //let isContientCover: boolean = CommonUtil.isMandatory(insContinentRef);

        let flag: boolean = false;
        // if (isIsureres && isIsuNo && isCivil) { //&& isCarrierCover && isContientCover
        let isCarrierCover: boolean = CommonUtil.isMandatory(insCarrierCoverRef);

        if(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER){
            if(isCarrierCover && Number(insCarrierCover)>=Number(CommonUtil.MIN_OWNER_COVERAGE)){
                //flag = true;
            }else{
                let strInsure = "Required coverage by Carriers for Rental/Repositioning value is greater or equal to 1000000";//CommonUtil.getMessageText(Constants.FINANCE_INSURENCE_UPDATE, "Required coverage by Carriers for Rental/Repositioning value is greater or equal to 1000000");
                ToastManager.showToast(strInsure, "Error");
                return;
            }
        }
        if ((CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER) || (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)) {
            let isAssetCov: boolean = CommonUtil.isMandatory(insAssRef);
            isIsureres = CommonUtil.isMandatory(insNameRef);
            isIsuNo = CommonUtil.isMandatory(insNoRef);
            isCivil = CommonUtil.isMandatory(insCivilRef);


            if (isAssetCov && isIsureres && isIsuNo && isCivil) {

                flag = true;

            }

        } else if ((CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER) || (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)) {
            let isCarrierCover: boolean = CommonUtil.isMandatory(insCarrierCoverRef);
            let isContientCover: boolean = CommonUtil.isMandatory(insContinentRef);
            isIsureres = CommonUtil.isMandatory(insNameRef);
            isIsuNo = CommonUtil.isMandatory(insNoRef);
            isCivil = CommonUtil.isMandatory(insCivilRef);

            if (isIsureres && isIsuNo && isCarrierCover && isContientCover) {
                flag = true;
            }
        }
        if (flag) {
            let restObj: any = {};
            restObj.insuranceExpirationDate = insurenceDate;//insureDateRef.current?
            restObj.insurersName = insurerName;
            restObj.insurersNumber = insurerNumber;
            restObj.minCoverageCivil = insCivil;
            restObj.minCoverageNonOwnedAssets = insAssCov;
            restObj.carrierRequiredCoverage = insCarrierCover;
            restObj.contingentCargo = continentCargo;
            restObj.saasOrgId = CommonUtil.getSaasOrgId();

            console.log(restObj);

            var saveInsurence: string = "api/organization/finance/saveInsuranceDetails?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(saveInsurence, restObj, "", "POST", true, onSaveInsurenceDetails, onLoginError);
        }else{
            ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"), "Error");
        }
        //}
    }

    let onSaveInsurenceDetails = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let strInsure = CommonUtil.getMessageText(Constants.FINANCE_INSURENCE_UPDATE, "Financial Insurance updated Successfully");
                ToastManager.showToast(strInsure, strInfo);
                setSectionCompleted("insuranceDetails", updateProfile);
            }
        }
        onClickInsurenceDisable();
    }

    let onClickTaxSave = () => {
        let isreason: boolean = CommonUtil.isMandatory(reasonRef);
        (taxStateObj) ? setTaxStateClass("select-box-valid") : setTaxStateClass("select-box-invalid");
        (statusObj) ? setTaxStateClass("select-box-valid") : setTaxStateClass("select-box-invalid");
        if (isreason && taxStateObj && statusObj) {
            let restObj: any = {};
            restObj.taxExemptionReason = reason;
            restObj.taxSignatureDate = signatureeDate;
            restObj.taxState = taxStateObj ? taxStateObj.Key : "";
            restObj.taxStatus = statusObj ? statusObj.Key : "";

            var saveTax: string = "api/organization/finance/saveTaxDetails?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(saveTax, restObj, "", "POST", true, onSaveTaxDetails, onLoginError);

            // console.log(restObj);
        }
        else{
            ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"), "Error");
        }

    }

    let onSaveTaxDetails = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let strFinanceTax = CommonUtil.getMessageText(Constants.FINANCE_TAX_UPDATE, "Financial Tax Exemption updated Successfully");
                ToastManager.showToast(strFinanceTax, strInfo);
            }
        }
        onClickTaxDisable();
    }

    let onClickBankSave = () => {
        let banks: any = [];
        let bank: any = {};
        bank.abaRoutingNumber = abaRouteNo;
        bank.accountNumber = bankAccNo;
        //bank.businessLocation = countryObj?countryObj.Key:"";
        bank.city = city;
        bank.country = countryObj ? countryObj.Key : "";
        bank.currency = currencyObj ? currencyObj.Key : "";
        bank.financialInstitutionAddress = finAddInstRawAddress;//finAddInstAddress;
        bank.rawAddress = finAddInstRawAddress;
        bank.financialInstitutionName = finInstName;
        bank.financialInstitutionNumber = finAddInstNumber;
        bank.isAutoDebit = (autoDebitRef1.current?.checked == true) ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
        bank.isDefault = (defBank1.current?.checked == true) ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
        bank.receipientsName = bankReceiptName;
        bank.transit = bankTransit;
        bank.zipCode = postalcode;
        bank.state = stateObj ? stateObj.Key : "";
        bank.transferType = typeObj ? typeObj.key : "";

        banks.push(bank);

        /*if (isFinInstName && isFinInsNum && isFinInsAddr && countryObj && stateObj && isPostlCode && currencyObj && isBankReceipt && isBankTransit && isAbRouteNo) {
            bankValidate = true;
        }*/
        console.log(banks);
        let flag:boolean = false;
        if (finInstName && finAddInstAddress && countryObj && currencyObj && stateObj && postalcode &&  bankReceiptName && bankAccNo) {
            if(countryObj){
                if(countryObj.Key == CommonUtil.US){
                    if(abaRouteNo){
                        flag = true;
                    }
                }else if(countryObj.Key == CommonUtil.CANADA){
                    if(finAddInstNumber && bankTransit){
                        flag = true;
                    }
                }

            }
            if(flag){
                let method: string = "POST";
                let api: string = "api/organization/finance/addBankAccount?saasOrgId=" + CommonUtil.getSaasOrgId();;
                if (bankAccId1) {
                    bank.bankAccountId = bankAccId1;
                    method = "PUT";
                    api = "api/organization/finance/editBankAccount?saasOrgId=" + CommonUtil.getSaasOrgId();
                }
                var bankInfo: string = api;
                NetworkManager.sendJsonRequest(bankInfo, bank, "", method, true, onBankInfoSaved, onLoginError);
            }

        }else{
            ToastManager.showToast(CommonUtil.getMessageText(Constants.PLEASE_ENTER_MANDATORY_FIELDS, "Please enter mandatory fields"), "Error");
        }


        // restObj.bankAccounts = banks;

    }

    let onBankInfoSaved = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let strBank = CommonUtil.getMessageText(Constants.BANK_UPDATE_SUCCESS, "Bank Info updated Successfully");
                ToastManager.showToast(strBank, strInfo);
                bankAccId1 = restData.bankAccountId;
                setSectionCompleted("bankDetails", updateProfile);
            }
        }
        onClickBankDisable();
    }

    let onClickBankSave1 = () => {
        let bank1: any = {};
        bank1.abaRoutingNumber = abaRouteNo1;
        bank1.accountNumber = bankAccNo1;
        //bank1.businessLocation = countryObj1?countryObj1.Key:"";
        bank1.city = city1;
        bank1.country = countryObj1 ? countryObj1.Key : "";
        bank1.currency = currencyObj1 ? currencyObj1.Key : "";
        bank1.financialInstitutionAddress = finAddInstRawAddress1;//finAddInstAddress1;
        bank1.rawAddress = finAddInstRawAddress1;
        bank1.financialInstitutionName = finInstName1;
        bank1.financialInstitutionNumber = finAddInstNumber1;
        bank1.isAutoDebit = (autoDebitRef2.current?.checked == true) ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;
        bank1.receipientsName = bankReceiptName1;
        bank1.transit = bankTransit1;
        bank1.zipCode = postalcode1;
        bank1.state = stateObj1 ? stateObj1.Key : "";
        bank1.transferType = typeObj1 ? typeObj1.key : "";
        bank1.isDefault = (defBank2.current?.checked == true) ? CommonUtil.CHECKED : CommonUtil.UN_CHECKED;

        let flag:boolean = false;
        if (finInstName1 && finAddInstAddress1  && currencyObj1 && stateObj1 && postalcode1 && bankReceiptName1 && bankAccNo1) {
            if(countryObj1){
                if(countryObj1.Key == CommonUtil.US){
                    if(abaRouteNo1){
                        flag = true;
                    }
                }else if(countryObj1.Key == CommonUtil.CANADA){
                    if(finAddInstNumber1 && bankTransit1){
                        flag = true;
                    }
                }

            }
            if(flag){
                let method: string = "POST";
                let api: string = "api/organization/finance/addBankAccount?saasOrgId=" + CommonUtil.getSaasOrgId();
                if (bankAccId2) {
                    bank1.bankAccountId = bankAccId2;
                    method = "PUT";
                    api = "api/organization/finance/editBankAccount?saasOrgId=" + CommonUtil.getSaasOrgId();
                }
                var bankInfo: string = api;

                // var bankInfo: string = "api/organization/finance/addBankAccount?saasOrgId=" + localStorage.getItem("saasorgId");
                NetworkManager.sendJsonRequest(bankInfo, bank1, "", method, true, onBankInfoSaved1, onLoginError);
            }

        }
        //banks.push(bank1);
    }

    let onBankInfoSaved1 = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let strBank = CommonUtil.getMessageText(Constants.BANK_UPDATE_SUCCESS, "Bank Info updated Successfully");
                ToastManager.showToast(strBank, strInfo);
                bankAccId2 = restData.bankAccountId;
                setSectionCompleted("bankDetails", updateProfile);
            }
        }
        onClickBankDisable1();
    }

    let getBambooraCode = (token:string,usCode:string,successCallBack:any,errorCallBack:any)=>{
        var myHeaders = new Headers();
        myHeaders.append("Authorization", usCode);//"Passcode MzYxNDIwMDAzOkM4ODYzQTgzRkM1MTQzRDc4QjZGNTg1NkE0QzJERDAz"
        myHeaders.append("Content-Type", "application/json");
        //myHeaders.append("Cookie", "TS013f0544=01c54dc23504302fe4e2b829c90ceda9ebe9148968d09fce2b3096b5b294171fee41c2db7746c9205bb94faf0c1f99377c922f5afd; TS013f0544028=018238f46cad86f2d1f03fc9bda5e35563fd5ad37cf1340ed2d8530e304513a1f7c0bb111ea49eacb576aa007cb220dcbc1c74d194");

        var usRestObj:any = {};
        usRestObj.token = {};
        usRestObj.token.name = CreditCardDetails.getCardHoderName();
        usRestObj.token.code = token;//res.token;

        var raw = JSON.stringify(usRestObj);
        var requestOptions:any = {method: 'POST', headers: myHeaders, body: raw, redirect: 'follow'};

        fetch("https://api.na.bambora.com/v1/profiles", requestOptions)
            .then(response => response.text())
            .then(result => successCallBack(result))
            .catch((error) => {
                console.log(error, 'error')
                BusyLoader.hideLoader()
                errorCallBack(error)
            })
    }


    let onClickSaveCreditCard =  () => {
       // let response = await CreditCardDetails.getTokenInfo()
       // console.log(CreditCardDetails.getTokenInfo());
        let flag:boolean = false;
        BusyLoader.showLoader();
       CreditCardDetails.getTokenInfo((results: any) => {
            //console.log(results);
           if(!flag){
               if(results.length == 2){
                   flag = true;
               }
               let restObj: any = {};

               console.log(results);
               if(results?.length > 0){
                let errorMsg:string = ""; 
                try{
                  results.map((error:any)=>{
                    if(error?.error?.message){
                      errorMsg = error.error.message
                    }
                  })
                  if(errorMsg){
                    ToastManager.showToast(errorMsg, "Error");
                    BusyLoader.hideLoader();
                    return;
                  }                 
                }catch(e){}
                
               }
               if (results && results[0] && results[0].token && results[1] && results[1].token) {
                   let restObj: any = {};
                   restObj.cardHolderName = CreditCardDetails.getCardHoderName();
                   restObj.creditCardExpiryMonth = results[0].expiryMonth ? results[0].expiryMonth : "";
                   restObj.creditCardExpiryYear = results[0].expiryYear ? results[0].expiryYear : "";
                   restObj.creditCardLast4 = results[0].last4 ? results[0].last4 : "";
                   restObj.ccId = ccId;
                   getBambooraCode(results[0].token,CommonUtil.US_PASSCODE,(dataObj:any)=>{
                       if(dataObj){
                           let restData = CommonUtil.parseData(dataObj);
                           if(restData &&  restData.customer_code){
                               restObj.creditCardTokenUs = restData.customer_code;
                               getBambooraCode(results[1].token,CommonUtil.CANADA_PASSCODE,(dataObj:any)=>{
                                   let canadaData = CommonUtil.parseData(dataObj);
                                   if(canadaData && canadaData.customer_code){
                                       restObj.creditCardTokenCanada = canadaData.customer_code;
                                       let method: string = "POST";
                                       let cardApi: string = "api/organization/finance/addCCData?saasOrgId=" + CommonUtil.getSaasOrgId();
                                       if (ccId) {
                                           method = "PUT";
                                           cardApi = "api/organization/finance/editCCData?saasOrgId=" + CommonUtil.getSaasOrgId();
                                       }
                                       // BusyLoader.hideLoader();
                                       console.log(restObj, cardApi);
                                       var saveCredicard: string = cardApi;//"api/organization/finance/addCCData?saasOrgId="+localStorage.getItem("saasorgId");
                                       NetworkManager.sendJsonRequest(saveCredicard, restObj, "", method, false, onSaveCCData, onLoginError);
                                   }else{
                                       ToastManager.showToast(canadaData.message, "Error");
                                       BusyLoader.hideLoader();
                                   }
                               },onLoginError);
                           }else{
                               ToastManager.showToast(restData.message, "Error");
                               BusyLoader.hideLoader();
                           }
                       }else{
                           BusyLoader.hideLoader();
                       }
                   },onLoginError);
                   /* let usCodeResponse = await getBambooraCode(CommonUtil.US_PASSCODE,results.US.token);
                    console.log(usCodeResponse);*/
                   //showBamnooraCode(results.US.token,CommonUtil.US_PASSCODE);
               }else{
                   BusyLoader.hideLoader();
               }
           }

        });
       // BusyLoader.showLoader();
       // let tokenInfo = await CreditCardDetails.getTokenInfo();
        //console.log(tokenInfo);
        /*CreditCardDetails.getCardDetails((res: any) => {
            console.log(res);
            BusyLoader.hideLoader();
            let banks: any = [];
            let restObj: any = {};

            restObj.cardHolderName = CreditCardDetails.getCardHoderName();
            if (res && res.US && res.US.token && res.CANADA.token) {
                restObj.creditCardExpiryMonth = res.US.expiryMonth ? res.US.expiryMonth : "";
                restObj.creditCardExpiryYear = res.US.expiryYear ? res.US.expiryYear : "";
                restObj.creditCardLast4 = res.US.last4 ? res.US.last4 : "";
                restObj.ccId = ccId;
                if(res.US.token){

                    getBambooraCode(res.US.token,CommonUtil.US_PASSCODE,(dataObj:any)=>{
                        console.log(dataObj);
                        if(dataObj){
                            let restData = CommonUtil.parseData(dataObj);
                            if(restData &&  restData.customer_code){
                                restObj.creditCardTokenUs = restData.customer_code;

                                getBambooraCode(res.CANADA.token,CommonUtil.CANADA_PASSCODE,(dataObj:any)=>{
                                    let canadaData = CommonUtil.parseData(dataObj);
                                    if(canadaData && canadaData.customer_code){
                                        restObj.creditCardTokenCanada = canadaData.customer_code;

                                        let method: string = "POST";
                                        let cardApi: string = "api/organization/finance/addCCData?saasOrgId=" + CommonUtil.getSaasOrgId();
                                        if (ccId) {
                                            method = "PUT";
                                            cardApi = "api/organization/finance/editCCData?saasOrgId=" + CommonUtil.getSaasOrgId();
                                        }
                                        // BusyLoader.hideLoader();
                                        console.log(restObj, cardApi);
                                        var saveCredicard: string = cardApi;//"api/organization/finance/addCCData?saasOrgId="+localStorage.getItem("saasorgId");
                                        NetworkManager.sendJsonRequest(saveCredicard, restObj, "", method, false, onSaveCCData, onLoginError);
                                    }else{
                                        BusyLoader.hideLoader();
                                        ToastManager.showToast(canadaData.message, "Error");
                                    }
                                },onLoginError);
                            }else{
                                ToastManager.showToast(restData.message, "Error");
                                BusyLoader.hideLoader();
                            }
                        }else{
                            BusyLoader.hideLoader();
                        }
                    },onLoginError);
                }else{
                    BusyLoader.hideLoader();
                }

                //restObj.creditCardTokenCanada = res.token ? res.token : "";



            }else{
                BusyLoader.hideLoader();
            }
        });*/
    }

    let onSaveCCData = (dataObj: any) => {
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let strCreditCard = CommonUtil.getMessageText(Constants.CARD_UPDATE_SUCCESS, "Credit Card Info updated Successfully");
                ToastManager.showToast(strCreditCard, strInfo);
                setSectionCompleted("creditCardDetails", updateProfile);
            }
        }
        getCrediCardDetails();
        onClickCardDisable();
    }

    let onLoginError = (err: any) => {
        BusyLoader.hideLoader();
        console.log(err);
    }
    
    return (
        <>
            <input type="file" ref={fileref} className="custom-file-input" multiple={false} id="inputGST"
                accept="application/pdf"
                style={{ "display": "none" }} onChange={(e) => {
                    onSelectFiles(e)
                }}></input>

            <div className="container-fluid p-0">
                {completedStatus["financeInformation"] && <RibbonTag/>}
                <section>                
                    {/* <div className={"row"}>
                        <div className="col-md-12 col-12  mb-3 text-right">
                            <button type="button" className="btn btn-danger btn-save js-save"
                                style={{ display: (genEdit ? "" : "none") }} onClick={onClickGenDisable}>{CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}</button>
                            &nbsp;
                            <button type="button" className="btn btn-primary btn-save js-save"
                                style={{ display: (genEdit ? "" : "none") }} onClick={onClickGenSave}>{CommonUtil.getMessageText(Constants.SAVE, "Save")}</button>
                            &nbsp;
                            <button type="button" className="btn btn-warning btn-edit js-edit"
                                style={{ display: (genEdit ? "none" : "") }} onClick={onClickGenEdit}>{CommonUtil.getMessageText(Constants.EDIT, "Edit")}</button>
                        </div>
                    </div> */}
                    <div className={"row"}>
                        <div className={"genaralClass"}>
                            <form className="is-readonly">                                
                                <div className="row">
                                    <div className="col-md-12  col-12  mb-3"><h6>{CommonUtil.getMessageText(Constants.FINANCE_INFO, "Finance Information")}<span style={{color: "red"}}>&nbsp;*</span></h6></div>
                                    <div className="col-md-4 col-12" style={{ display: (isCanadaVisible) }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CST_HST_NUMBER, "CST/HST Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={gstRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.ENTER_GST_NUMBER, "You must enter a GST/HST Number")} value={gstNumber}
                                                    onChange={(e) => {
                                                        setGSTNumber(e.currentTarget.value);
                                                        CommonUtil.isMandatory(gstRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" style={{ display: (isCanadaVisible) }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.QST_PST_NUMBER, "QST/PST Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={qstRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.ENTER_QST_PST_NUMBER, "You must enter a QST/PST Number")} value={qstNumber}
                                                    onChange={(e) => {
                                                        setQSTNumber(e.currentTarget.value);
                                                        CommonUtil.isMandatory(qstRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.BUSINESS_NUMBER_EIN, "Business Number (EIN in Us)")}</label>
                                            <div className="col-sm-12">
                                                <input ref={bntRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.BUSINESS_NUMBER, "Business Number")} value={bnNumber}
                                                    onChange={(e) => {
                                                        setBNNumber(e.currentTarget.value);
                                                        CommonUtil.isMandatory(bntRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" style={{ display: (isCanadaVisible == "block" ? "" : "none") }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label"><a href="/docs/FP-2506(2021-04) English.pdf" className="hyperlinktab" target={"_blank"}>
                                                <i className="fa fa-download"></i> {CommonUtil.getMessageText(Constants.FP_2506_FORM_ENGLISH, "Download FP2506 English Form")}</a><br />
                                                <small>{CommonUtil.getMessageText(Constants.HEADQUARTER_QUEBEC, "(For companies with their headquarters in Quebec)")}</small></label>
                                            <label className="col-sm-12 col-form-label"><a href="/docs/FP-2506(2021-04) French.pdf" className="hyperlinktab" target={"_blank"}>
                                                <i className="fa fa-download"></i> {CommonUtil.getMessageText(Constants.FP_2506_FORM_FRENCH, "Download FP2506 French Form")}</a><br />
                                                <small>{CommonUtil.getMessageText(Constants.HEADQUARTER_QUEBEC, "(For companies with their headquarters in Quebec)")}</small></label>
                                            <label className="col-sm-12 col-form-label"><a href="/docs/GST506 vHub - Final.pdf" className="hyperlinktab" target={"_blank"}>
                                                <i className="fa fa-download"></i> {CommonUtil.getMessageText(Constants.GST506_FORM, "Download GST506 Form")}</a><br />
                                                <small>{CommonUtil.getMessageText(Constants.HEADQUARTER_QUEBEC_OUTSIDE, "(For companies with their headquarters outside of Quebec)")}</small></label>

                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" ref={dropGST} style={{ display: (isCanadaVisible == "block" ? "" : "none") }}>
                                        <div className="col-sm-12 cus-input-file-text financial-browse-button"
                                            onClick={() => { uploadFinanceDocuments(CommonUtil.GST506_DOC_TYPE) }}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                                            <button type="button" className="btn btn-outline-primary financial-button">{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                        </div>
                                        <div className="col-sm-12 cus-input-file p-0">

                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 mt-2" style={{ display: (isCanadaVisible == "block" ? "" : "none") }}>
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="gallery flex documents-gallary">
                                                    {attachDocs.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.FP2506_DOC_TYPE || item.documentType == CommonUtil.GST506_DOC_TYPE)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                            onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ marginLeft: "-10px" }} />
                                </div>

                                <div className="row" style={{ display: (isCanadaVisible == "block") ? "none" : "none" }}>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.W8_NUMBER, "W-8 Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={w8tRef} type="text" className="form-control"
                                                    placeholder="W-8 Number" value={w8Number}
                                                    onChange={(e) => {
                                                        setW8Number(e.currentTarget.value);
                                                        CommonUtil.isMandatory(w8tRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ display: (isCanadaVisible == "block") ? "none" : "none" }}>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label"><a href="/docs/fw8ben.pdf" className="hyperlinktab" target="_blank">
                                                <i className="fa fa-download"></i> {CommonUtil.getMessageText(Constants.DOWNLOAD_W8_FORM, "Download W8 Form")}</a><br /><small></small></label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" ref={dropW8}>
                                        <div className="col-sm-12 cus-input-file-text financial-browse-button"
                                            onClick={() => { uploadFinanceDocuments(CommonUtil.W8_DOC_TYPE) }}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                                            <button type="button" className="btn btn-outline-primary financial-button" >{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                        </div>
                                        <div className="col-sm-12 cus-input-file p-0"></div>
                                    </div>
                                    <div className="col-md-4 col-12 mt-2">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="gallery flex documents-gallary">
                                                    {attachDocs.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.W8_DOC_TYPE)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ marginLeft: "-10px" }} />
                                </div>
                                <div className="row" style={{ display: (isUSVisible == "block") ? "" : "none" }}>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.W9_NUMBER, "W-9 Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={w9tRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.W9_NUMBER, "W-9 Number")} value={w9Number}
                                                    onChange={(e) => {
                                                        setW9Number(e.currentTarget.value);
                                                        CommonUtil.isMandatory(w9tRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ display: (isUSVisible == "block") ? "" : "none" }}>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label"><a className="hyperlink hyperlinktab" href="/docs/fw9.pdf" target="_blank">
                                                <i className="fa fa-download"></i> {CommonUtil.getMessageText(Constants.DOWNLOAD_W9_FORM, "Download W9 Form")}</a><br /><small></small></label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" ref={dropW9}>
                                        <div className="col-sm-12 cus-input-file-text financial-browse-button"
                                            onClick={() => { uploadFinanceDocuments(CommonUtil.W9_DOC_TYPE) }}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                                            <button type="button" className="btn btn-outline-primary financial-button">{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                        </div>
                                        <div className="col-sm-12 cus-input-file p-0">

                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 mt-2">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="gallery flex documents-gallary">
                                                    {attachDocs.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.W9_DOC_TYPE)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <hr style={{ marginLeft: "-10px" }} /> */}
                                </div>
                                   
                            </form>
                        </div>
                    </div>
                    <div className="text-end">
                                <button type="button" className="btn btn-success" onClick={onClickGenSave}>Save</button>                              
                                 </div>
                </section>
                {completedStatus["insuranceDetails"] && <RibbonTag/>}
                <section>                
                    <div className="row">
                        <div className="col-md-6  col-12  mb-3"><h6>{CommonUtil.getMessageText(Constants.INSURANCE_DETAILS, "Insurance Details")}<span style={{color: "red"}}>&nbsp;*</span></h6></div>
                        {/* <div className="col-md-6 col-12  mb-3 text-right">
                            <button type="button" className="btn btn-danger btn-save js-save"
                                style={{ display: (insurenceEdit ? "" : "none") }}
                                onClick={onClickInsurenceDisable}>{CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-primary btn-save js-save"
                                style={{ display: (insurenceEdit ? "" : "none") }}
                                onClick={onClickInsuranceSave}>{CommonUtil.getMessageText(Constants.SAVE, "Save")}</button>
                            &nbsp;
                            <button type="button" className="btn btn-warning btn-edit js-edit"
                                style={{ display: (insurenceEdit ? "none" : "") }}
                                onClick={onClickInsurenceEdit}>{CommonUtil.getMessageText(Constants.EDIT, "Edit")}</button>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <form className={"insurenceClass"}>
                                <div className="row">
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.INSURER_NAME, "Insurer's Name")}</label>
                                            <div className="col-sm-12">
                                                <input ref={insNameRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.INSURER_NAME, "Insurer's Name")} value={insurerName}
                                                    onChange={(e) => {
                                                        setInsurereName(e.currentTarget.value);
                                                        CommonUtil.isMandatory(insNameRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.INSURER_NUMBER, "Insurance Policy Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={insNoRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.INSURER_NUMBER, "Insurance Policy Number")} value={insurerNumber}
                                                    onChange={(e) => {
                                                        setInsurereNumber(e.currentTarget.value);
                                                        CommonUtil.isMandatory(insNoRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.INSURER_EXPIRATION, "Policy Expiration Date")}</label>
                                            <div className="col-sm-12">
                                                <Flatpickr placeholder={CommonUtil.getMessageText(Constants.INSURER_EXPIRATION, "Policy Expiration Date")} ref={insureDateRef} options={dateOptions}
                                                    onChange={onChangeInsuranceDate} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    {(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)&&
                                    <><div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CIVIL_COVERAGE, "Insurance Civil Liability Coverage")}</label>
                                            <div className="col-sm-12">
                                                <input ref={insCivilRef} type="number"
                                                    className="form-control clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.CIVIL_COVERAGE, "Insurance Civil Liability Coverage")} value={insCivil}
                                                    onChange={(e) => {
                                                        setInsCivil(e.currentTarget.value);
                                                        CommonUtil.isMandatory(insCivilRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.NON_OWNED_COVERAGE, "Insurance Non-Owned Asset Coverage Minimum")}</label>
                                            <div className="col-sm-12">
                                                <input ref={insAssRef} type="number" disabled={isInsFieldDisable ? false : true}
                                                    className="form-control clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.NON_OWNED_COVERAGE, "Insurance Non-Owned Asset Coverage Minimum")} value={insAssCov}
                                                    onChange={(e) => {
                                                        setInsAssCover(e.currentTarget.value);
                                                        CommonUtil.isMandatory(insAssRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div></>}
                                    {(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) && 
                                    <><div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CARRIERS_COVERAGE, "Required Civil Liability Coverage by the Carrier for Rental/Repositioning")}</label>
                                            <div className="col-sm-12">
                                                <input ref={insCarrierCoverRef} type="number" disabled={(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER) || (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) ? false : true}
                                                    className="form-control clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.CARRIERS_COVERAGE, "Required Civil Liability Coverage by the Carrier for Rental/Repositioning")} value={insCarrierCover}
                                                    onChange={(e) => {
                                                        setCarrierCover(e.currentTarget.value);
                                                        CommonUtil.isMandatory(insCarrierCoverRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CONTINGENT_CARGO, "Contingent Cargo")}</label>
                                            <div className="col-sm-12">
                                                <input ref={insContinentRef} type="number" disabled={((CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER) || (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)) ? false : true}
                                                    className="form-control" placeholder={CommonUtil.getMessageText(Constants.CONTINGENT_CARGO, "Contingent Cargo")} value={continentCargo}
                                                    onChange={(e) => {
                                                        setContinentCargo(e.currentTarget.value);
                                                        CommonUtil.isMandatory(insContinentRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    </>}
                                    <div className="col-md-4 col-12"></div>
                                    <div className="col-md-4 col-12"></div>
                                    <div className="col-md-4 col-12" ref={dropInsurance} >
                                        <div className="col-sm-12 cus-input-file-text financial-browse-button"
                                            onClick={() => { uploadFinanceDocuments(CommonUtil.INSUR_DOC_TYPE) }}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                                            <button type="button" className="btn btn-outline-primary financial-button">{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                        </div>
                                        <div className="col-sm-12 cus-input-file p-0">

                                        </div>

                                    </div>
                                    <div className="col-md-7 col-12 mt-2">
                                        <div className="form-group row">

                                            <div className="col-sm-12">
                                                <div className="gallery flex documents-gallary">
                                                    {attachDocs.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.INSUR_DOC_TYPE)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                            onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>


                        </div>


                    </div>
                    <div className="text-end">
                                <button type="button" className="btn btn-success"onClick={onClickInsuranceSave}>Save</button>                              
                                 </div>
                </section>
                {completedStatus["taxExempted"] && <RibbonTag/>}
                <section style={{ display: isUSVisible }}>
                    <div className="row">
                        <div className="col-md-6  col-12  mb-3"><h6>{CommonUtil.getMessageText(Constants.TAX_EXEMPTION, "Tax Exemption")}</h6></div>
                        {/* <div className="col-md-6 col-12  mb-3 text-right">
                            <button type="button" className="btn btn-danger btn-save js-save"
                                style={{ display: (taxEdit ? "" : "none") }} onClick={onClickTaxDisable}>{CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-primary btn-save js-save"
                                style={{ display: (taxEdit ? "" : "none") }} onClick={onClickTaxSave}>{CommonUtil.getMessageText(Constants.SAVE, "Save")}</button>
                            &nbsp;
                            <button type="button" className="btn btn-warning btn-edit js-edit"
                                style={{ display: (taxEdit ? "none" : "") }} onClick={onClickTaxEdit}>{CommonUtil.getMessageText(Constants.EDIT, "Edit")}</button>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <form className={"taxClass"}>
                                <div className="row">
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EXEMPTION_REASON, "Exemption Reason")}</label>
                                            <div className="col-sm-12">
                                                <input ref={reasonRef} type="text"
                                                    className="form-control clsFormInputControl" placeholder={CommonUtil.getMessageText(Constants.EXEMPTION_REASON, "Exemption Reason")} value={reason}
                                                    onChange={(e) => {
                                                        setReason(e.currentTarget.value);
                                                        CommonUtil.isMandatory(reasonRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STATE, "State")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={taxStates} selectedValue={taxStateObj}
                                                    handleChange={onTaxStateChange} value={"Key"} label={"Value"}
                                                    className={taxStateClass} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.SIGNATURE_DATE, "Signature Date")}</label>
                                            <div className="col-sm-12">
                                                <Flatpickr placeholder="Signature Date" ref={signatureDateRef} options={dateOptions}
                                                    onChange={onChangeSignatureDate} className="form-control " />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STATUS, "Status")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp ref={statusRef} dataSource={statues} selectedValue={statusObj}
                                                    handleChange={onStatusChange} value={"Key"} label={"Value"}
                                                    className={taxStatusClass} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">

                                    </div>
                                    <div className="col-md-4 col-12"></div>
                                    <div className="col-md-4 col-12" ref={dropTax} >
                                        <div className="col-sm-12 cus-input-file-text financial-browse-button"
                                            onClick={() => { uploadFinanceDocuments(CommonUtil.TAX_DOC_TYPE) }}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                                            <button type="button" className="btn btn-outline-primary financial-button" >{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                        </div>
                                        <div className="col-sm-12 cus-input-file p-0">

                                        </div>

                                    </div>
                                    <div className="col-md-7 col-12 mt-2">
                                        <div className="form-group row">

                                            <div className="col-sm-12">
                                                <div className="gallery flex documents-gallary">
                                                    {attachDocs.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.TAX_DOC_TYPE)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                            onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>


                        </div>


                    </div>
                    <div className="text-end">
                                <button type="button" className="btn btn-success" onClick={onClickTaxSave}>Save</button>                              
                                 </div>
                </section>
                {completedStatus["bankDetails"] && <RibbonTag/>}
                <section>                
                    <div className="row">
                        <div className="col-md-6  col-12  mb-3"><h6>{CommonUtil.getMessageText(Constants.BANK_DETAILS, "Bank Details")}
                        {(CommonUtil.SELECT_ORG_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.SELECT_ORG_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) ? <span style={{color: "red"}}>&nbsp;*</span> :""}
                            </h6></div>
                        <div className="row" ></div>
                        <div className="row col-md-12 col-12  mb-3" style={{ justifyContent: 'end' }}>
                            <h6 className="dark-grey css-16ihq8s m-0" style={{ paddingTop: "5px", width: "auto", display: addBank }} onClick={onClickAddBank}>
                                <a className="hyperlink">{CommonUtil.getMessageText(Constants.ADD_BANK, "Add Bank")}</a></h6>
                            {/* <button type="button" className="btn btn-danger btn-save js-save" style={{ display: (bankEdit ? "" : "none"), width: 'fit-content' }}
                                onClick={onClickBankDisable}>{CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
                            </button>    &nbsp;
                            <button type="button" className="btn btn-primary btn-save js-save"
                                style={{ display: (bankEdit ? "" : "none"), width: 'fit-content' }} onClick={onClickBankSave}>{CommonUtil.getMessageText(Constants.SAVE, "Save")}
                            </button>    &nbsp;
                            <button type="button" className="btn btn-warning btn-edit js-edit"
                                style={{ display: (bankEdit ? "none" : ""), width: 'fit-content' }} onClick={onClickBankEdit}>{CommonUtil.getMessageText(Constants.EDIT, "Edit")}
                            </button> */}
                            </div>

                        <div className="col-sm-12">
                            <form className={"bankClass"}>
                                <div className="row">

                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.FIN_INSTITUTION_NAME, "Financial Institution Name")}</label>
                                            <div className="col-sm-12">
                                                <input ref={finInsNameRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder="" value={finInstName} maxLength={500}
                                                    onChange={(e) => {
                                                        setFinInstName(e.currentTarget.value);
                                                        CommonUtil.isMandatory(finInsNameRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" id={"divFinInsNum"}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.FIN_INSTITUTION_NUMBER, "Financial Institution Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={finInsNumRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder=""
                                                    value={finAddInstNumber}
                                                    onChange={(e) => {
                                                        setFinAddInstNumber(e.currentTarget.value);
                                                        CommonUtil.isMandatory(finInsNumRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.FIN_INSTITUTION_ADDRESS, "Financial Institution Address")}</label>
                                            <div className="col-sm-12">
                                                <input ref={finInsAddrRef} id="bankAddr" type="text" className="form-control clsFormInputControl"
                                                    placeholder="" value={finAddInstRawAddress}
                                                    onChange={(e) => {
                                                        setFinAddInstRawAddress(e.currentTarget.value);
                                                        setFinAddInstAddress(e.currentTarget.value);
                                                        CommonUtil.isMandatory(finInsAddrRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COUNTRY_LOC, "Country Located at")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={countries} selectedValue={countryObj}
                                                    handleChange={onCountryChange} value={"Key"} label={"Value"}
                                                    className={countryClass} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{provinceText}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={states} selectedValue={stateObj}
                                                    handleChange={onStateChange} value={"Key"} label={"Value"}
                                                    className={provinceClass} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CITY, "City")}</label>
                                            <div className="col-sm-12">
                                                <input ref={cityRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.ENTER_CITY, "You must enter a Routing Number")}
                                                    value={city} onChange={(e) => {
                                                        setCity(e.currentTarget.value);
                                                        CommonUtil.isMandatory(cityRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{postalCodeText}</label>
                                            <div className="col-sm-12">
                                                <input ref={postalCodeRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder={pcTextPlace} value={postalcode}
                                                    onChange={(e) => {
                                                        setPostalCode(e.currentTarget.value);
                                                        CommonUtil.isMandatory(postalCodeRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CURRENCY, "Currency")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={currencies} selectedValue={currencyObj}
                                                    handleChange={onCurrencyChange} value={"Key"} label={"Value"}
                                                    className={currencyClass} disabled={false} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ACC_NUMBER, "Account number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={bankAccRef} type="text" className="form-control clsFormInputControl"
                                                    placeholder="" value={bankAccNo} maxLength={((countryObj && countryObj.Key == CommonUtil.CANADA)?13:15)}
                                                    onChange={(e) => {
                                                        setBankAccNo(e.currentTarget.value);
                                                        CommonUtil.isMandatory(gstRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RECIPIENT_NAME, "Recipient Name")}</label>
                                            <div className="col-sm-12">
                                                <input ref={bankRecRef} type="text"
                                                    className="form-control clsFormInputControl"
                                                    placeholder="" value={bankReceiptName} maxLength={40}
                                                    onChange={(e) => {
                                                        setBankReceiptName(e.currentTarget.value);
                                                        CommonUtil.isMandatory(bankRecRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" id={"divTransit"}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.TRANSIT, "Transit")}</label>
                                            <div className="col-sm-12">
                                                <input ref={bankTransRef} type="text"
                                                    className="form-control clsFormInputControl"
                                                    placeholder="" value={bankTransit} maxLength={5}
                                                       onKeyPress={CommonUtil.allowNumbersDecimal}
                                                    onChange={(e) => {
                                                        setBankTransit(e.currentTarget.value);
                                                        CommonUtil.isMandatory(bankTransRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" id={"divABARouteNum"}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ABA_ROUTING_NUMBER, "ABA Routing Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={abaRouteNoRef} type="text"
                                                    className="form-control clsFormInputControl"
                                                    placeholder="" value={abaRouteNo} maxLength={9}
                                                    onChange={(e) => {
                                                        setABARouteNo(e.currentTarget.value);
                                                        CommonUtil.isMandatory(abaRouteNoRef);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.TYPE, "Type")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={types} selectedValue={typeObj}
                                                    handleChange={onTypeChange} value={"key"} label={"value"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-12" style={{ paddingTop: "20px",display:"none" }}>
                                        <label className="col-sm-12 col-form-label">{''}</label>
                                        <div className="col-sm-12">
                                            <label>{CommonUtil.getMessageText(Constants.SWITF_NOTE, "Note: By using SWIFT, additional processing fees may be applied on the side of your financial institution")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-6 cus-input-switch">
                                        <div className="form-switch ps-0 py-2 mx-2">
                                            <label style={{ marginRight: "50px" }}>{CommonUtil.getMessageText(Constants.AUTO_DEBIT, "Auto Debit")}</label> &nbsp;
                                            <input ref={autoDebitRef1} className="form-check-input" type="checkbox" role="switch" />
                                        </div>

                                    </div>
                                    <div className="col-md-2 col-6 p-0 cus-input-switch">
                                        <div className="form-switch ps-0 py-2 mx-2">
                                            <span style={{ marginRight: "50px" }}>{CommonUtil.getMessageText(Constants.DEFAULT_BANK, "Default Bank")}</span>
                                            <input ref={defBank1} className="form-check-input" type="checkbox"
                                                role="switch" />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12">

                                    </div>
                                    <div className="col-md-12 col-12" style={{ paddingBottom: "26px" }}>
                                        <div className="col-sm-12">
                                            <label>{CommonUtil.getMessageText(Constants.INTERNET_BANK_DETAILS, "Note: You can usually find these details by logging into your internet bank. From there you should easily be able to see the account number of the account you would like to use, the institution number of the bank, and the transit number of the branch")}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label"><a className="hyperlink" href="../../images/pre-authorized payment form_vHub ANG[2914].pdf" download>
                                                <i className="fa fa-download"></i>{CommonUtil.getMessageText(Constants.BANK_DOWNLOAD_FORM, " Download Auto Debit Authorization Form")}</a><br /><small></small></label>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12" ref={dropBank} >
                                        <div className="col-sm-12 cus-input-file-text financial-browse-button"
                                            onClick={() => { uploadFinanceDocuments(CommonUtil.BANK_DOC_TYPE) }}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")}  &nbsp;
                                            <button type="button" className="btn btn-outline-primary financial-button" >{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                        </div>
                                        <div className="col-sm-12 cus-input-file p-0">

                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 mt-2">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="gallery flex documents-gallary">
                                                    {attachDocs.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.BANK_DOC_TYPE)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                            onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row" style={{ display: sBankVisible }}>
                        <hr style={{ paddingTop: "5px" }} />
                        <div className="row">
                            <div className="col-md-6  col-12 "><h6>{CommonUtil.getMessageText(Constants.BANK_DETAILS, "Bank Details")}</h6></div>
                            <div className="row col-md-6 col-12" style={{ justifyContent: 'end' }} >
                                <h6 className="dark-grey css-16ihq8s m-0" style={{ paddingTop: "5px", width: 'auto' }} onClick={onClickRemoveBank}>
                                    <a className="hyperlink">{CommonUtil.getMessageText(Constants.REMOVE_BANK, "Remove Bank")}</a></h6>    <button type="button" className="btn btn-danger btn-save js-save"
                                        style={{ display: (bankEdit1 ? "" : "none"), width: 'fit-content' }} onClick={onClickBankDisable1}>{CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
                                </button>    &nbsp;
                                <button type="button" className="btn btn-primary btn-save js-save"
                                    style={{ display: (bankEdit1 ? "" : "none"), width: 'fit-content' }} onClick={onClickBankSave1}>{CommonUtil.getMessageText(Constants.SAVE, "Save")}
                                </button>    &nbsp;
                                <button type="button" className="btn btn-warning btn-edit js-edit"
                                    style={{ display: (bankEdit1 ? "none" : ""), width: 'fit-content' }} onClick={onClickBankEdit1}>{CommonUtil.getMessageText(Constants.EDIT, "Edit")}
                                </button></div>
                        </div>
                        <div className="col-sm-12">
                            <form className={bankClass1}>
                                <div className="row">

                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.FIN_INSTITUTION_NAME, "Financial Institution Name")}</label>
                                            <div className="col-sm-12">
                                                <input ref={finInsNameRef1} type="text" className="form-control clsFormInputControl"
                                                    placeholder="" value={finInstName1} maxLength={500}
                                                    onChange={(e) => {
                                                        setFinInstName1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(finInsNameRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" id={"divFinInsNum1"}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.FIN_INSTITUTION_NUMBER, "Financial Institution Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={finInsNumRef1} type="text" className="form-control clsFormInputControl"
                                                    placeholder=""
                                                    value={finAddInstNumber1}
                                                    onChange={(e) => {
                                                        setFinAddInstNumber1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(finInsNumRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.FIN_INSTITUTION_ADDRESS, "Financial Institution Address")}</label>
                                            <div className="col-sm-12">
                                                <input ref={finInsAddrRef1} id="bankAddr1" type="text" className="form-control clsFormInputControl"
                                                    placeholder="" value={finAddInstRawAddress1}
                                                    onChange={(e) => {
                                                        setFinAddInstRawAddress1(e.currentTarget.value);
                                                        setFinAddInstAddress1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(finInsAddrRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COUNTRY_LOC, "Country Located at")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={countries} selectedValue={countryObj1}
                                                    handleChange={onCountryChange1} value={"Key"} label={"Value"}
                                                    className={countryClass} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{provinceText1}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={states1} selectedValue={stateObj1}
                                                    handleChange={onStateChange1} value={"Key"} label={"Value"}
                                                    className={provinceClass} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CITY, "City")}</label>
                                            <div className="col-sm-12">
                                                <input ref={cityRef1} type="text" className="form-control clsFormInputControl"
                                                    placeholder={CommonUtil.getMessageText(Constants.ENTER_CITY, "You must enter a City")}
                                                    value={city1} onChange={(e) => {
                                                        setCity1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(cityRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{postalCodeText1}</label>
                                            <div className="col-sm-12">
                                                <input ref={postalCodeRef1} type="text" className="form-control clsFormInputControl"
                                                    placeholder={pcTextPlace1} value={postalcode1}
                                                    onChange={(e) => {
                                                        setPostalCode1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(postalCodeRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.CURRENCY, "Currency")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={currencies} selectedValue={currencyObj1}
                                                    handleChange={onCurrencyChange1} value={"Key"} label={"Value"}
                                                    className={currencyClass} disabled={false} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ACC_NUMBER, "Account number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={bankAccRef1} type="text" className="form-control clsFormInputControl"
                                                    placeholder="" value={bankAccNo1} maxLength={((countryObj1 && countryObj1.Key == CommonUtil.CANADA)?13:15)}
                                                    onChange={(e) => {
                                                        setBankAccNo1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(bankAccRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RECIPIENT_NAME, "Recipient Name")}</label>
                                            <div className="col-sm-12">
                                                <input ref={bankRecRef1} type="text"
                                                    className="form-control clsFormInputControl"
                                                    placeholder="" value={bankReceiptName1} maxLength={40}
                                                    onChange={(e) => {
                                                        setBankReceiptName1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(bankRecRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" id={"divTransit1"}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.TRANSIT, "Transit")}</label>
                                            <div className="col-sm-12">
                                                <input ref={bankTransRef1} type="text"
                                                    className="form-control clsFormInputControl"
                                                    placeholder="" value={bankTransit1} maxLength={5}
                                                       onKeyPress={CommonUtil.allowNumbersDecimal}
                                                    onChange={(e) => {
                                                        setBankTransit1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(bankTransRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12" id={"divABARouteNum1"}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ABA_ROUTING_NUMBER, "ABA Routing Number")}</label>
                                            <div className="col-sm-12">
                                                <input ref={abaRouteNoRef1} type="text"
                                                    className="form-control clsFormInputControl"
                                                    placeholder="" value={abaRouteNo1} maxLength={9}
                                                    onChange={(e) => {
                                                        setABARouteNo1(e.currentTarget.value);
                                                        CommonUtil.isMandatory(abaRouteNoRef1);
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.TYPE, "Type")}</label>
                                            <div className="col-sm-12">
                                                <SingleSelectComp dataSource={types1} selectedValue={typeObj1}
                                                    handleChange={onTypeChange1} value={"key"} label={"value"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-12" style={{ paddingTop: "20px",display:"none" }}>
                                        <label className="col-sm-12 col-form-label">{''}</label>
                                        <div className="col-sm-12">
                                            <label>{CommonUtil.getMessageText(Constants.SWITF_NOTE, "Note: By using SWIFT, additional processing fees may be applied on the side of your financial institution")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-6 cus-input-switch">
                                        <div className="form-switch ps-0 py-2 mx-2">
                                            <label style={{ marginRight: "50px" }}>{CommonUtil.getMessageText(Constants.AUTO_DEBIT, "Auto Debit")}</label> &nbsp;
                                            <input ref={autoDebitRef2} className="form-check-input" type="checkbox" role="switch" />
                                        </div>

                                    </div>
                                    <div className="col-md-2 col-6 p-0 cus-input-switch">
                                        <div className="form-switch ps-0 py-2 mx-2">
                                            <span style={{ marginRight: "50px" }}>{CommonUtil.getMessageText(Constants.DEFAULT_BANK, "Default Bank")}</span>
                                            <input ref={defBank2} className="form-check-input" type="checkbox"
                                                role="switch" />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12">

                                    </div>
                                    <div className="col-md-12 col-12" style={{ paddingBottom: "26px" }}>
                                        <div className="col-sm-12">
                                            <label>{CommonUtil.getMessageText(Constants.INTERNET_BANK_DETAILS, "Note: You can usually find these details by logging into your internet bank. From there you should easily be able to see the account number of the account you would like to use, the institution number of the bank, and the transit number of the branch")}</label>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label"><a className="hyperlink" href="../../images/pre-authorized payment form_vHub ANG[2914].pdf" download>
                                                <i className="fa fa-download"></i> {CommonUtil.getMessageText(Constants.BANK_DOWNLOAD_FORM, " Download Auto Debit Authorization Form")}</a><br /><small></small></label>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12" ref={dropBank1}>
                                        <div className="col-sm-12 cus-input-file-text financial-browse-button"
                                            onClick={() => { uploadFinanceDocuments(CommonUtil.BANK1_DOC_TYPE) }}>
                                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                                            <button type="button" className="btn btn-outline-primary financial-button" >{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                                        </div>
                                        <div className="col-sm-12 cus-input-file p-0">

                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12 mt-2">
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="gallery flex documents-gallary">
                                                    {attachDocs.map((item: any, idx: number) => {
                                                        if (item && (item.documentType == CommonUtil.BANK1_DOC_TYPE)) {
                                                            var list = (
                                                                <div className="img-fluid  document-box" key={idx}>
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                            onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className=" remove-btn fa fa-remove"
                                                                                onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                                    </div>
                                                                    <label className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                                </div>
                                                            )
                                                            return list
                                                        }
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="text-end">
                                <button type="button" className="btn btn-success" onClick={onClickBankSave}>Save</button>                              
                                 </div>
                </section>
                {completedStatus["creditCardDetails"] && <RibbonTag/>}
                <section>                
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-8"><h6>{CommonUtil.getMessageText(Constants.CC_DETAILS, "Credit Card Details (Payment processing powered by Elavon)")}
                                    {(CommonUtil.SELECT_ORG_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.SELECT_ORG_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)?<span style={{color: "red"}}>&nbsp;*</span>:""}
                                    </h6></div>
                                {/* <div className="col-sm-4 text-right">
                                    <button type="button" className="btn btn-danger btn-save js-save"
                                        style={{ display: (cardEdit ? "" : "none") }} onClick={onClickCardDisable}>{CommonUtil.getMessageText(Constants.CANCEL, "Cancel")}
                                    </button>
                                    &nbsp;
                                    <button type="button" className="btn btn-primary btn-save js-save"
                                        style={{ display: (cardEdit ? "" : "none") }} onClick={onClickSaveCreditCard}>{CommonUtil.getMessageText(Constants.SAVE, "Save")}</button>
                                    &nbsp;
                                    <button type="button" className="btn btn-warning btn-edit js-edit"
                                        style={{ display: (cardEdit ? "none" : "") }} onClick={onClickCardEdit}>{CommonUtil.getMessageText(Constants.EDIT, "Edit")}</button>
                                </div> */}
                            </div>
                            <form className="is-readonly">
                                <div className={"cardClass"}>
                                    {/*<CreditCardDetails/>*/}
                                    <CreditCardDetails enable={cardEdit} details={cardDetails}/>
                                </div>
                                <div className="row" style={{ paddingTop: "15px" }}>
                                    <div className="col-sm-4 text-right">
                                        <p className={"fw-600"}>{CommonUtil.getMessageText(Constants.CREDITCARD_NOTE, "Note: Only Visa and Mastercard credit cards are accepted")}</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <img src="../../images/visa-logo.svg" alt="" width="30" />
                                        &nbsp;
                                        <img src="../../images/master-logo.svg" alt="" width="30" />
                                    </div>
                                </div>
                                <div className="row">

                                </div>
                            </form>

                        </div>

                    </div>
                    <div className="text-end">
                                <button type="button" className="btn btn-success" onClick={onClickSaveCreditCard}>Save</button>                              
                                 </div>
                </section>
                {/* {CommonUtil.isRootUser() && completedStatus["openAccount"] && <RibbonTag/>} */}
                <section className={CommonUtil.isRootUser() ? "" : "d-none"}>
                    <AddOpenAccount updateProfile={updateProfile}/>
                </section>
                
                
                
            </div>
        </>
    );
}

export default FinanceInfo;
